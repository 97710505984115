import { Routes } from '@angular/router';
import { NotAuthorizedComponent } from './pages/authentication/not-authorized.component';
import { SigningInComponent } from './pages/authentication/signing-in.component';
import { SingleSignOnComponent } from './pages/authentication/single-sign-on.component';
import { ChangeRequestsComponent } from './pages/change-requests/change-requests.component';
import { CompareDetailsPageComponent } from './pages/compare-details/compare-details.component';
import { LogHistoryComponent } from './pages/log-history/log-history.component';
import { ProductsImportComponent } from './pages/products-import/products-import.component';
import { SearchResultsPageComponent } from './pages/search-results/search-results.component';
import { VariantSetPageComponent } from './pages/variant-set-page.component';
import { AuthGuard } from './services/auth.guard';
import { RoleGuardService } from './services/role.guard';

export const routes: Routes = [
  { path: '', component: SearchResultsPageComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'compare-details', component: CompareDetailsPageComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'open-requests', component: ChangeRequestsComponent, pathMatch: 'full', canActivate: [AuthGuard, RoleGuardService] },
  { path: 'log-history', component: LogHistoryComponent, pathMatch: 'full', canActivate: [AuthGuard, RoleGuardService] },
  { path: 'variant/:variant', component: VariantSetPageComponent, pathMatch: 'full', canActivate: [AuthGuard] },
  { path: 'not-authorized', component: NotAuthorizedComponent },
  { path: 'signing-in', component: SigningInComponent },
  { path: 'access_token', component: SingleSignOnComponent },
  { path: 'products-import', component: ProductsImportComponent, canActivate: [AuthGuard, RoleGuardService] },
];
