import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserSettingsService } from '../services/user-settings.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'variant-set',
  template: ``
})
export class VariantSetPageComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userSettingsService: UserSettingsService,
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        tap(params => this.userSettingsService.setVariant(params.variant)),
        tap(_ => this.router.navigate(['/']))
      )
      .subscribe().unsubscribe();
  }
}
