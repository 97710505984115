import { Component, Input } from '@angular/core';
import { OverlayCreatorService } from 'covestro-ui-components/components/molecules/overlay-creator.service';
import { Product } from '../../../generated/graphql';
import { PRODUCT_PROPERTY_LABELS_BY_FAMILY } from '../../services/product-properties.service';
import { GtagService } from '../../shared/gtag/gtag.service';
import { DialogService } from '../dialog/dialog.service';
import { ProductEditComponent } from '../product-edit/product-edits.component';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.html',
  styleUrls: ['./product-details.scss'],
})
export class ProductDetailsComponent {
  properties = {};

  constructor(
    private gtag: GtagService,
    public dialogService: DialogService,
    public ref:OverlayCreatorService
  ) {}

  @Input() options: any;

  @Input()
  set product(product: Product) {
    this._product = product;
    this.properties = PRODUCT_PROPERTY_LABELS_BY_FAMILY[product.family];
    const allColumns = Object.keys(product)
      .filter(key => !this.describingFields.includes(key) && key !== '__typename')
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    // Adding this extra rearrangeArray() methd to rearrange the item order as required by VCOP-1047.
    const keysToMove = ["excludingBioCarbon", "includingBioCarbon"];
    const targetKey = "carbonFootprint";
    const allShowColumn:string[] = [...this.describingFields, ...allColumns];
    this.showColumns = this.rearrangeArray(allShowColumn, keysToMove, targetKey);
  }

  rearrangeArray(input: string[], keysToMove: string[], targetKey: string): string[] {
    // Check if any of the keys to move are present in the input array
    const keysPresent = keysToMove.filter((key) => input.includes(key));
    // Return the input array as is if none of the keys to move are present
    if (keysPresent.length === 0) return input;
    // Find the index of the target key
    const targetKeyIndex = input.indexOf(targetKey);
    // Remove the keys to move from their current positions
    const newArray = input.filter((item) => !keysToMove.includes(item));
    // Insert the keys to move after the target key or at the end if targetKey is not present
    if (targetKeyIndex !== -1) newArray.splice(targetKeyIndex + 1, 0, ...keysPresent);
    else newArray.push(...keysPresent);
    return newArray;
  }

  get product(): Product {
    return this._product;
  }

  private _product: Product;

  showColumns: any = [];
  columnLabels: any = [];

  private describingFields = ['supplier', 'name', 'family', 'type', 'subType']; // TODO: might remove from here

  editProduct() {
    this.gtag.click('edit-product');
    const ref = this.dialogService.open(ProductEditComponent, {data: { product: this.product, options: this.options} } );
  };

  close() {
   this.ref.close();
  }
}
