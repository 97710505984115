<div *ngIf="noData" class="warning">
  Sorry, but we can't find any results for your search parameters.
</div>
<!-- TODO: https://github.com/akeresztesgh/angular-double-scrollbars -->
<section data-ui="content">
<div class="wrapper">
  <div class="table-window" #scrollableWrapper (scroll)="onScroll($event)">
    <table>
      <thead>
        <tr>
          <th *ngFor="let col of showFields" (click)="toggleSortForColumn(col)" style="cursor: pointer;">
            {{col | logTable}}&nbsp;
            <i class="fas fa-sort" *ngIf="!sortPositionAndDirectionByColumns[col]; else sorted"></i>
            <ng-template #sorted>
              <i class="fas fa-sort-up" *ngIf="sortPositionAndDirectionByColumns[col].direction === 'ASC'"></i>
              <i class="fas fa-sort-down" *ngIf="sortPositionAndDirectionByColumns[col].direction === 'DESC'"></i>
              <sup class="sort-order">&nbsp;{{ sortPositionAndDirectionByColumns[col].index + 1 }}</sup>
            </ng-template>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="data">
       <tr *ngFor="let row of data; let i = index; trackBy: tableRowIdentity" (click) ="showDetails(row)" (mouseenter) ="mouseEnter(row)"  (mouseleave) ="mouseLeave(row)">
          <ng-container *ngIf="row !== LAZY_LOAD_WRAPPER && row !== LOADING_WRAPPER">
            <td *ngFor="let col of showFields" >
              <span (click)="(clickEvents && clickEvents[col]) ? clickEvents[col](row) : null"
                [class.clickable]="clickEvents && clickEvents[col]">
                <ng-container *ngIf="columnTemplate; else defaultPrint">
                  <ng-container *ngTemplateOutlet="columnTemplate; context: {$implicit: row[col] | logTableData, column: col, row: row}">
                  </ng-container>
                </ng-container>
              </span>

              <ng-template #defaultPrint>
                <ng-container *ngIf="row[col] === true; then truePrint;
                    else (row[col] === false && falsePrint)
                      || simplePrint ">
                </ng-container>
              </ng-template>
              <ng-template #simplePrint>{{ row[col]}} </ng-template> 
              
            </td>
            
            
          </ng-container>
          <ng-container *ngIf="row === LAZY_LOAD_WRAPPER">
            <td><span class="lazy" #lazyLoadElement [attr.data-index]="i"></span></td>
          </ng-container>
          <ng-container *ngIf="row === LOADING_WRAPPER">
            <td colspan="2"><i class="fas fa-spinner"></i></td>
          </ng-container> 
        </tr> 
      </tbody>
    </table>
  </div>
</div>
</section>

<ng-template #truePrint><i class="fas fa-check"></i></ng-template>
<ng-template #falsePrint><i class="fas fa-times"></i></ng-template>
