import { Component, ContentChildren, Input, Output, QueryList, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { TabLabelDirective } from "./tab-label.directive";

@Component({
  selector: 'tabs',
  templateUrl: './tab.html',
  styleUrls: ['./tab.scss'],
})
export class TabComponent implements OnInit {
  @Input()
  selected: number = 0;

  @Input()
  preloadFn: (selected: number) => Promise<any>;

  @Output()
  selectedChange = new EventEmitter<number>();

  @ContentChildren(TabLabelDirective)
  set tabs(tabs: QueryList<TabLabelDirective>) {
    this._tabs = tabs;
    if (!tabs || tabs.length <= this.selected) {
      this.selected = 0;
      this.selectedChange.emit(0);
    }
    this.openedTab = tabs?.get(this.selected);
  }

  get tabs(): QueryList<TabLabelDirective> {
    return this._tabs;
  }

  private _tabs: QueryList<TabLabelDirective>;

  openedTab: TabLabelDirective;

  async ngOnInit() {
    if (this.preloadFn) {
      await this.preloadFn(this.selected);
    }
  }

  async openTab(i: number): Promise<void> {
    if (!this.tabs.get(i).disabled) {
      if (this.preloadFn) {
        await this.preloadFn(i);
      }
      this.selected = i;
      this.openedTab = this.tabs.get(i);
      this.selectedChange.emit(i);
    }
  }

}
