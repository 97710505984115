import { Component, OnInit } from '@angular/core';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { GtagService } from 'src/app/shared/gtag/gtag.service';
import { DialogRef } from '../dialog/dialog-ref';

@Component({
  selector: 'app-help-section',
  templateUrl: './help-section.component.html',
  styleUrls: ['./help-section.component.scss']
})
export class HelpSectionComponent implements OnInit {
  loadintro() {
    throw new Error('Method not implemented.');
  }
  selectedTab: number;
  selected:boolean = true;
  

  constructor(
    public dialogEdit: DialogRef,
    public settings: UserSettingsService,
    private gtag: GtagService
    ) { }

  ngOnInit(): void {
    this.selectedTab = 0;
  }
  onClose(){
    this.dialogEdit.close('close');
    
   }
   preloadTab = (selected: number) => {
    if (selected === 1) {
      this.loadintro();
    }
  };
   onSetTab($event) {
    
    this.gtag.click('set-tab');
    this.settings.setTab($event);
    this.selectedTab = $event;
  }
  
}
