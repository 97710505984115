import { Component, OnDestroy, OnInit } from '@angular/core';
import { OverlayCreatorService } from 'covestro-ui-components/components/molecules/overlay-creator.service';
import { LOADING_WRAPPER, SortableTableState } from 'covestro-ui-components/components/organisms/sortable-table/sortable-table.component';
import { isEmpty, sortBy } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { PrintableProduct } from 'src/app/services/convert-product-to-printable.function';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { GtagService } from 'src/app/shared/gtag/gtag.service';
import { Product } from 'src/generated/graphql';
import { LAZY_LOAD_WRAPPER } from '../../../../../components/components/organisms/sortable-table/sortable-table.component';
import { ProductFilterInput, SortBy, SortDirection } from '../../../generated/graphql';
import { ProductsService } from '../../api/products.service';
import { ProductDetailsComponent } from '../../components/product-details/product-details.component';
import { ProductEditComponent } from '../../components/product-edit/product-edits.component';
import { DiagramProperties } from '../../components/product-scatter-plot/product-scatter-plot.component';
import { mapProductToPrintable } from '../../services/convert-product-to-printable.function';
import { ProductsBasketStoreService } from '../../services/product-basket.store.service';
import { PRODUCT_PROPERTY_LABELS, PRODUCT_PROPERTY_LABELS_BY_FAMILY } from '../../services/product-properties.service';
import {DialogService } from '../../components/dialog/dialog.service';
import * as XLSX from 'xlsx';
import * as $ from 'jquery';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.html',
  styleUrls: ['./search-results.scss']
})
export class SearchResultsPageComponent implements OnInit, OnDestroy {
  products: Array<PrintableProduct>;

  basket$: Observable<Array<Product>>;
  loading: boolean;
  isDownloadAsExcelLoading: boolean;
  hasBiocontent:boolean;
  allProductsLoading: boolean;
  numberLoadedProducts: number;
  searchResulstSub: Subscription;
  showSearchResult: boolean = false;
  selectedTab: number;
  showFields = this.settings.resultTableState?.showFields ?? ['name', 'supplier', 'type', 'subType', 'family'];

  fields: any; // TODO: to be replaced by pipe :)

  private selectedFamily: string = 'ALL';

  private sortOrder: Array<SortBy> = this.convertSortOrder(this.settings.resultTableState);

  private totalProducts = 0;
  private filterInput: Partial<ProductFilterInput>;
  options: any;

  constructor(
    private overlayCreatorService: OverlayCreatorService,
    private productsService: ProductsService,
    private productsBasketStoreService: ProductsBasketStoreService,
    public settings: UserSettingsService,
    private gtag: GtagService,
    public dialogService: DialogService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.productsService.fetchFilterOptions({isDeleted : {is : false}}).then(options => this.options = options);
    this.basket$ = this.productsBasketStoreService.basket$;
    this.fields = PRODUCT_PROPERTY_LABELS;
    this.selectedTab = this.settings.getTab();
    if (this.selectedTab === 1) {
      this.loadAllProducts();
    }
  }

  ngOnChanges(): void {
    this.selectedTab = this.settings.getTab();
  }

  onSetTab($event) {
    this.gtag.click('set-tab');
    this.settings.setTab($event);
    this.selectedTab = $event;
  }

  preloadTab = (selected: number) => {
    if (selected === 1) {
      this.loadAllProducts();
    }
  };

  keyFn = ProductsService.keyFn;

  tableCellClickEvents = { name: (product: Product) => {
    const options = this.options;
    this.gtag.click('show-product-details');
    this.overlayCreatorService.open(ProductDetailsComponent, { product, options });
  } };

  async loadProducts($event: Partial<ProductFilterInput>) {
    this.filterInput = $event;
    this.loading = true;
    this.showSearchResult = true;
    this.products = (await this.productsService.fetchByFilter($event, this.sortOrder)).map(mapProductToPrintable);
    this.numberLoadedProducts = this.products.length;
    while (this.products.length < this.totalProducts) {
      this.products.push(LAZY_LOAD_WRAPPER);
    }
    this.loading = false;
  }


  async loadAllProducts(): Promise<Array<PrintableProduct>> {
    if (this.products?.some(p => p === LAZY_LOAD_WRAPPER)) {
      this.allProductsLoading = true;
      await this.loadTableItems(
        [this.products.indexOf(LAZY_LOAD_WRAPPER), this.products.lastIndexOf(LAZY_LOAD_WRAPPER)]
      );
      this.allProductsLoading = false;
    }
    return this.products;
  }

  async onFilterSelected($event: Partial<ProductFilterInput>) {
    if($event.biocontent){
      this.hasBiocontent = true;
      this.showFields = this.showFields.filter((x) => x !== 'biocontent');
      this.showFields =[...this.showFields,'biocontent']
    }
    if(!$event.biocontent){
      this.hasBiocontent = false;
    }
    this.selectedTab = this.settings.getTab();
    if($event.family){
      this.fields = [];
      this.fields =PRODUCT_PROPERTY_LABELS_BY_FAMILY[$event.family.contains];
    }
    if(!$event.family){
       this.fields = PRODUCT_PROPERTY_LABELS;
    }
    this.filterInput = $event;
    if (!this.products?.length) {
      this.loadProducts(this.filterInput);
    }
  }

  async onSearch($event: string) {
    const filterInput = Object.assign({}, this.filterInput, { name: { contains: $event } });
    this.productsService.fetchFilterOptions(filterInput).then(options => this.totalProducts = options._total);
    await this.loadProducts(filterInput);
  }

  onClearAllFilters($event) {
    this.gtag.click('clear-filters');
    this.filterInput = null;
  }

  addToBasket(products: Set<Product>): void {
    this.gtag.click('add-to-basket');
    let productsArray = Array.from(products)
    productsArray = productsArray.filter(product => !isEmpty(product))
    this.productsBasketStoreService.addBulkToBasket(new Set(productsArray));
  }

  removeSelections(products: Set<Product>): void {
    this.gtag.click('remove-selections');
    const toRemove = new Set([...products].map(product => this.keyFn(product)));
    this.products = this.products.filter(product => !toRemove.has(this.keyFn(product)));
  }

  onColoumnsChange($event) {
   const temp = $event.map(value => `${value},` )
   this.gtag.click('show-columns', temp)
  }

  toggleEmptyFields(state: SortableTableState<Product>, showEmptyFields: boolean): void {
    this.gtag.click('toggle-empty-fields');
    if (showEmptyFields) {
      const fields = [...new Set(
        this.products.map(Object.entries).flat()
          .filter(([, value]) => this.isEmpty(value))
          .map(([key]) => key)
      )];

      this.showFields = fields;
    } else {
      if (this.products) {
        this.showFields = ['name','supplier','type','subType','family'];
      }
    }
  }

  async onTableStateChanged(state: SortableTableState<Product>): Promise<void> {
    this.settings.resultTableState = state;
    const sortOrder = this.convertSortOrder(state);
      if (JSON.stringify(sortOrder) != JSON.stringify(this.sortOrder)) {
      this.sortOrder = sortOrder;
      if (this.totalProducts > this.products.filter(p => p !== LOADING_WRAPPER && p !== LAZY_LOAD_WRAPPER).length) {
        await this.loadProducts(this.filterInput);
      }
    }
  }

  private convertSortOrder(state: SortableTableState<Product>) {
    if (state == null || state.sortOrder == null) {
      return null;
    }
    return sortBy(Object.entries(state.sortOrder), ([property, { index, direction }]) => index)
      .map(([property, { index, direction }]) => ({ property, direction: direction as SortDirection }));
  }

  onTotalAvailableChange(total: number) {
    this.totalProducts = total;
  }

  async loadTableItems(itemPositions: Array<number>) {
    const min = Math.min(...itemPositions);
    const max = Math.max(...itemPositions);

    for (let i = min; i<= max; i++) {
      this.products[i] = LOADING_WRAPPER;
    }

    for (let i = min; i <= max; i = i + 20) { // 20 => page size
      const products = (await this.productsService.fetchByFilter(this.filterInput, this.sortOrder, i, 20))
      this.numberLoadedProducts += products.length;
      this.products.splice(i, Math.min(20, this.products.length - i), ...(products).map(mapProductToPrintable));
    }
  }

  get graphProperties(): DiagramProperties {
    return this.settings.graphProperties ? this.settings.graphProperties[this.selectedFamily] : null;
  }

  set graphProperties(graphProperties: DiagramProperties) {
    this.settings.graphProperties = Object.assign({}, this.settings.graphProperties ?? {}, {[this.selectedFamily]: graphProperties});
  }

  addNewProduct() {
    this.gtag.click('add-new-product');
    const ref = this.dialogService.open(ProductEditComponent, {data: {options: this.options}} );
  };

  async onDownloadAsExcel() {
    this.isDownloadAsExcelLoading = true;

    let isScrollComplete;
    do {
      isScrollComplete = await this.scrollTillEnd();
    } while (isScrollComplete !== 'YES');
    const fileName = `ProductsExcelSheet_${new Date().getTime()}.xlsx`;

    const element = document.getElementById('productsTableId');
    let ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    ws['!cols'][0] = { hidden: true };

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, fileName);
    this.isDownloadAsExcelLoading = false;
  }

  scrollTillEnd() {
    return new Promise((resolve, reject) => {
      const ID = "scrollableTableWindow"; // This ID is from sortable-table.component.html
      const containerDiv = document.getElementById(ID);
      const containerScrollHeight = containerDiv.scrollHeight;

      // prettier-ignore
      $(`#${ID}`).animate( { scrollTop: containerScrollHeight }, 3000, () => {
          if (containerScrollHeight !== containerDiv.scrollHeight) {
            resolve('NO');
          } else {
            $(`#${ID}`).scrollTop(0);
            resolve('YES');
          }
        }
      );
    });
  }

  private isEmpty(value): boolean {
    return value === null || value === undefined || value === ""
  }

  ngOnDestroy() {
    this.searchResulstSub?.unsubscribe();
  }
}