import { Component, ContentChildren, QueryList, Input, EventEmitter, Output } from '@angular/core';
import { MultiSelectOptionDirective } from './option.directive';

type Option = {value: string, label: any, selected: boolean, changed: boolean};

@Component({
  selector: 'multi-select',
  templateUrl: './multi-select.html',
  styleUrls: ['./multi-select.scss']
})
export class MultiSelectComponent {
  options: Array<Option> = [];

  @Input()
  displayLabel: string = "";

  @Input()
  label: string;

  @Input()
  allSelectedValue: any;

  @Output()
  selectChange = new EventEmitter<Array<any>>();

  open = false;

  @ContentChildren(MultiSelectOptionDirective)
  set optionItems(options: QueryList<MultiSelectOptionDirective>) {
    this.options = options.map(({element}) => ({
      label: element.nativeElement.label,
      value: element.nativeElement.value,
      selected: element.nativeElement.selected || this.options.some(opt => opt.value === element.nativeElement.value && opt.selected),
      changed: false
    }));
    this.initLabel();
  }

  toggleOpen() {
    this.open = !this.open;
  }

  toogleOption(option: Option) {
    option.selected = !option.selected;
    option.changed = !option.changed;
  }

  apply() {
    const selected = this.initLabel();
    this.selectChange.emit(selected.map(o => o.value));
    this.open = false;
  }

  reset() {
    this.options.filter(o => o.selected).forEach(o => o.selected = false);
    this.options.filter(o => o.changed).forEach(o => o.changed = false);
    this.selectChange.emit(null);
    this.open = false;
    this.displayLabel = "";
  }

  private initLabel(): Array<Option> {
    const selected = this.options.filter(option => option.selected);
    if (selected.length === 1) {
      this.displayLabel = selected[0].label;
    } else if (selected.length === 0) {
      this.displayLabel = "";
    } else {
      this.displayLabel = selected[0].label + " +" + (selected.length - 1);
    }
    return selected;
  }

}
