import { Component, Input } from '@angular/core';
import { mapProductPropertyToPrintable } from '../../services/convert-product-to-printable.function';

@Component({
  selector: 'app-product-property',
  template: `

  <ng-container
    *ngIf="value === true; then truePrint;
      else (value === false && falsePrint)
        || (value === null && nullPrint)
        || (value === undefined && nullPrint)
        || (value === 'not applicable' && undefinedPrint )
        || simplePrint">
  </ng-container>

  <ng-template #simplePrint>{{ value }}</ng-template>
  <ng-template #truePrint><span class="unicode-icon">&#10004;</span></ng-template>
  <ng-template #falsePrint><span class="unicode-icon">&#10006;</span></ng-template>
  <ng-template #nullPrint><span class="no-value">not entered</span></ng-template>
  <ng-template #undefinedPrint><span class="no-value">not applicable</span></ng-template>
  `,
  styleUrls: ['./product-property.scss']
})
export class ProductPropertyComponent {
  @Input()
  set value(value: any) {
    this._value = mapProductPropertyToPrintable(value);
  }

  get value() {
    return this._value;
  }

  _value: string | number | boolean | null | undefined;
}
