<div class="help-section-dialog">
    
    <h3>Help<i _ngcontent-wgl-c93="" class="fas fa-times" (click) = 'onClose()'></i></h3>
    <section class = "main">
        <ng-container class="detail-block">
            <tabs  [selected]="this.selectedTab" (selectedChange)="onSetTab($event)">
              <ng-template tab-label="Intro">
                <h2><img src="../../assets/arrow_right.svg" draggable="false" height="20px" width="20px">   Intro</h2>
                <span>Using the Visual Comparison Tool, you can create:
                    <ul>
                        <li>A <b>table</b> comparing products using the properties you choose</li>
                        <li>A <b>bubble</b> chart showing graphically a comparison of chosen products based on two selected properties</li>
                    </ul>
                </span>
              </ng-template>
              <ng-template tab-label="User Manual">
                <h2><img src="../../assets/arrow_right.svg" draggable="false" height="20px" width="20px">   User Manual</h2>
                <span>For further information, please have a look on our simple show:<br>
                    
                        <a href ="https://web.microsoftstream.com/video/982cbdaf-f500-44db-b3b7-d6026186b12d"><b>SIMPLE SHOW </b></a>
                    
                </span>
            </ng-template>
            <ng-template tab-label="Contacts" class="contact-details">
                <h2><img src="../../assets/arrow_right.svg" draggable="false" height="20px" width="20px">   Contacts</h2>
                <span>Please contact the CLUE leaders:<br><a><b>CLUE-approvals_core_team@covestro.com</b></a></span>
            </ng-template>
            </tabs>
        </ng-container>
    </section>
      
    <!--<button (click) = 'onClose()'>OK</button>-->
</div>
