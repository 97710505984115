import { chunk, flatten } from 'lodash';

export async function chunkedRequest<T, R>(
  requestData: Array<T>,
  chunkSize: number,
  request: (requestData: Array<T>) => Promise<Array<R>>
): Promise<Array<R>> {
  return flatten(await Promise.all(
    chunk(requestData, chunkSize).map(chunkedData => request(chunkedData))
  ));
}
