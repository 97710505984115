import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginComponent } from './components/organisms/login/login.component';
import { CovestroHeaderComponent } from './components/organisms/header/header.component';
import { ScatterPlotComponent } from './components/charts/scotter-plott/scatter-plot.component';
import { MultiDropdownComponent } from './components/atoms/multi-dropdown/multi-dropdown.component';
import { SortableTableComponent } from './components/organisms/sortable-table/sortable-table.component';
import { ProductBasketComponent } from './components/organisms/product-basket/product-basket.component';
import { ClickOutsideDirective } from './directives/clickOutside.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ComparisionTableComponent } from './components/organisms/comparision-table/comparision-table.component';
import { ButtonBlurDirective } from './directives/button-blur.directive';
import { ProgressBarComponent } from './components/atoms/progress-bar/progress-bar.component';
import { TabLabelDirective } from './components/organisms/tab/tab-label.directive';
import { TabComponent } from './components/organisms/tab/tab.component';
import { OverlayCreatorService } from './components/molecules/overlay-creator.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { PopoverMenuComponent } from './components/organisms/popover-menu/popover-menu.component';
import { CheckboxIndeterminateDirective } from './components/atoms/checkbox/checkbox-indeterminate.directive';
import { CheckboxGroupDirective } from './components/atoms/checkbox/checkbox-group.directive';
import { CheckboxGroupLeadDirective } from './components/atoms/checkbox/checkbox-group-lead.directive';
import { DynamicFilterInputComponent } from './components/atoms/dynamic-filter-input/dynamic-filter-input.component';
import { FilterComponent } from './components/organisms/filter/filter.component';
import { DynamicInputComponent } from './components/atoms/dynamic-input/dynamic-input.component';
import { MultiSelectOptionDirective } from './components/atoms/multi-select/option.directive';
import { MultiSelectComponent } from './components/atoms/multi-select/multi-select.component';
import { LogTableComponent } from './components/organisms/log-table/log-table.component';
import { LogTablePipe } from './components/organisms/log-table/log-table.pipe';
import { LogTableDataPipe } from './components/organisms/log-table/log-table-data.pipe';


@NgModule({
  declarations: [
    LoginComponent,
    CovestroHeaderComponent,
    ScatterPlotComponent,
    MultiDropdownComponent,
    SortableTableComponent,
    ClickOutsideDirective,
    ProductBasketComponent,
    ComparisionTableComponent,
    ButtonBlurDirective,
    ProgressBarComponent,
    TabLabelDirective,
    TabComponent,
    PopoverMenuComponent,
    CheckboxIndeterminateDirective,
    CheckboxGroupDirective,
    CheckboxGroupLeadDirective,
    DynamicFilterInputComponent,
    DynamicInputComponent,
    FilterComponent,
    MultiSelectOptionDirective,
    MultiSelectComponent,
    LogTableComponent,
    LogTablePipe,
    LogTableDataPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    OverlayModule,
    ReactiveFormsModule,
  ],
  providers: [
    OverlayCreatorService,
  ],
  exports: [
    LoginComponent,
    CovestroHeaderComponent,
    ScatterPlotComponent,
    MultiDropdownComponent,
    ProductBasketComponent,
    SortableTableComponent,
    ComparisionTableComponent,
    ProgressBarComponent,
    TabLabelDirective,
    TabComponent,
    PopoverMenuComponent,
    CheckboxIndeterminateDirective,
    CheckboxGroupDirective,
    CheckboxGroupLeadDirective,
    DynamicFilterInputComponent,
    DynamicInputComponent,
    FilterComponent,
    MultiSelectOptionDirective,
    MultiSelectComponent,
    LogTableComponent
  ]
})
export class CovestroUiComponentsModule { }
