import { Component, ElementRef, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { chunk } from 'lodash';
import * as Papa from 'papaparse';
import { ProductsService } from 'src/app/api/products.service';
import { mapProductToPrintable } from 'src/app/services/convert-product-to-printable.function';
import * as XLSX from 'xlsx';
import { PRODUCT_PROPERTIES_FOR_LABELS } from '../../services/product-properties.service';
import { ProductInput, createProductsChangeRequestGQL, Product, ProductFilterInput } from './../../../generated/graphql';
import { ProductAdapter } from './product.adapter';

@Component({
  selector: 'app-products-import',
  templateUrl: './products-import.component.html',
  styleUrls: ['./products-import.component.scss']
})
export class ProductsImportComponent implements OnInit {

  transactWriteItems:number = 12;
  products: Product[] = [];
  productFamilies = [];
  showColumns = ['supplier', 'name', 'family'];
  fileName = '';
  importProgress: number;
  total: number;
  error: { name: string, errorMessage: string };
  errorLog: Array<any> = [];
  form: FormGroup;
  success:string;
  preview:any;
  mappingError:any;

  @ViewChild('file') fileUpload: ElementRef;
  @ViewChild('import') importButton: ElementRef;
  
  private filterInput: Partial<ProductFilterInput>;
  totalProducts: number;

  constructor(
    private createProductsChangeRequestGQL: createProductsChangeRequestGQL,
    private adapter: ProductAdapter,
    private productsService: ProductsService,
    private fb: FormBuilder) {
  }

  ngOnInit(): void {
   this.productsService.fetchFilterOptions(null).then(options =>  this.productFamilies = options.family);
    this.form = this.fb.group({
      family: null
    });
    
  }
  
  selectChangeHandler(){
    if(this.fileName){
    this.preview =null;
    this.mappingError = null;
  } else{
    this.preview = this.form.value.family;    
    }
  }

  fileChanged({target}: {target: HTMLInputElement}) {
    if (target.files[0]) {
      this.fileName = target.files[0].name;
      this.preview =null;
    } else {
      this.fileName = null;
    }
  }

  importClicked() {
    this.import();
  }

  async import() {
    let json;
    if (this.fileName.endsWith(".csv")) {
      json = await this.readCsvFile(this.fileUpload.nativeElement.files[0]);
    } else if (this.fileName.endsWith(".xlsx")) {
      json = await this.readXlsxFile(this.fileUpload.nativeElement.files[0]);
    }
    const products = this.mapColumns(json).map(json => this.adapter.adapt(json));
    if(products.length==0) {
      this.success = null;
      this.importProgress = null;
      return this.error = { name: 'error', errorMessage: "  * Supplier, Product Name, Type, BaseType shouldn't be empty. "};   
    } 
    for(let i = 0; i<products.length; i++)
      { 
        if(this.form.value.family != products[i].family)
          {
            this.success = null;
            this.importProgress = null;
            return this.error = { name: 'error', errorMessage: "  Please check the Column Base Type in the excel, All the values should be "+this.form.value.family+""};
          } 
          if(!products[i].family  || !products[i].name  ||
            !products[i].supplier  || !products[i].type ){
              this.success = null;
              this.importProgress = null;
              return this.error = { name: 'error', errorMessage: " * Supplier, Product Name, Type, BaseType shouldn't be empty."};    
         }
                 
      }
       for(let i = 0; i<products.length; i++)
      { 
        const duplicate =  Object.assign({}, this.filterInput, { family: { equals: products[i].family}} , {type: { equals: products[i].type}});
        this.productsService.fetchFilterOptions(duplicate).then(options => this.totalProducts = options._total);
        const product = (await this.productsService.fetchByFilter(duplicate)).map(mapProductToPrintable);
        if(product.length == 0)
        {          
          return this.error = { name: 'error', errorMessage: "   Please provide valid 'Type' value for the 'BaseType' - " +products[i].family +" on line number "+(i+2)+" in the excel"};
        }        
      }
      this.error = { name: '', errorMessage: '' };
      this.errorLog =null;
      await this.postProducts(products);
  }

  private readCsvFile(file: File): Promise<Array<Record<string, any>>> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(Papa.parse<Record<string, any>>(<string>reader.result, {
            delimiter: ";",
            header: true,
          }).data);
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  }

  private readXlsxFile(file: File): Promise<Array<Record<string, any>>> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        const wb = XLSX.read(<string>reader.result, {type: 'binary'})
        const firstSheet = wb.Sheets[wb.SheetNames[0]];
        resolve(XLSX.utils.sheet_to_json(firstSheet));
      };
      reader.onerror = reject;
      reader.readAsBinaryString(file);
    });
  }

  private mapColumns(json: Array<Record<string, any>>): Array<Record<keyof Product, any>> {
       return json.map(entry =>
        Object.fromEntries(
          Object.entries(entry)
            .map(([key, value]) => [PRODUCT_PROPERTIES_FOR_LABELS[key] ?? key, value])
      ) as Record<keyof Product, any>)
      .filter(product => product.name && product.supplier)
      .map(product => Object.assign(product, {family: product.family}))
    }

  private async postProducts(products: Array<ProductInput>) {
    this.total = products.length;
    this.importProgress = 0;
    const chunks = chunk(products, this.transactWriteItems);
    for (let chunk of chunks) {
      await this.createProductsChangeRequestGQL.mutate({
        products: chunk
      }).toPromise().catch((error) => {
      //const tempError = JSON.parse(JSON.stringify(error));
        this.mappingError = 'error';
        this.success=null;
        this.importProgress = null;
             return  this.error = { name: error, errorMessage: 'Error occurred during data importing. '+error };
      });
      if(this.mappingError !='error'){
      this.importProgress += chunk.length;
      this.error = { name: '', errorMessage: '' };
      this.errorLog =null;
      this.success = "File successfully Uploaded";
      this.fileName = '';
      await this.sleep(600);
      }
    } 
  }

  private sleep(ms): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  reset() {
    this.importProgress = null;
    this.total = null;
    this.fileUpload.nativeElement.value = '';
    this.form.controls['family'].reset();
    this.fileName = '';
    this.error = { name: '', errorMessage: '' };
    this.errorLog = null;
    this.success ="";
    this.preview = null;
  }

  clean() {
    this.reset();
  }
}


