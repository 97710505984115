import { Component, Input } from '@angular/core';

@Component({
  selector: 'cov-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class CovestroHeaderComponent {
  @Input()
  appName: string;
}
