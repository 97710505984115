<div class="navigation" gtag="product-comparision">
  <a href="/">
    <button data-ui="secondary small">&lt; Back</button>
  </a>
</div>
<div class="content">
  <div class="main" *ngIf="products">
    <app-product-scatter-plot [keyFn]="keyFn" [(vizualisationProperties)]="compareDetailsGraphProperties"
      [products]="products" [productsSelected]="basket$ | async"></app-product-scatter-plot>
    <comparision-table [data]="products" [showFields]="showColumns" [describingFields]="describingFields"
      [fields]="fields" (sortedRows)="changeSortedRows($event)" [columnTemplate]="column"
      [selectActionsTemplate]="selectActions">
      <ng-container *actions="let v=value">
        <multi-interactions (showEmptyFields)="toggleEmptyFields(v, $event)"></multi-interactions>
      </ng-container>
    </comparision-table>
  </div>
  <div class="item">
    <app-products-basket class="item"></app-products-basket>
  </div>
</div>

<ng-template #column let-value let-column="column" let-row="row">
  <app-product-property [value]="value"></app-product-property>
</ng-template>

<ng-template #selectActions let-selected="selected">
  <i class="action fas fa-eye-slash" (click)="removeSelections(selected)" title="remove from comparision"></i>
</ng-template>
