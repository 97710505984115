import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AuthorizationService } from './authorization.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public username: string;
  public password: string;

  public errorMessage: string;

  public pageState?: 'NEW_PASSWORD_REQUIRED' | 'FORGOT_PASSWORD';

  get isLoginState(): boolean {
    return this.pageState == null;
  }

  get isForgotPasswordState(): boolean {
    return this.pageState === 'FORGOT_PASSWORD';
  }

  get isNewPasswordRequired(): boolean {
    return this.pageState === 'NEW_PASSWORD_REQUIRED';
  }

  public newPassword: string;
  public passwordRepeat: string;
  private userAttributes: any;
  private cognitoUser: CognitoUser;

  public verificationCode: string;

  constructor(
    private router: Router,
    private authorizationService: AuthorizationService
    ) { }

  ngOnInit(): void {
    if (this.authorizationService.isLoggedIn()) {
      this.router.navigate(['']);
    }
  }

  login(): void {
    this.errorMessage = null;
    this.pageState = null;
    this.passwordRepeat = null;

    this.authorizationService.signIn(this.username, this.password)
      .subscribe(
        () => this.router.navigate(['']),
        (error: Error) => {
          if (error.name === 'NEW_PASSWORD_REQUIRED') {
            this.pageState = 'NEW_PASSWORD_REQUIRED';
            this.userAttributes = (error as any).userAttributes;
            this.cognitoUser = (error as any).cognitoUser as CognitoUser;
            this.errorMessage = 'Your password is expired, please change.';
          } else {
            this.errorMessage = error.message;
          }
        }
      );
  }

  changePassword(): void {
    this.errorMessage = null;

    if (this.newPassword === this.passwordRepeat) {
      this.authorizationService.handleNewPassword(this.cognitoUser, this.userAttributes, this.username, this.newPassword)
      .subscribe(
        () => this.router.navigate(['']),
        (error: Error) => {
          this.errorMessage = error.message;
        }
      );
    } else {
      this.errorMessage = 'Boths passwords not match!';
    }
  }

  forgotPassword(): void {
    this.errorMessage = null;

    if (this.username && this.username !== '') {
      this.authorizationService.initiateForgotPassword(this.username)
        .subscribe(
          res => {
            this.pageState = 'FORGOT_PASSWORD';
            this.cognitoUser = res.cognitoUser;
          },
          (error: Error) => {
            this.errorMessage = error.message;
          }
        );
    } else {
      this.errorMessage = 'Please enter username to proceed with password recovery.';
    }
  }

  resetPassword(): void {
    this.errorMessage = null;

    if (this.newPassword === this.passwordRepeat) {
      this.authorizationService.handleForgotPassword(this.cognitoUser, this.verificationCode, this.newPassword)
      .subscribe(
        () => {
        this.pageState = null;
        this.errorMessage = null;
        },
        (error: Error) => {
          this.errorMessage = error.message;
        }
      );
    } else {
      this.errorMessage = 'Boths passwords not match!';
    }
  }

}
