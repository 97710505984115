import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DropdownFilterProperty, FilterProperty, MinMax } from '../../../../../components/components/organisms/filter/filter.component';
import { Product, ProductFilterInput, ProductInput } from '../../../generated/graphql';
import { ConfirmationComponent } from '../confirmation/confirmation.component'
import { DialogService } from '../dialog/dialog.service';
import { ProductAdapter } from '../../pages/products-import/product.adapter';
import { PRODUCT_MINIMAL_PROPERTIES, PRODUCT_PROPERTIES_BY_FAMILY } from '../../services/product-properties.service';
import { DialogConfig } from '../dialog/dialog-config';
import { DialogRef } from '../dialog/dialog-ref';
import { ProductsService } from 'src/app/api/products.service';
import { mapProductToPrintable } from 'src/app/services/convert-product-to-printable.function';



@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.html',
  styleUrls: ['./product-edit.scss'],
})
export class ProductEditComponent implements OnInit, OnDestroy, AfterViewInit {

  properties: Array<FilterProperty> = [];
  values: FormGroup = new FormGroup({});
  startYear = new Date().getFullYear();
  range = [];
  successMessage: string;
  errorMessages: Array<string>;
  headline: string;
  productEdit: boolean = false;
  numberLoadedProducts: any;
  products: any;
  totalProducts: number;
  

  constructor(
    private productsService: ProductsService,
    private productAdapter: ProductAdapter,
    public dialogService: DialogService,
    public config: DialogConfig,
    public dialogEdit: DialogRef,
    
  ) { }

  @Input()
  set product(product: Product) {
    this._product = this.removeNotApplicables(this.productAdapter.adapt(product));
    const allColumns = this.properties
      .filter(property => !this.describingFields.includes(property.name) && property.name !== '__typename')
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    this.properties = [
      ...this.properties.filter(property => this.describingFields.includes(property.name)),
      ...allColumns
    ];
    this.previousProductName = product.name;
  }

  get product(): Product {
    return this._product;
  }

  @Input() options: any;

  private previousProductName: string;

  private _product: Product;

  private describingFields = ['supplier', 'name', 'family', 'type', 'subType']; // TODO: might remove from here

  private sub: Subscription;

  private sub1: Subscription;

  private filterInput: Partial<ProductFilterInput>;
 
  async ngOnInit() {
    if (this.config?.data?.product) this.product = this.config.data?.product;
    if (this.config?.data?.options) this.options = this.config?.data?.options;
    this.properties = this.restrictFamily(PRODUCT_MINIMAL_PROPERTIES);
    this.productEdit = this.product ? true : false;
    this.headline = this.productEdit ? 'Edit product' : 'Add new product';
  }

  ngAfterViewInit() {
      this.sub = this.values.controls["family"].valueChanges
        .pipe(
          distinctUntilChanged()
        )
        .subscribe(family => {
          if (this.product) {
            this.product.family = family;
          }
          this.setProperties(family)
         
        });
    this.sub1 =  this.values.valueChanges
      .pipe(
        distinctUntilChanged()
      )
      .subscribe(family => {
        if(family.biocontent) {
          if(family.biocontent.value == null) {          
            family.mass = null;
            family.carbon = null;
          }
        }
        if(family.type != null){
        this.setPropertySubType(family.type, family.family)
      }});
      if (this.product) {
        setTimeout(() => this.values.controls["family"].setValue(this.product.family));
      }
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.sub1?.unsubscribe();
  }

  onClose() {
    this.dialogEdit.close('close');
  }
  
  containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
  }

 
  async submit() {
    delete this.values.value.undefined
    const productInputs: Array<ProductInput> = [this.values.value];
    
    if(productInputs[0].family == null || productInputs[0].name == null ||
       productInputs[0].supplier == null || productInputs[0].type == null ){
       this.errorMessages = ["Supplier, Product Name, Type, BaseType shouldn't be empty."];   
    }
    else if(this.headline == 'Add new product') {
      if (this.containsSpecialChars(productInputs[0].name)) {
        const duplicate =  Object.assign({}, { name: { contains: productInputs[0].name.replace(/\s+/g, ' ',).trim()} });
        this.productsService.fetchFilterOptions(duplicate).then(options => this.totalProducts = options._total);
        this.products = (await this.productsService.fetchByFilter(duplicate)).map(mapProductToPrintable);
        
        for( let i = 0 ; i< this.products.length;i++)
        {
          if(this.products[i].name.toLowerCase() == productInputs[0].name.toLowerCase().replace(/\s+/g, ' ',).trim())
          {
            return this.errorMessages = ["Product" + " "+"'" + productInputs[0].name+"'" + " " +"already exists"];
          } 
        }
        
        const duplicate1 =  Object.assign({},  { name: { contains: productInputs[0].name.replace(/-+/g, ' ',).trim()} });
        this.productsService.fetchFilterOptions(duplicate1).then(options => this.totalProducts = options._total);
        this.products = (await this.productsService.fetchByFilter(duplicate1)).map(mapProductToPrintable);
        
        for( let i = 0 ; i< this.products.length;i++)
        {
          if(this.products[i].name.toLowerCase() == productInputs[0].name.toLowerCase().replace(/-+/g, ' ',).trim())
          {
            return this.errorMessages = ["Product" + " "+"'" + productInputs[0].name+"'" + " " +"already exists"];
          }
        }  
     }
      if (!this.containsSpecialChars(productInputs[0].name)) {
        const duplicate =  Object.assign({},  { name: { contains: productInputs[0].name.replace(/\s+/g, ' ',).trim()} });
        this.productsService.fetchFilterOptions(duplicate).then(options => this.totalProducts = options._total);
        this.products = (await this.productsService.fetchByFilter(duplicate)).map(mapProductToPrintable);
        for( let i = 0 ; i< this.products.length;i++)
        {
          if(this.products[i].name.toLowerCase() == productInputs[0].name.toLowerCase().replace(/\s+/g, ' ',).trim())
          {
            return this.errorMessages = ["Product" + " "+"'" + productInputs[0].name+"'" + " " +"already exists"];
          }
      }
        
        const duplicate1 =  Object.assign({}, { name: { contains: productInputs[0].name.replace(/\s+/g, '-',).trim()} });
        this.productsService.fetchFilterOptions(duplicate1).then(options => this.totalProducts = options._total);
        this.products = (await this.productsService.fetchByFilter(duplicate1)).map(mapProductToPrintable);
        for( let i = 0 ; i< this.products.length;i++)
        {
          if(this.products[i].name.toLowerCase() == productInputs[0].name.toLowerCase().replace(/\s+/g, '-',).trim())
          {
            return this.errorMessages = ["Product" + " "+"'" + productInputs[0].name+"'" + " " +"already exists"];
          }
        }

        const duplicate2 =  Object.assign({},  { name: { contains: productInputs[0].name.replace(/ /, '-',).trim()} });
        this.productsService.fetchFilterOptions(duplicate2).then(options => this.totalProducts = options._total);
        this.products = (await this.productsService.fetchByFilter(duplicate2)).map(mapProductToPrintable);
        
        for( let i = 0 ; i< this.products.length;i++)
        {
          if(this.products[i].name.toLowerCase() == productInputs[0].name.toLowerCase().replace(/ /, '-',).trim())
          {
            return this.errorMessages = ["Product" + " "+"'" + productInputs[0].name+"'" + " " +"already exists"];
          }
        }
      }
    this.errorMessages = null;
    const ref = this.dialogService.open(ConfirmationComponent, { data: { message: 'Submit changes to data reviewer?', submit: 'Yes, submit', cancel: 'Back' } });
    ref.afterClosed.subscribe(result => {
      if (result === 'submit') {
        const productInputs: Array<ProductInput> = [this.values.value];
        if (this.previousProductName != null && this.previousProductName !== this.values.value.name) {
          const deleteProductRequest = Object.assign({}, this.product, { isDeleted: true });
          delete deleteProductRequest.__typename;
          productInputs.push(deleteProductRequest);
        }
        this.productsService.createProductsChangeRequest(productInputs)
          .then(_ => {
            this.errorMessages = null;
            this.successMessage = "Product request send successfully";            
            setTimeout(() => { this.dialogEdit.close() }, 1000)
          })
          .catch(err => this.errorMessages = err?.graphQLErrors?.map(e => e.message));
      }
    });
   } else {
    const ref = this.dialogService.open(ConfirmationComponent, { data: { message: 'Submit changes to data reviewer?', submit: 'Yes, submit', cancel: 'Back' } });
    ref.afterClosed.subscribe(result => {
      if (result === 'submit') {
        const productInputs: Array<ProductInput> = [this.values.value];
        if (this.previousProductName != null && this.previousProductName !== this.values.value.name) {
          const deleteProductRequest = Object.assign({}, this.product, { isDeleted: true });
          delete deleteProductRequest.__typename;
          productInputs.push(deleteProductRequest);
        }
        this.productsService.createProductsChangeRequest(productInputs)
          .then(_ => {
            this.errorMessages = null;
            this.successMessage = "Product request send successfully";            
            setTimeout(() => { this.dialogEdit.close() }, 1000)
          })
          .catch(err => this.errorMessages = err?.graphQLErrors?.map(e => e.message));
      }
    });
   }
  }

  requestRemoval() {
    const ref = this.dialogService.open(ConfirmationComponent, {
      data: {
        message: 'Are you really sure you want to request the removal of this product from the data base?',
        submit: 'Yes, request', cancel: 'Back'
      }
    });
    ref.afterClosed.subscribe(result => {
      if (result === 'submit') {
        this.productsService.deleteProduct(this.values.value.name)
          .then(_ => {
            this.successMessage = "Product removal request send successfully";
            this.errorMessages = null;
            setTimeout(() => { this.dialogEdit.close() }, 1000)
          })
          .catch(err => this.errorMessages = err?.graphQLErrors?.map(e => e.message));
      }
    });
  }

  private setProperties(family: string) {
   this.properties = this.restrictFamily(PRODUCT_PROPERTIES_BY_FAMILY[family], family);
   
    for(let i =0; i<this.properties.length;i++)
    {
      if (this.properties[i] == undefined) { 
        this.properties.splice(i,1); 
    }
  }
    const propertySet = new Set(this.properties.map(prop => prop.name));
    Object.keys(this.values.controls)
      .filter(prop => !propertySet.has(prop))
      .forEach(prop => {
        this.values.removeControl(prop);
      });
  }

  async onSelectFilter(val: Record<string, number | boolean | string | MinMax>, prop:any, propname:any) {
    this.filterInput = this.convertToFilterInput(val);
    const data:any = await this.productsService.fetchFilterOptions(this.filterInput);
    if(propname == 'type'){
    const type= Object.assign({}, prop, <DropdownFilterProperty>{
      type: 'datalist',
      options:Object.assign(data.type ?? [], data.type ?? [])});
      this.properties.splice(3,0,type)
    }
    if(propname == 'subType'){
      const subType= Object.assign({}, prop, <DropdownFilterProperty>{
        type: 'datalist',
        options:Object.assign(data.subType ?? [], data.subType ?? [])});
        this.properties.splice(4,0,subType)
    }
  }

  async setPropertySubType(type:any, family:any){
    const val: Record<string, number | boolean | string | MinMax> = {family: family, supplier: null, type: type, subType: null}
    this.filterInput = this.convertToFilterInput(val);
    const data = await this.productsService.fetchFilterOptions(this.filterInput);
    for(let i =0; i<this.properties.length;i++)
      {
        if (this.properties[i].name == 'subType') { 
         this.properties[i].options =data.subType;
        }
      }
  }

  private convertToFilterInput($event: Record<string, number | boolean | string | MinMax | Array<any>>): ProductFilterInput {
      const filterInputEntries = Object.entries($event)
        .filter(([key, value]) => value != null && ((typeof value === 'string' && value !== 'ALL') || ((value as MinMax).min != null || (value as MinMax).max != null) || typeof value === 'number' || typeof value === 'boolean' || (Array.isArray(value) && (value as Array<any>)?.length)))
        .map(([key, value]) => {
          if (key === 'hasBiocontent') {
            if (value && ($event['biocontent'] as MinMax)?.min == null && ($event['biocontent'] as MinMax)?.max == null) {
              return ['biocontent', {min: 0.000001}];
            } else {
              return null;
            }
          }
          if (typeof value === 'string') {
            if(value.length == 0){ return null;}
            else{
            return [key, {contains: value}];
            }
          }
          if (Array.isArray(value) && (value as Array<any>)?.length) {
            return [key, {in: value}];
          }
          if (typeof value === 'boolean') {
            return [key, {is: value}];
          }
          return [key, value];
        });
        filterInputEntries.push(['isDeleted', {is: false}]); // If "Archived/Deleted" products want to be filtered, remove this line
      return filterInputEntries.length ? Object.fromEntries(filterInputEntries.filter(e => e != null)) : null;
  }

  private restrictFamily(properties: Array<FilterProperty>, family?:string): Array<FilterProperty> {
    return properties
      .filter(prop => prop.name !== 'hasBiocontent')
      .map(prop => {
        if (prop.name === 'family') {
          return Object.assign({}, prop, <DropdownFilterProperty>{
            type: 'enum',
            options: Object.fromEntries(Object.keys(PRODUCT_PROPERTIES_BY_FAMILY).map(k => [k, k]))
          });
        } else if (prop.name === 'primaryApplication') {
          return Object.assign({}, prop, <DropdownFilterProperty>{
            type: 'enum',
            options: Object.fromEntries(this.options[prop.name]?.map(option => [option, option]) ?? [])
          });
        } else if (prop.name === 'biocontentMethod') {
          return Object.assign({}, prop, <DropdownFilterProperty>{
            type: 'enum',
            options: Object.fromEntries(this.options[prop.name]?.map(option => [option, option]) ?? [])
          });
        } else if (prop.name === 'feedstockType') {
          return Object.assign({}, prop, <DropdownFilterProperty>{
            type: 'enum',
            options: Object.fromEntries(this.options[prop.name]?.map(option => [option, option]) ?? [])
          });
        } 
        else if (prop.name === 'year') {
          const option:any = this.startyear();
          return Object.assign({}, prop, <DropdownFilterProperty>{
            type: 'enum',
            options: Object.fromEntries(option?.sort((a,b) => b - a).map(option => [option, option]))
          });
        } else if (prop.name === 'supplier' || prop.name === 'type' || prop.name === 'subType') {
          if(family == undefined)
          {
            return Object.assign({}, prop, <DropdownFilterProperty>{
               type: 'datalist',
               options: Object.assign(this.options[prop.name]?.map(option => option) ?? [], this.options[prop.name]?.map(option => option) ?? [])
              });
          } else if(prop.name ==='type') {
            const data: Record<string, number | boolean | string | MinMax> = {family: family, supplier: null, type: null, subType: null}
           const type:any = this.onSelectFilter(data, prop, prop.name);
           return type
          } else if(prop.name ==='subType'){
            const data: Record<string, number | boolean | string | MinMax> = {family: family, supplier: null, type: null, subType: null}
           const subtype:any= this.onSelectFilter(data, prop,prop.name);
           return subtype
          } else if(prop.name ==='supplier'){
            return Object.assign({}, prop, <DropdownFilterProperty>{
              type: 'datalist',
             options: Object.assign(this.options[prop.name]?.map(option => option) ?? [], this.options[prop.name]?.map(option => option) ?? [])
             });
          }
        } 
        else {
          return prop
        }
      });
  }

  private removeNotApplicables(product: Product): Product {
    const p = Object.assign({}, product);
    Object.keys(p)
    return p;
  }

  private startyear():Array<any>{
    const year = new Date().getFullYear();
    const range = [];
    range.push(year);
      for (let i = 1; i < 50; i++) {
          range.push(year - i);
      }
    return range
  }
}
