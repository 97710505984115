import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'dynamic-filter-input',
  templateUrl: './dynamic-filter-input.component.html',
  styleUrls: ['./dynamic-filter-input.component.scss']
})

export class DynamicFilterInputComponent implements OnInit {
  @Input()
  type: 'number' | 'boolean' | 'enum' | 'multi-enum' | 'string';

  @Input()
  name: string;

  @Input()
  label: string;

  @Input()
  set options(options: Record<string, string>) {
    if (options) {
      const selected: Array<string> = this.formGroup.controls[this.name]?.value;
      this.sortedOptions = Object.entries(options)
        .map(([key, value]) => ({key, value, selected: !!(selected?.includes(value))}))
        .sort((a, b) => (a.key ?? '').toLowerCase().localeCompare((b.key ?? '').toLowerCase()));
    } else {
      this.sortedOptions = [];
    }
  }

  sortedOptions: Array<{key: string, value: string}> = [];

  @Input()
  formGroup: FormGroup;

  ngOnInit(): void {
    if (!this.formGroup.controls[this.name]) {
      this.formGroup.addControl(
        this.name,
        this.type === 'number' ?
          new FormGroup({
            min: new FormControl(),
            max: new FormControl(),
          })
          : new FormControl()
      )
    }
  }

  onChange(values: Array<string>) {
    this.formGroup.controls[this.name].setValue(values);
  }
}

