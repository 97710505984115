import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'missinreplaceNullWithText'
})
export class MissinreplaceNullWithTextPipe implements PipeTransform {

  transform(value: any, repleceText: string = 'N/A'): any {
    if (typeof value === 'undefined' || value === null) {
      return repleceText;
    }
    if (value === 'name') {
      return 'Product Name';
    }
    if (value === 'family') {
      return 'Base Type';
    }
    if (value === 'supplier') {
      return 'Supplier';
    }
    if (value === 'type') {
      return 'Type';
    }

    return value;
  }

}
