import { Component, OnInit } from '@angular/core';
import { flatten, groupBy, uniq } from 'lodash';
import { ConfirmationComponent } from 'src/app/components/confirmation/confirmation.component';
import { DialogService } from 'src/app/components/dialog/dialog.service';
import { OverlayCreatorService } from '../../../../../components/components/molecules/overlay-creator.service';
import { ModifiedChangeRequestInput, Product, PropertyChangeRequest } from '../../../generated/graphql';
import { ProductsService } from '../../api/products.service';
import { ProductDetailsComponent } from '../../components/product-details/product-details.component';
import { PRODUCT_PROPERTY_LABELS, PRODUCT_MINIMAL_PROPERTIES } from '../../services/product-properties.service';

@Component({
  selector: 'app-change-requests',
  templateUrl: './change-requests.html',
  styleUrls: ['./change-requests.scss']
})
export class ChangeRequestsComponent implements OnInit {
  changeRequestGroups: Array<ChangeRequestGroup> = [];
  modifyState = {
    open: false,
    id: 0,
  }

  newValue: any;
  decl:any=[];
  bulkproduct:any=[];
  accep:any=[]

  LABELS = PRODUCT_PROPERTY_LABELS;

  IS_REQUIRED = Object.fromEntries(PRODUCT_MINIMAL_PROPERTIES
    .filter(prop => prop.required)
    .map(prop => [prop.name, prop.required]));

  isLoadingMore = false;
  isNoMoreAvailable = false;
  page = 1;
  isDirty = false;

  collected: Array<['accepted' | 'rejected' | 'modified', PropertyChangeRequest, ModifiedChangeRequestInput?]> = [];
  collectedStatesById: {[id: number]: 'accepted' | 'rejected' | 'modified'} = {};
  collectedPrevious: {[id: number]: any} = {};

  constructor(
    private productsService: ProductsService,
    private overlayCreatorService: OverlayCreatorService,
    public dialogService: DialogService,
  ) { }

  async ngOnInit() {
    this.changeRequestGroups = await this.fetchAndGroupOpenRequests(1);
  }

  openProductDetails(product: Product) {
    this.overlayCreatorService.open(ProductDetailsComponent, { product });
  }

  async acceptRequest(request: ChangeRequestGroup) {
    for(let i=0;i<request.changeRequestsPerProducts.length;i++) {
      if(request.changeRequestsPerProducts[i].product == null){
      this.bulkproduct.push(request.changeRequestsPerProducts[i].changeRequests);
      this.accep.push(request.changeRequestsPerProducts[i]);
      }
    }
    const ref = this.dialogService.open(ConfirmationComponent, { data: { message: 'Accept open change request?', submit: 'Yes, accept', cancel: 'Back' } });
    ref.afterClosed.subscribe(async result => {
      if (result === 'submit') {
         if(this.collected.length != 0){
            for(let i=0;i<this.collected.length;i++) {
              if(this.collected[i][0] == 'rejected') {
                await this.productsService.respondProductChangeRequests([],[this.collected[i][1].id]);
                this.collected[i][1].state = 'rejected';
                for(let j=0;j<this.bulkproduct.length;j++){
                  for(let k=0;k<this.bulkproduct[j].length;k++){
                  if(this.bulkproduct[j][k].id == this.collected[i][1].id){
                    this.bulkproduct[j].splice(k,1);
                  }
                 }
                }
              }
            } 
            await this.productsService.respondProductChangeRequests(flatten(request.changeRequestsPerProducts.map(r => r.changeRequests.map(c => c.id))));
            for(let i =0;i<this.collected.length;i++) {
             if(this.collected[i][0] == 'modified') {
              await this.productsService.respondProductChangeRequests(null, null, [this.collected[i][2]]);
             }
            } 
         } else {
          await this.productsService.respondProductChangeRequests(flatten(this.accep.map(r => r.changeRequests.map(c => c.id))));
          request.changeRequestsPerProducts.forEach(r => r.changeRequests.forEach(c => c.state = 'accepted'));  
        }
        this.bulkproduct = null;
        this.isDirty = true;
        window.location.reload();
      }
    })
  }

  async declineRequest(request: ChangeRequestGroup) {
    for(let i=0;i<request.changeRequestsPerProducts.length;i++) {
      if(request.changeRequestsPerProducts[i].product == null){
        this.accep.push(request.changeRequestsPerProducts[i]);
      }
    }
    const ref = this.dialogService.open(ConfirmationComponent, { data: { message: 'Decline open change request?', submit: 'Yes, decline', cancel: 'Back' } });
    ref.afterClosed.subscribe(async result => {
      if (result === 'submit') {
        await this.productsService.respondProductChangeRequests([], flatten(this.accep.map(r => r.changeRequests.map(c => c.id))));
        request.changeRequestsPerProducts.forEach(r => r.changeRequests.forEach(c => c.state = 'rejected'));
        this.isDirty = true;
        window.location.reload();
      }
    })
  }

  async acceptProduct(productRequests: ChangeRequestsPerProduct) {
    this.decl = productRequests.changeRequests;
    const ref = this.dialogService.open(ConfirmationComponent, { data: { message: 'Accept product change request?', submit: 'Yes, accept', cancel: 'Back' } });
    ref.afterClosed.subscribe(async result => {
      if (result === 'submit') {
        if(this.collected != null){
          for(let i =0;i<this.collected.length;i++) {
            if(this.collected[i][0] == 'rejected') {
              if(this.collected[i][1].productName == productRequests.changeRequests[0].productName){
              await this.productsService.respondProductChangeRequests([],[this.collected[i][1].id]);
              this.collected[i][1].state = 'rejected';
              }
              for(let j =0;j<this.decl.length;j++){
                if(this.decl[j].id == this.collected[i][1].id){
                  this.decl.splice(j,1);
                }
              }
            }
          } 
        await this.productsService.respondProductChangeRequests(this.decl.map(r => r.id));
        (this.decl).map(request => request.state = 'accepted');
        for(let i =0;i<this.collected.length;i++) {
          if(this.collected[i][0] == 'modified') {
            if(this.collected[i][1].productName == productRequests.changeRequests[0].productName){
              await this.productsService.respondProductChangeRequests(null, null, [this.collected[i][2]]);
            } 
          }
        }
      }
      this.isDirty = true;
      this.collected = null;
      window.location.reload();
    }
    })
  }

  async declineProduct(productRequests: ChangeRequestsPerProduct) {
    const ref = this.dialogService.open(ConfirmationComponent, { data: { message: 'Decline product change request?', submit: 'Yes, decline', cancel: 'Back' } });
    ref.afterClosed.subscribe(async result => {
      if (result === 'submit') {
        await this.productsService.respondProductChangeRequests([], productRequests.changeRequests.map(r => r.id));
        (productRequests.changeRequests).map(request => request.state = 'rejected');
        this.isDirty = true;
        window.location.reload();
      }
    })
  }

  async accept(request: PropertyChangeRequest, directSubmit: boolean) {
    if (directSubmit) {
      const ref = this.dialogService.open(ConfirmationComponent, { data: { message: 'Accept product property change request?', submit: 'Yes, accept', cancel: 'Back' } });
      ref.afterClosed.subscribe(async result => {
        if (result === 'submit') {
          await this.productsService.respondProductChangeRequests([request.id]);
          request.state = 'accepted';
          this.isDirty = true;
          window.location.reload();
        }
      })
    } else {
      this.collected.push(['accepted', request]);
      this.collectedStatesById[request.id] = 'accepted';
    }
  }

  catchFormValues(e) {
    this.newValue = e;
  }

  async submitModification(request: PropertyChangeRequest, directSubmit: boolean) {
    if (this.newValue !== undefined) {
      let changes: ModifiedChangeRequestInput = {
        changeId: request.id,
        newNumericValue: {

        },
        newTextualValue: null,
      }
      
      if (request.numericValue.value != null ||request.numericValue.isNotApplicable) {
        changes.newNumericValue = {
          value: this.newValue !== 'not applicable' && this.newValue != null ? Number(this.newValue) : null,
          isNotApplicable: this.newValue === 'not applicable' ? true : false
        }
      } else {
        changes.newTextualValue = this.newValue ? this.newValue : null
      }
      if (directSubmit) {
        await this.productsService.respondProductChangeRequests(null, null, [changes]);
        request.state = 'modified';
        this.isDirty = true;
        window.location.reload();
      } else {
        this.collected.push(['modified', request, changes]);
        this.collectedStatesById[request.id] = 'modified';
        this.collectedPrevious[request.id] = JSON.parse(JSON.stringify(request));

        this.modifyState.id = 0;
        this.modifyState.open = false;

        request.booleanValue = changes.booleanValue;
        if (changes.newNumericValue) {
          if(changes.newNumericValue.hasOwnProperty('isNotApplicable') == false){
            changes.newNumericValue.isNotApplicable = false;
          }
          request.numericValue = {...changes.newNumericValue, __typename: 'NumericProperty'};
        }
        request.textValue = changes.newTextualValue;
      }
    } else {
      this.abortModification();
    }
  }

  modify(request: PropertyChangeRequest) {
    this.modifyState.id = request.id;
    this.modifyState.open = true;
    this.newValue = undefined;
  }

  abortModification() {
    this.modifyState.id = 0;
    this.modifyState.open = false;
    this.newValue = undefined;
  }

  async decline(request: PropertyChangeRequest, directSubmit: boolean) {
    if (directSubmit) {
      const ref = this.dialogService.open(ConfirmationComponent, { data: { message: 'Decline product property change request?', submit: 'Yes, decline', cancel: 'Back' } });
      ref.afterClosed.subscribe(async result => {
        if (result === 'submit') {
          await this.productsService.respondProductChangeRequests([], [request.id]);
          request.state = 'rejected';
          this.isDirty = true;
          window.location.reload();
        }
      })
    } else {
      this.collected.push(['rejected', request]);
      this.collectedStatesById[request.id] = 'rejected';
    }
  }

  async submitCollected(changeRequestsPerProduct: ChangeRequestsPerProduct) {
    const collected = this.collected.filter(([key, request]) => changeRequestsPerProduct.changeRequests.some(r => r.id === request.id));

    const accepted = collected.filter(([key, request, change]) => key === 'accepted').map(([key, request]) => request);
    const rejected = collected.filter(([key, request]) => key === 'rejected').map(([key, request]) => request);
    const modified = collected.filter(([key, request, change]) => key === 'modified').map(([key, request, change]) => [request, change] as [PropertyChangeRequest, ModifiedChangeRequestInput]);

    await this.productsService.respondProductChangeRequests(
      accepted.map(request => request.id),
      rejected.map(request => request.id),
      modified.map(([request, change]) => change)
    );

    collected.forEach(([key, request, change]) => request.state = key);
    this.collected = this.collected.filter(([key, request]) => !changeRequestsPerProduct.changeRequests.some(r => r.id === request.id));
    this.collectedStatesById = Object.fromEntries(this.collected.map(([key, request]) => [request.id, key]));
    this.isDirty = true;
    window.location.reload();
  }

  revertChange(requestId: number, changeRequestsPerProduct: ChangeRequestsPerProduct) {
    this.collected = this.collected.filter(([type, request]) => request.id !== requestId);
    changeRequestsPerProduct.changeRequests = changeRequestsPerProduct.changeRequests
    .map(request => {
      if (request.id === requestId && this.collectedStatesById[requestId] === 'modified')  {
        return this.collectedPrevious[requestId];
      }
      return request;
    });
    delete this.collectedStatesById[requestId];
    delete this.collectedPrevious[requestId];
  }

  isAllRequiredsSet(changeRequests: Array<PropertyChangeRequest>): boolean {
    return this.collected.some(([key, request]) => changeRequests.some(r => r.id === request.id))
      && !changeRequests
        .some(r =>
          r.state === 'open'
          && this.IS_REQUIRED.hasOwnProperty(r.propertyName)
          && !this.collectedStatesById.hasOwnProperty(r.id)
        );
  }

  async loadMoreRequests() {
    this.page++;
    this.isLoadingMore = true;

    const newOnes = await this.fetchAndGroupOpenRequests(this.page);

    this.changeRequestGroups = [...(this.changeRequestGroups ?? []), ...newOnes];

    this.isLoadingMore = false;
    this.isNoMoreAvailable = !(newOnes?.length);
  }

  private async fetchAndGroupOpenRequests(page: number): Promise<Array<ChangeRequestGroup>> {
    const changeRequests = await this.productsService.fetchProductsChangeRequests(page);
    if (changeRequests.length === 0) {
      return [];
    }
    const products = groupBy(
      await this.productsService.fetchAllByFilter({ name: { in: uniq(changeRequests.map(r => r.productName)) } }),
      p => p.name
    );
    return this.groupRequests(changeRequests, products);
  }

  private groupRequests(changeRequests: Array<PropertyChangeRequest>, productsByName: Record<string, Array<Product>>): Array<ChangeRequestGroup> {
    return Object.values(groupBy(changeRequests, p => `${p.createdAt}-${p.createdBy}`))
      .map(requests => (<ChangeRequestGroup>{
        createdAt: requests[0].createdAt ? new Date(requests[0].createdAt * 1000).toUTCString() : "uncertain",
        createdBy: requests[0]?.createdBy,
        changeRequestsPerProducts:
          Object.entries(groupBy(requests, r => r.productName))
            .map(([productName, changeRequests]) =>
            (<ChangeRequestsPerProduct>{
              changeRequests: changeRequests,
              product: productsByName[productName] ? productsByName[productName][0] : null
            }))
      }))
  }

}

type ChangeRequestsPerProduct = {
  product?: Product,
  changeRequests: Array<PropertyChangeRequest>
};

type ChangeRequestGroup = {
  createdAt: string,
  createdBy: string
  changeRequestsPerProducts: Array<ChangeRequestsPerProduct>
};
