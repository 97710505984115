import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { userGQL } from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private userGQL: userGQL,
  ) { }

  getUser = () => this.userGQL.fetch().pipe(map(r => r.data.getUser)).toPromise();

}
