import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'multi-interactions',
    templateUrl: 'multi-interactions.html',
    styleUrls: ['multi-interactions.scss']
})

export class MultiInteractions {
  @Output()
  showEmptyFields = new EventEmitter();
  showEmptyOnly: boolean = false;

  onChangeEmptyFields(change) {
    this.showEmptyFields.emit(change);
  }
}


