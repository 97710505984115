export const PROPERTY_ORDERS_PER_FAMILY: Record<string, Array<string>> = {
  MinimalProduct: [
    'family',
    'supplier',
    'name',
    'type',
    'subType',
    // "isDeleted",
  ],
  Coreactant: [
    'family',
    'supplier',
    'name',
    'type',
    'subType',
    'primaryApplication',
    'carbonFootprint',
    'year',
    'includingBioCarbon',
    'excludingBioCarbon',
    'equivalentWeight',
    'functionality',
    'viscosityMPaS',
    'viscosityRange',
    'viscosityTemperature',
    'viscosityMethod',
    'solids',
    'solvent',
    'OH',
    'acid',
    'OHNumber',
    'COOH',
    'previousName',
    'amineNumber',
    'PAEComposition',
    'MFFT',
    'hasBiocontent',
    'biocontent',
    'carbon',
    'mass',
    'biocontentMethod',
    'feedstockType',
    'biodegradability',
   // 'biodegradabilityMethod',
    'maxGardnerColor',
    'maxHazenAPHAPtCoColor',
    'maxIodineColor',
    'softeningPoint',
    'monomerBasis',
    'tg',
    'tg2'
    //"isDeleted",
  ],
  Dispersion: [
    'family',
    'supplier',
    'name',
    'type',
    'subType',
    'primaryApplication',
    'carbonFootprint',
    'year',
    'includingBioCarbon',
    'excludingBioCarbon',
    'solids',
    'solvent',
    'MFFT',
    'activationTemperature',
    'crystallization',
    'pH',
    'dispersionBasis',
    'OHNumber',
    'acid',
    'tg',
    'tg2',
    'typicalViscosityMPaS',
    'viscosityMPaS',
    'viscosityMethod',
    'viscosityRange',
    'hasBiocontent',
    'biocontent',
    'carbon',
    'mass',
    'biocontentMethod',
    'feedstockType',
    'biodegradability',
    //'biodegradabilityMethod',
    'anionicCationicNonionicDispersion',
    'monomerBasis',
    'maximumMonomer',
    'modulusMpa',
    'tensileStrengthMpa',
    'elongationAtBreak',
    'deblockTemperature',
    'blockingAgent',
    'meltingTemp',
    'COOH',
    'OH',
    'previousName',
    'equivalentWeight',
    'functionality',
    'maxGardnerColor',
    'maxHazenAPHAPtCoColor',
    'maxIodineColor',
    'MwByGPC',
    'PDIByGPC',
    'particleSize',
    'softeningPoint',
    // "isDeleted",
  ],
  Isocyanate: [
    'family',
    'supplier',
    'name',
    'type',
    'subType',
    'monomerBasis',
    'primaryApplication',
    'carbonFootprint',
    'year',
    'includingBioCarbon',
    'excludingBioCarbon',
    'NCO',
    'NCORange',
    'functionality',
    'viscosityMPaS',
    'viscosityTemperature',
    'viscosityRange',
    'solids',
    'solvent',
    'hasBiocontent',
    'biocontent',
    'carbon',
    'mass',
    'biocontentMethod',
    'feedstockType',
    'biodegradability',
    //'biodegradabilityMethod',
    'maxHazenAPHAPtCoColor',
    'maxIodineColor',
    'maxGardnerColor',
    'blockingAgent',
    'deblockTemperature',
    'previousName',
    'equivalentWeight',
    'maximumMonomer',
    //"isDeleted",
  ],
  NonreactiveResin: [
    'family',
    'supplier',
    'name',
    'type',
    'subType',
    //"isDeleted",
    'primaryApplication',
    'carbonFootprint',
    'year',
    'includingBioCarbon',
    'excludingBioCarbon',
    'viscosityMPaS',
    'viscosityRange',
    'viscosityMethod',
    'typicalViscosityMPaS',
    'solids',
    'solvent',
    'hasBiocontent',
    'biocontent',
    'carbon',
    'mass',
    'biocontentMethod',
    'feedstockType',
    'biodegradability',
    //'biodegradabilityMethod',
    'otherReactiveGroupPercentage',
    'functionality',
    'maxGardnerColor',
    'maxHazenAPHAPtCoColor',
    'maximumMonomer',
    'previousName',
  ],
  OtherReactiveSystems: [
    'family',
    'supplier',
    'name',
    'type',
    'subType',
    //"isDeleted",
    'primaryApplication',
    'carbonFootprint',
    'year',
    'includingBioCarbon',
    'excludingBioCarbon',
    'viscosityMPaS',
    'viscosityRange',
    'viscosityMethod',
    'typicalViscosityMPaS',
    'solids',
    'solvent',
    'hasBiocontent',
    'biocontent',
    'carbon',
    'mass',
    'biocontentMethod',
    'feedstockType',
    'biodegradability',
    //'biodegradabilityMethod',
    'otherReactiveGroupPercentage',
    'functionality',
    'maxGardnerColor',
    'maxHazenAPHAPtCoColor',
    'maximumMonomer',
    'previousName',
  ],
  EnergyCurableSolutions: [
    'family',
    'supplier',
    'name',
    'type',
    'subType',
    'biocontent',
    'carbon',
    'mass',
    'feedstockType',
    'primaryApplication',
    'carbonFootprint',
    'year',
    'includingBioCarbon',
    'excludingBioCarbon',
    'viscosityMPaS',
    'functionality',
    'maxGardnerColor',
    'maxHazenAPHAPtCoColor',
    'MwByGPC',
    'tg',
    'acid',
    'diluent',
    'density',
  ],
};
