import { Directive, ElementRef, Input, OnInit, OnDestroy, HostListener, Output, EventEmitter } from '@angular/core';
import { CheckboxGroupDirective } from './checkbox-group.directive';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { CheckboxGroup } from './checkbox-group.model';
import {  mergeMap, tap } from 'rxjs/operators';

@Directive({ selector: 'input[type="checkbox"][groupLead]' })
export class CheckboxGroupLeadDirective implements OnInit {
  @Input()
  groupLead: CheckboxGroup;

  @Output()
  change = new EventEmitter<{target: {checked: boolean}, internalChange: boolean}>();

  constructor(private elem: ElementRef) {
  }

  ngOnInit(): void {
    this.groupLead.checkboxGroupLead = this;
  }

  onValuesChange(hasTrue: boolean, hasFalse: boolean): void {
    this.elem.nativeElement.checked = hasTrue;
    this.elem.nativeElement.indeterminate = hasTrue && hasFalse;

    this.change.emit({target: {checked: hasTrue}, internalChange: true});
  }


  @HostListener('change', ['$event'])
  onChange(e): void {
    if (!e.internalChange) {
      const value = this.elem.nativeElement.checked;
      this.groupLead.setAllValues(value);
    }
  }
}
