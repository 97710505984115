import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'multi-dropdown',
  templateUrl: './multi-dropdown.component.html',
  styleUrls: ['./multi-dropdown.component.scss']
})

export class MultiDropdownComponent {
  
  @Input()
  options: Array<string>;

  @Input()
  selected: Array<string>;

  @Output()
  selectedChange = new EventEmitter<Array<string>>();

  // tslint:disable-next-line:no-input-rename
  @Input()
  dataUi: string;

  @Input()
  label: string;

  @Input()
  fields: { [key in string]: string } = {};

  showSelection = false;
  
  data=[];

  column=[];
  
  
  updateArray(){
    this.data = [];
    this.options = this.options.filter((x) => x !== '__typename');
    this.options = this.options.filter((x) => x !== 'hasBiocontent');
    this.showSelection = true;
    this.column =this.options;
    const sortOrder = ['family','supplier','name','type','subType','primaryApplication','solids','solvent','blockingAgent','MFFT','activationTemperature','crystallization','pH','dispersionBasis','OHNumber','acid','amineNumber','tg','tg2','PAEComposition','typicalViscosityMPaS','isDeleted','viscosityMPaS','viscosityMethod','viscosityRange','viscosityTemperature','equivalentWeight','maximumMonomer','functionality','diluent','maxGardnerColor','maxHazenAPHAPtCoColor','maxIodineColor','NCO','NCORange','MwByGPC','PDIByGPC','particleSize','modulusMpa','tensileStrengthMpa','elongationAtBreak','anionicCationicNonionicDispersion','deblockTemperature','meltingTemp','COOH','OH','softeningPoint','previousName','monomerBasis','density','biocontent','biocontentMethod','feedstockType','biodegradability','otherReactiveGroupPercentage'];
    this.column.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b));
    
    for(let i=0; i<this.column.length;i++)
    {
      this.data.push({option: this.column[i],show:false});
    }
    
    const dt:any = this.data;
    for(let i = 0; i < this.selected.length; i++) {
      for(let j = 0; j < dt.length; j++) {
          if(this.selected[i] == this.column[j]) {
            dt[j].show = true;
          }
      }
    }
    this.data = dt;
    
  }
  
  close(event: MouseEvent): void {
    event.stopPropagation();
    const element = event.target as HTMLInputElement;
    const className = element.className;
     if (className !== 'option') {
      this.showSelection = false;
    }
  }

  deselect(column: string): void {
    this.selected = this.selected.filter((x) => x !== column);
    this.selectedChange.emit(this.selected);
  }
 
  toogleOption(option) {
    
    if(option.show == false){
      for(let i =0; i<this.data.length;i++)
      {
        if(this.data[i].option == option.option){
          this.data[i].show = true;
        }
      }
      if(option.option == 'name'){
        this.selected.splice(0,0,option.option);
      }
      else if(option.option == 'supplier'){
        this.selected.splice(2,0,option.option);
      }
      else if(option.option == 'type'){
        this.selected.splice(3,0,option.option);
      }
      else if(option.option == 'subType'){
        this.selected.splice(4,0,option.option);
      }    
      else if(option.option == 'family'){
        this.selected.splice(1,0,option.option);
      }
      else if(option.option == 'primaryApplication'){
        this.selected.splice(5,0,option.option);
      }
      else if(option.option == 'solids'){
        this.selected.splice(6,0,option.option);
      }
      else if(option.option == 'solvent'){
        this.selected.splice(7,0,option.option);
      }
      else if(option.option == 'blockingAgent'){
        this.selected.splice(8,0,option.option);
      }
      else if(option.option == 'MFFT'){
        this.selected.splice(9,0,option.option);
      }
      else if(option.option == 'activationTemperature'){
        this.selected.splice(10,0,option.option);
      }
      else if(option.option == 'crystallization'){
        this.selected.splice(11,0,option.option);
      }
      else if(option.option == 'pH'){
        this.selected.splice(12,0,option.option);
      }
      else if(option.option == 'dispersionBasis'){
        this.selected.splice(13,0,option.option);
      }
      else if(option.option == 'OHNumber'){
        this.selected.splice(14,0,option.option);
      }
      else if(option.option == 'acid'){
        this.selected.splice(15,0,option.option);
      }
      else if(option.option == 'tg'){
        this.selected.splice(16,0,option.option);
      }
      else if(option.option == 'tg2'){
        this.selected.splice(17,0,option.option);
      }
      else if(option.option == 'typicalViscosityMPaS'){
        this.selected.splice(18,0,option.option);
      }
      else if(option.option == 'viscosityMPaS'){
        this.selected.splice(19,0,option.option);
      }
      else if(option.option == 'viscosityMethod'){
        this.selected.splice(20,0,option.option);
      }
      else if(option.option == 'viscosityRange'){
        this.selected.splice(21,0,option.option);
      }
      else if(option.option == 'equivalentWeight'){
        this.selected.splice(22,0,option.option);
      }
      else if(option.option == 'maximumMonomer'){
        this.selected.splice(23,0,option.option);
      }
      else if(option.option == 'functionality'){
        this.selected.splice(24,0,option.option);
      }
      else if(option.option == 'diluent'){
        this.selected.splice(25,0,option.option);
      }
      else if(option.option == 'maxGardnerColor'){
        this.selected.splice(26,0,option.option);
      }
      else if(option.option == 'maxHazenAPHAPtCoColor'){
        this.selected.splice(27,0,option.option);
      }
      else if(option.option == 'maxIodineColor'){
        this.selected.splice(28,0,option.option);
      }
      else if(option.option == 'MwByGPC'){
        this.selected.splice(29,0,option.option);
      }
      else if(option.option == 'PDIByGPC'){
        this.selected.splice(30,0,option.option);
      }
      else if(option.option == 'particleSize'){
        this.selected.splice(31,0,option.option);
      }
      else if(option.option == 'modulusMpa'){
        this.selected.splice(32,0,option.option);
      }
      else if(option.option == 'tensileStrengthMpa'){
        this.selected.splice(33,0,option.option);
      }
      else if(option.option == 'elongationAtBreak'){
        this.selected.splice(34,0,option.option);
      }
      else if(option.option == 'anionicCationicNonionicDispersion'){
        this.selected.splice(35,0,option.option);
      }
      else if(option.option == 'deblockTemperature'){
        this.selected.splice(36,0,option.option);
      }
      else if(option.option == 'meltingTemp'){
        this.selected.splice(37,0,option.option);
      }
      else if(option.option == 'COOH'){
        this.selected.splice(38,0,option.option);
      }
      else if(option.option == 'OH'){
        this.selected.splice(39,0,option.option);
      }
      else if(option.option == 'softeningPoint'){
        this.selected.splice(40,0,option.option);
      }
      else if(option.option == 'previousName'){
        this.selected.splice(41,0,option.option);
      }
      else if(option.option == 'monomerBasis'){
        this.selected.splice(42,0,option.option);
      }
      else if(option.option == 'density'){
        this.selected.splice(43,0,option.option);
      }
      else if(option.option == 'biocontent'){
        this.selected.splice(44,0,option.option);
      }
      else if(option.option == 'biocontentMethod'){
        this.selected.splice(45,0,option.option);
      }
      else if(option.option == 'feedstockType'){
        this.selected.splice(45,0,option.option);
      }
      else if(option.option == 'biodegradability'){
        this.selected.splice(46,0,option.option);
      }
      // else if(option.option == 'biodegradabilityMethod'){
      //   this.selected.splice(47,0,option.option);
      // }
      else if(option.option == 'NCO'){
        this.selected.splice(48,0,option.option);
      }
      else if(option.option == 'amineNumber'){
        this.selected.splice(49,0,option.option);
      }
      else if(option.option == 'isDeleted'){
        this.selected.splice(50,0,option.option);
      }
      else if(option.option == 'NCORange'){
        this.selected.splice(51,0,option.option);
      }
      else if(option.option == 'otherReactiveGroupPercentage'){
        this.selected.splice(52,0,option.option);
      } else {  
       this.selected = [...this.selected, option.option];
      }
    this.selectedChange.emit(this.selected);
    
    } else {
      for(let i =0; i<this.data.length;i++)
      {
        if(this.data[i].option == option.option){
          this.data[i].show = false;
        }
      }
      this.selected = this.selected.filter((x) => x !== option.option);
      this.selectedChange.emit(this.selected);
      
    }
  }
}


