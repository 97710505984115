import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';

interface DialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
}

const DEFAULT_CONFIG: DialogConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'dialog-panel'
}

@Injectable()
export class OverlayCreatorService {
  buttonClose:any;

  constructor(
    private overlay: Overlay
  ) { }

  open<T>(component: ComponentType<T>, properties = {}, config: DialogConfig = {}): OverlayRef {
    // Override default configuration
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(dialogConfig);

    // Close on click X button
    this.buttonClose =overlayRef;
    
    // Create ComponentPortal that can be attached to a PortalHost
    const componentPortal = new ComponentPortal(component);

    // Attach ComponentPortal to PortalHost
    const componentRef = overlayRef.attach(componentPortal);

    // Close on click outside
    overlayRef.backdropClick().subscribe(() => overlayRef.dispose());

    // assigning properties
    Object.entries(properties).forEach(([key, value]) =>
      componentRef.instance[key] = value);

    return overlayRef;
  }

  close<T>() {
    this.buttonClose.dispose();
  }

  private createOverlay(config: DialogConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(config: DialogConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return overlayConfig;
  }
}
