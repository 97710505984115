import { Injectable } from '@angular/core';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CognitoSingleSignOnService {
  private auth = new CognitoAuth({
    ClientId: environment.auth.clientId,
    AppWebDomain: environment.auth.appWebDomain,
    TokenScopesArray: ['email', 'profile', 'aws.cognito.signin.user.admin', 'openid'],
    RedirectUriSignIn: window.location.href.split('#')[0],
    RedirectUriSignOut: window.location.href.split('#')[0],
    IdentityProvider: environment.auth.identityProvider,
    UserPoolId: environment.auth.userPoolId,
    AdvancedSecurityDataCollectionFlag: false,
    // Storage
  });

  perfomSingleSignOn(): void {
    const url = window.localStorage.getItem('auth-href');
    if (!url) {
      this.auth.getSession();
    } else {
      this.auth.userhandler = {
        onSuccess: (result) => {
          window.localStorage.removeItem('auth-href');
        },
        onFailure: (err) => console.error(err),
      };
      this.auth.parseCognitoWebResponse(url);
    }
  }

  isLoggedIn(): boolean {
    if (this.auth.getSignInUserSession().getIdToken().getJwtToken() === '') {
      this.perfomSingleSignOn();
    } else {
      this.renewSessionIfExpired();
    }
    return this.auth.isUserSignedIn();
  }

  isSigningIn(): boolean {
    return !window.localStorage.getItem('auth-href') &&
      this.auth.getSignInUserSession().getIdToken().getJwtToken() === '';
  }

  getSignedInUsername(): Observable<string> {
    return of(this.auth
      .getUsername()
      .replace(environment.auth.identityProvider + '_', '')
    );
  }

  // TODO: keep url path
  renewSessionIfExpired(): void {
    const expires =
      this.auth.getSignInUserSession().getIdToken().getExpiration() * 1000;
    if (+new Date() > expires) {
      this.auth.clearCachedTokensScopes();
      this.perfomSingleSignOn();
    }
  }

  getAccessToken = () =>
    of(this.auth.getSignInUserSession().getIdToken().getJwtToken())

  logOut(): void {
    window.localStorage.removeItem('auth-href');
    this.auth.signOut();
  }
}
