<section data-ui="content">
  <div class="tabs">
    <button *ngFor="let tab of tabs; let i = index" [ngClass]="{ selected: selected === i }" data-ui="secondary"
      (click)="openTab(i)" [disabled]="tab.disabled">
      {{ tab.tabLabel }}
    </button>
  </div>
</section>
<section data-ui="content">
  <ng-container *ngIf="openedTab">
    <ng-container *ngTemplateOutlet="openedTab.template"></ng-container>
  </ng-container>
</section>
