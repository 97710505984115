import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CognitoSingleSignOnService } from './cognito-singlesignon.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  private loggedIn = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private singleSignOnService: CognitoSingleSignOnService
  ) {}

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.singleSignOnService.isLoggedIn()) {
      this.loggedIn.next(true);
      // logged in so return true
      return true;
    }

    if (this.singleSignOnService.isSigningIn()) {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/signing-in'], { queryParams: { returnUrl: decodeURI(state.url) } });
      this.loggedIn.next(false);
      return false;
    }

    // not authorized
    this.router.navigate(['/not-authorized']);
    this.loggedIn.next(false);
    return false;
  }
}
