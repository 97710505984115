import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'logTableData'
})
export class LogTableDataPipe implements PipeTransform {

  transform(value: any, repleceText: string = 'N/A'): any {
    if (typeof value === 'undefined' || value === null) {
      return repleceText;
    }
    if (value === 'rejected') {
        return '✘ Rejected';
    }
    if (value === 'accepted') {
      return '✔ Accepted';
    }
    return value;
  }
}

