import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input()
  get progress(): number {
    if (!this._progress) {
      return null;
    }
    return Math.round((this._progress / this.total) * 100);
  }
  set progress(progress: number) {
    this._progress = progress;
  }
  private _progress;
  @Input() total: number;
  color: string;


  constructor() { }

  ngOnInit(): void {

  }

}
