<div class="select customSelect" [class.open]="open" (click)="toggleOpen()">
  <label *ngIf="label">{{label}}</label>
  <div class="label">{{displayLabel}}</div>
</div>
<section *ngIf="open" class="dropdown" data-ui="primary secondary" [class.no-options]="!options.length">
  <div class="options">
    <label *ngFor="let option of options" class="resetted option" [class.changed]="option.changed">
      <div>
        <input type="checkbox" [ngModel]="option.selected" (ngModelChange)="toogleOption(option)">
      </div>
      <div>{{ option.label }}</div>
    </label>
  </div>
  <div *ngIf="!options.length">
    No options available
  </div>
  <ng-container *ngIf="options.length">
    <div>
      <button data-ui="small" (click)="apply()">
        Apply
      </button>
    </div>
    <div>
      <button data-ui="small secondary" (click)="reset()" class="reset">
        Reset
      </button>
    </div>
  </ng-container>
</section>
