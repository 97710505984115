<section data-ui="content">
  <div class="overlay-header">
    <span (click)="close()">
      <i class="fas fa-times"></i>
    </span>
  </div>
</section>
<section data-ui="content">
  <div class="actions">
    <button data-ui="small" (click)="editProduct()">Edit Product</button>
  </div>

  <table *ngIf="properties" >
    <tr *ngFor="let col of showColumns; let i = index">
      <td>{{ properties[col] }}</td>
      <td><app-product-property [value]="product[col]"></app-product-property></td>
    </tr>
  </table>
  <div *ngIf="product.supplier !== 'Covestro'">
    <a
    href="https://covestro.sharepoint.com/sites/CompetitorObservation/Competitor%20information/Forms/AllItems.aspx?useFiltersInViewXml=1&view=7&q={{product.name}}"
    outbound
    target="_blank">
      Show more information on {{ product.name }} <i class="fas fa-external-link-alt"></i>
    </a>
  </div>
</section>
