<section class="wrapper" >
  <form [formGroup]="propertiesForm" class="data-selection">
    <ng-container *ngIf="sortedProperties?.length">
      <label>
        <span>Diagram per: </span>
        <div class="select-wrapper">
          <div class="customSelect">
            <select formControlName="diagramPer">
              <option *ngFor="let k of sortedProperties;" [value]="k[0]">{{ k[1] }}</option>
            </select>
          </div>
          <span class="remove-dimension">
            <i *ngIf="propertiesForm.value.diagramPer" class="fas fa-times"
              (click)="propertiesForm.controls.diagramPer.setValue(null)"></i>
          </span>
        </div>
      </label>
      <label>
        <span>X-axis: </span>
        <div class="select-wrapper">
          <div class="customSelect">
            <select formControlName="x">
              <option *ngFor="let k of graphProperties;" [value]="k[0]">{{ k[1] }}</option>
            </select>
          </div>
          <span class="remove-dimension">
            <i *ngIf="propertiesForm.value.x" class="fas fa-times" (click)="propertiesForm.controls.x.setValue(null)"></i>
          </span>
        </div>
      </label>
      <label>
        <span>Y-axis: </span>
        <div class="select-wrapper">
          <div class="customSelect">
            <select formControlName="y">
              <option *ngFor="let k of graphProperties;" [value]="k[0]">{{ k[1] }}</option>
            </select>
          </div>
          <span class="remove-dimension">
            <i *ngIf="propertiesForm.value.y" class="fas fa-times" (click)="propertiesForm.controls.y.setValue(null)"></i>
          </span>
        </div>
      </label>
      <label>
        <span>Bubble size by: </span>
        <div class="select-wrapper">
          <div class="customSelect">
            <select formControlName="size">
              <option *ngFor="let k of graphProperties;" [value]="k[0]">{{ k[1] }}</option>
            </select>
          </div>
          <span class="remove-dimension">
            <i *ngIf="propertiesForm.value.size" class="fas fa-times"
              (click)="propertiesForm.controls.size.setValue(null)"></i>
          </span>
        </div>
      </label>
      <label>
        <span>Color per: </span>
        <div class="select-wrapper">
          <div class="customSelect">
            <select formControlName="color">
              <option *ngFor="let k of sortedProperties;" [value]="k[0]">{{ k[1] }}</option>
            </select>
          </div>
          <span class="remove-dimension">
            <i *ngIf="propertiesForm.value.color" class="fas fa-times"
              (click)="propertiesForm.controls.color.setValue(null)"></i>
          </span>
        </div>
      </label>
    </ng-container>
  </form>
</section>
<div>
  <section *ngFor="let data of items; let i = index" class="graphs">
    <h3 *ngIf="propertiesForm.value.diagramPer">{{ data['diagram'] }}</h3>
    <scatter-plot [items]="data.items" [xFn]="data.x" [xPropertyName]="propertiesForm.value.x" [yFn]="data.y"
      [yPropertyName]="propertiesForm.value.y" [sizeFn]="data.size" [sizePropertyName]="propertiesForm.value.size"
      [classificationFn]="data.color" [classificationPropertyName]="propertiesForm.value.color"
      [classificationColors]="data.classificationColors" [defaultColor]="colors[i % colors.length]"
      [selected]="selected[i]" [keyFn]="keyFn">
      <ng-container *template="let item=item">

        <div class="tooltip-bd">
          <b>{{ item.name }}</b>
          <span><br /><strong>Supplier:</strong> {{ item.supplier }}</span>
          <span><br /><strong>Type:</strong> {{ item.type }}
           <span *ngIf="item.subType"> - {{ item.subType }}</span></span>
          <span><br /><strong>{{ properties[propertiesForm.value.x]}}:</strong> {{ item[propertiesForm.value.x] }}</span>
          <span *ngIf="propertiesForm.value.y && propertiesForm.value.y !== propertiesForm.value.x
            && propertiesForm.value.y !== 'type' && propertiesForm.value.y !== 'subType' && propertiesForm.value.y !== 'supplier'">
            <br /><strong>{{properties[propertiesForm.value.y]}}:</strong> {{ item[propertiesForm.value.y] }}</span>
          <span *ngIf="propertiesForm.value.size && propertiesForm.value.size !== propertiesForm.value.x && propertiesForm.value.size !== propertiesForm.value.y
          && propertiesForm.value.size !== 'type' && propertiesForm.value.size !== 'subType' && propertiesForm.value.size !== 'supplier'">
            <br /><strong>{{properties[propertiesForm.value.size]}}:</strong> {{ item[propertiesForm.value.size] }}</span>
          <span *ngIf="propertiesForm.value.color && propertiesForm.value.color !== propertiesForm.value.x && propertiesForm.value.color !== propertiesForm.value.y && propertiesForm.value.color !== propertiesForm.value.size
          && propertiesForm.value.color !== 'type' && propertiesForm.value.color !== 'subType' && propertiesForm.value.color !== 'supplier'">
            <br /><strong>{{properties[propertiesForm.value.color]}}:</strong> {{ item[propertiesForm.value.color] }}</span>
        </div>
        <div class="button_footer">
          <!-- TODO: not properly working: chart not rerendering -->
          <button data-ui="small" *ngIf="isProductSelected.includes(item)" (mousedown)="removeProductInGraph(item, i)">Remove</button>
          <button data-ui="small" *ngIf="!isProductSelected.includes(item)" (mousedown)="selectProductInGraph(item, i)">Add to Comparison</button>
      </div>
  </ng-container>
    </scatter-plot>
    <div *ngIf="data.metrics">
      <div *ngIf="data.metrics.numberOfProducts"><label>Number of competitive products: </label><span>{{ data.metrics.numberOfProducts }}</span></div>
      <div *ngIf="data.metrics.notDisplayable"><label>Number of not listed data: </label><span>{{ data.metrics.notDisplayable.length }}</span>
        <a *ngIf="data.metrics.notDisplayable.length !== notDisplayable" (click) = "submit(data.metrics.notDisplayable, this.propertiesForm.value.x, this.propertiesForm.value.y)"
        [class.clickable]="clickEvents && clickEvents[col]">
        Show products with missing "{{this.propertiesForm.value.x}}" & "{{this.propertiesForm.value.y}}" <i class="fas fa-external-link-alt"></i>
      </a>
        
      </div>
      <div *ngIf="data.metrics.notSizable"><label>Number of not sizable nodes: </label><span>{{ data.metrics.notSizable }}</span></div>
      <div *ngIf="data.metrics.numberOfSelected"><label>Number of selected products: </label><span>{{ data.metrics.numberOfSelected }}</span></div>
    </div>
  </section>
</div>
