import { Component, OnInit } from '@angular/core';
import { DialogRef } from '../dialog/dialog-ref';

@Component({
  selector: 'app-bookmark',
  templateUrl: './bookmark.component.html',
  styleUrls: ['./bookmark.component.scss']
})
export class BookmarkComponent implements OnInit {

  constructor(
   public dialogEdit: DialogRef
   ) { }

  ngOnInit(): void {
  }
  onClose(){
   this.dialogEdit.close('close');
   
  }
}
