<div class="loghistory ">
  <a href="/">
    <button data-ui="secondary small">&lt; Back</button>
  </a>
  <h1>Log History</h1>
<div class="content" >
  <section class="main">
    <ng-container *ngIf="showSearchResult">
      <ng-container *ngIf="loading">
        <div class="loading-wrapper">
          <div class="loader"></div>
        </div>
      </ng-container>
      <div class="filter">
        <app-products-search class="item" (search)="onSearch($event)">
        </app-products-search>
      </div>
      <ng-container *ngIf="!loading ">
        <tabs *ngIf="products && fields && showFields" [preloadFn]="preloadTab" [selected]="this.selectedTab" (selectedChange)="onSetTab($event)">
          <ng-template tab-label>
            <app-log-table [data]="products.logsData"
            (loadItems)="loadTableItems($event)"
            (activeSelectValues)="onColoumnsChange($event)"
            [fields]="fields" [keyFn]="keyFn"
              [clickEvents]="tableCellClickEvents"
              [showFields]="showFields"
              [hasBiocontent]="hasBiocontent"
              [state]="settings.resultTableState"
              (stateChange)="onTableStateChanged($event)"
              [showActionsTemplate]="showActions" [selectActionsTemplate]="selectActions" [totalNumberTemplate]="totalNumber"
              [columnTemplate]="column"
              (selectedRow)="selectedRow($event)" >
              <div *actions="let v=value" class="actions">
                <multi-interactions (showEmptyFields)="toggleEmptyFields(v, $event)"></multi-interactions>
              </div>
            </app-log-table>
          </ng-template>
        </tabs>
      </ng-container>
    </ng-container>
  </section>

  <div class="basket-place">
       <app-product-log-details  class="item" 
       [showDetails]="showDetails"
       [enteredValue]="enteredValue"
       [fields]="fields"></app-product-log-details> 
  </div>

  <ng-template #showActions let-v="state">
    <multi-interactions (showEmptyFields)="toggleEmptyFields(v, $event)"></multi-interactions>
  </ng-template>

  <ng-template #selectActions let-selected="selected">
    <button class="action-to-basket" [disabled]="!selected?.size" data-ui="small" (click)="addToBasket(selected)">Add to comparision</button>
    <button data-ui="small" (click)="addNewProduct()">Add new product</button>
  </ng-template>

  <ng-template #totalNumber let-total="total">
    <div class="total" *ngIf="total">{{ totalProducts }} Products found</div>
  </ng-template>

  <ng-template #column let-value let-column="column" let-row="row">
    <app-product-property [value]="value"></app-product-property>
  </ng-template>
  </div>
</div>
