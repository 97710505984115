import { Injectable } from '@angular/core';
import { UserSettingsService } from './../../services/user-settings.service';

@Injectable({providedIn: 'root'})
export class VariantFeatureMapperService {

  private static featureMapping = {
    default: [
    ]
  };

  constructor(
    private userSettingsService: UserSettingsService,
  ) {}

  getFeaturesBasedOnVariant(): Array<string> {
    return VariantFeatureMapperService.featureMapping[this.userSettingsService.getVariant()];
  }

}
