
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "MinimalProduct": [
      "Coreactant",
      "Dispersion",
      "EnergyCurableSolutions",
      "Isocyanate",
      "NonreactiveResin",
      "OtherReactiveSystems"
    ],
    "Product": [
      "Dispersion",
      "Coreactant",
      "Isocyanate",
      "NonreactiveResin",
      "OtherReactiveSystems",
      "EnergyCurableSolutions"
    ]
  }
};
      export default result;
    