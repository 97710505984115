import { Directive, TemplateRef, Input } from '@angular/core';

@Directive({
  selector: '[tab-label]',
})
export class TabLabelDirective {
  constructor(public readonly template: TemplateRef<any>) { }

  @Input('tab-label')
  tabLabel: string;

  @Input('disabled')
  disabled: boolean;
}
