import { Injectable } from "@angular/core";
import { VariantFeatureMapperService } from "./variant-feature-mapper.service";

@Injectable({providedIn: 'root'})
export class FeatureToggleService {
  private activeFeatures = new Set<string>();

  constructor(
    private variantFeatureMapper: VariantFeatureMapperService,
  ) {}

  activateFeature(feature: string): void {
    this.activeFeatures.add(feature);
  }

  deactivateFeature(feature: string): void {
    this.activeFeatures.delete(feature);
  }

  isFeatureActive = (feature: string) => this.activeFeatures.has(feature)
    || this.variantFeatureMapper.getFeaturesBasedOnVariant().includes(feature);

  setActiveFeatures(features: Array<string>): void {
    this.activeFeatures = new Set(features);
  }
}
