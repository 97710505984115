import { Directive, HostListener } from '@angular/core';

@Directive({ selector: 'button' })
export class ButtonBlurDirective {
  constructor() { }

    @HostListener('mouseup', ['$event'])
    afterClick($event: Event){
      ($event.currentTarget as HTMLButtonElement).blur();
    }
}
