import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { ProductsService } from 'src/app/api/products.service';

@Component({
  selector: 'app-request-modify',
  templateUrl: './request-modify.html',
})

export class RequestModifyComponent implements OnInit {
constructor(private productsService: ProductsService){

  }
  values: FormGroup = new FormGroup({});
  inputChange = new Subject();

  @Input()
  value: any;

  @Input()
  label: string;

  @Output()
  newValue = new EventEmitter<any>();

  property: any;
  options:any;
  dropdown:any;
  

  async ngOnInit() {
    this.property = this.convertPropertyToInput(this.value);
    await this.productsService.fetchFilterOptions({isDeleted : {is : false}}).then(options => this.options = options);
    this.dropdown = this.options.family;
  }

  onChange(e) {
    let value = e.value;
    if (value === 'on' && e.checked != null) {
      value = e.checked ? 'not applicable' : null;
    }
    if (value === '') {
      value = null;
    }
    this.inputChange.next(value);
    this.newValue.emit(value)
  }

  private convertPropertyToInput(value: any) {
    return {
      type: this.convertType(value),
      name: this.value.propertyName,
      value: this.getPropertyValue(value),
      label: this.label
      }
  }

  private convertType(value: any) {
    if (value.numericValue.value != null || value.numericValue.isNotApplicable) {
      return 'number'
    } else if (value.booleanValue != null) {
      return 'boolean'
    } else if (value.propertyName == 'family'){   
      return 'array'
    } else {
      return 'string'
    }
  }

  private getPropertyValue(value: any) {
    if (value.numericValue.value != null || value.numericValue.isNotApplicable) {
      return {
        value: value.numericValue.value,
        isNotApplicable: value.numericValue.isNotApplicable
      }
    } else {
      return value.textValue
    }
  }
}
