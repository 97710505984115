import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'signing-in',
  template: '<section data-ui="primary">Signing in...</section>',
  styles: [`
    section {
      text-align: center;
      font-weight: bold;
    }
  `]
})
export class SigningInComponent implements OnInit {

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    sessionStorage.setItem('returnUrl', this.route.snapshot.queryParamMap.get('returnUrl'));
  }
}
