<div class="selection" (clickOutside)="close($event)">
  <button [attr.data-ui]="dataUi" (click)="updateArray()" [className]="showSelection ? 'open' : 'closed'" [disabled]="showSelection">
    {{ label }}
    <ng-container *ngIf="selected.length > 0">({{selected.length}})</ng-container>
  </button>
 
  <section [attr.data-ui]="dataUi" class="options" *ngIf="showSelection">
    <div >
      <label *ngFor="let option of data" class="resetted option" >
        <div>
          <input type="checkbox" [ngModel]="option.show" (ngModelChange)="toogleOption(option)"> {{ fields ? fields[option.option] : option.option }}
        </div>
        
      </label>
    </div>
    </section>
</div>
