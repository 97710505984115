import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'single-sign-on',
  template: ``
})
export class SingleSignOnComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    let returnUrl = sessionStorage.getItem('returnUrl');
    sessionStorage.removeItem('returnUrl');
    if (returnUrl) {
      if (returnUrl.includes('?')) {
        const navigationExtras: NavigationExtras = {
          queryParams: Object.fromEntries(new URLSearchParams(encodeURI(returnUrl.substr(returnUrl.indexOf('?') + 1))) as any)
        };

        this.router.navigate([returnUrl.split('?')[0]], navigationExtras);
      } else {
        this.router.navigate([returnUrl]);
      }
    } else {
      this.router.navigate(['/']);
    }
  }
}
