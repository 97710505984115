import { NumericProperty, Product } from '../../generated/graphql';

export type PrintableProduct = Record<keyof Product, number | string | boolean | null | undefined>;

export function mapProductToPrintable(product: Product): PrintableProduct {
  return Object.fromEntries(
    Object.entries(product)
      .map(([key, value]) => {
        return [key, mapProductPropertyToPrintable(value)];
      }) as Array<[keyof Product, number | string | boolean | null | undefined]>
  ) as PrintableProduct;
}

export function mapProductPropertyToPrintable(property: NumericProperty | string | boolean): number | string | boolean | null | undefined {
  if (property && typeof property == 'object' && property.__typename) {
    if (property.isNotApplicable === true) {
      return 'not applicable';
    }
    if (property.value !== undefined) {
      return property.value;
    }
  }
  return property as string | boolean;
}
