import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureToggleService } from './feature-toggle.service';

@Directive({
  selector: '[feature]',
})
export class FeatureToggleDirective implements OnInit {

  @Input()
  feature: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureToogleService: FeatureToggleService
  ) {}

  ngOnInit(): void {
    if (this.featureToogleService.isFeatureActive(this.feature)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
