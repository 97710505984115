import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CognitoSingleSignOnService } from './cognito-singlesignon.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authenticationService: CognitoSingleSignOnService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!environment.production && environment.auth.apiKey) {
      const apiKey = environment.auth.apiKey;

      return apiKey ?
        of(apiKey)
          .pipe(
            map((apiKey: string) => request.clone({ setHeaders: { 'x-api-key': `${apiKey}` } })),
            mergeMap((httpRequest: HttpRequest<any>) => next.handle(httpRequest)))
        : next.handle(request);
    }

    const token = this.authenticationService.getAccessToken();
    return token
      .pipe(
        map((jwtToken: string) =>
          request.clone({
            setHeaders: {
              Authorization: `${jwtToken}`,
            },
          })
        ),
        mergeMap((httpRequest: HttpRequest<any>) => next.handle(httpRequest)));
  }
}
