/* THIS FILE IS AUTOMATICALLY GENERATED - DO NOT EDIT! */
/* eslint-disable */
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
}



export interface BooleanFilterInput {
  is?: Maybe<Scalars['Boolean']>;
  isNot?: Maybe<Scalars['Boolean']>;
}

/** Coreactant product family */
export interface Coreactant extends MinimalProduct {
  __typename?: 'Coreactant';
  /** Base Type */
  family: Scalars['String'];
  /** Supplier */
  supplier: Scalars['String'];
  /** Product Name */
  name: Scalars['String'];
  /** Type */
  type: Scalars['String'];
  /** Product Sub-Type */
  subType?: Maybe<Scalars['String']>;
  /** Archived */
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Primary Application */
  primaryApplication?: Maybe<Scalars['String']>;
  /** Equivalent Weight */
  equivalentWeight?: Maybe<NumericProperty>;
  /** Functionality */
  functionality?: Maybe<NumericProperty>;
  /** Max Gardner Color */
  maxGardnerColor?: Maybe<NumericProperty>;
  /** Max Hazen APHA PtCo Color */
  maxHazenAPHAPtCoColor?: Maybe<NumericProperty>;
  /** Max Iodine Color */
  maxIodineColor?: Maybe<NumericProperty>;
  /** Viscosity mPaS (midpoint) */
  viscosityMPaS?: Maybe<NumericProperty>;
  /** Viscosity Range mPaS (±) */
  viscosityRange?: Maybe<NumericProperty>;
  /** Viscosity Temp °C */
  viscosityTemperature?: Maybe<NumericProperty>;
  /** Viscosity Method */
  viscosityMethod?: Maybe<Scalars['String']>;
  /** % Solids */
  solids?: Maybe<NumericProperty>;
  /** Solvent */
  solvent?: Maybe<Scalars['String']>;
  /** %OH */
  OH?: Maybe<NumericProperty>;
  /** Acid Number */
  acid?: Maybe<NumericProperty>;
  /** OH Number */
  OHNumber?: Maybe<NumericProperty>;
  /** % COOH */
  COOH?: Maybe<NumericProperty>;
  /** Previous Name */
  previousName?: Maybe<Scalars['String']>;
  /** Amine Number */
  amineNumber?: Maybe<NumericProperty>;
  /** MFFT °C */
  MFFT?: Maybe<NumericProperty>;
  /** Softening Point °C */
  softeningPoint?: Maybe<NumericProperty>;
  /** Monomer Basis */
  monomerBasis?: Maybe<Scalars['String']>;
  /** Tg °C */
  tg?: Maybe<NumericProperty>;
  /** Tg2 °C */
  tg2?: Maybe<NumericProperty>;
  /** PAE Composition */
  PAEComposition?: Maybe<Scalars['String']>;
  /** Alternative Feedstock Type */
  feedstockType?: Maybe<Scalars['String']>;
  /** PCF in kg CO2/kg Product */
  carbonFootprint?: Maybe<NumericProperty>;
  /** Year */
  year?: Maybe<Scalars['String']>;
  /** Including Biogenic Carbon */
  includingBioCarbon?: Maybe<Scalars['Boolean']>;
  /** Excluding Biogenic Carbon */
  excludingBioCarbon?: Maybe<Scalars['Boolean']>;
  /** % Alternative Feedstock Content */
  biocontent?: Maybe<NumericProperty>;
  /** % Referring To Carbon Only */
  carbon?: Maybe<Scalars['Boolean']>;
  /** % Referring To Total Mass */
  mass?: Maybe<Scalars['Boolean']>;
  /** Chain of custody model */
  biocontentMethod?: Maybe<Scalars['String']>;
  /** Biodegradability */
  biodegradability?: Maybe<Scalars['String']>;
}

/** Dispersion product family */
export interface Dispersion extends MinimalProduct {
  __typename?: 'Dispersion';
  /** Base Type */
  family: Scalars['String'];
  /** Supplier */
  supplier: Scalars['String'];
  /** Product Name */
  name: Scalars['String'];
  /** Type */
  type: Scalars['String'];
  /** Product Sub-Type */
  subType?: Maybe<Scalars['String']>;
  /** Archived */
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Primary Application */
  primaryApplication?: Maybe<Scalars['String']>;
  /** % Solids */
  solids?: Maybe<NumericProperty>;
  /** Solvent */
  solvent?: Maybe<Scalars['String']>;
  /** MFFT °C */
  MFFT?: Maybe<NumericProperty>;
  /** Activation Temperature  °C */
  activationTemperature?: Maybe<NumericProperty>;
  /** Crystallization */
  crystallization?: Maybe<Scalars['String']>;
  /** pH */
  pH?: Maybe<NumericProperty>;
  /** PES/PC/PET */
  dispersionBasis?: Maybe<Scalars['String']>;
  /** OH Number */
  OHNumber?: Maybe<NumericProperty>;
  /** Acid Number */
  acid?: Maybe<NumericProperty>;
  /** Tg °C */
  tg?: Maybe<NumericProperty>;
  /** Tg2 °C */
  tg2?: Maybe<NumericProperty>;
  /** Typical Dispersion Viscosity */
  typicalViscosityMPaS?: Maybe<NumericProperty>;
  /** Viscosity mPas (midpoint) */
  viscosityMPaS?: Maybe<NumericProperty>;
  /** Viscosity Method */
  viscosityMethod?: Maybe<Scalars['String']>;
  /** Viscosity Range */
  viscosityRange?: Maybe<NumericProperty>;
  /** Equivalent Weight */
  equivalentWeight?: Maybe<NumericProperty>;
  /** Maximum % Monomer */
  maximumMonomer?: Maybe<NumericProperty>;
  /** Functionality */
  functionality?: Maybe<NumericProperty>;
  /** Max Gardner Color */
  maxGardnerColor?: Maybe<NumericProperty>;
  /** Max Hazen APHA PtCo Color */
  maxHazenAPHAPtCoColor?: Maybe<NumericProperty>;
  /** Max Iodine Color */
  maxIodineColor?: Maybe<NumericProperty>;
  /** Mw by GPC */
  MwByGPC?: Maybe<NumericProperty>;
  /** PDI by GPC */
  PDIByGPC?: Maybe<NumericProperty>;
  /** Particle Size */
  particleSize?: Maybe<NumericProperty>;
  /** 100% Modulus Mpa */
  modulusMpa?: Maybe<NumericProperty>;
  /** Tensile Strength Mpa */
  tensileStrengthMpa?: Maybe<NumericProperty>;
  /** Elongation at Break */
  elongationAtBreak?: Maybe<NumericProperty>;
  /** Anionic/Cationic/Nonionic */
  anionicCationicNonionicDispersion?: Maybe<Scalars['String']>;
  /** Deblock Temperature  °C */
  deblockTemperature?: Maybe<NumericProperty>;
  /** Blocking Agent */
  blockingAgent?: Maybe<Scalars['String']>;
  /** Melting Temp °C */
  meltingTemp?: Maybe<NumericProperty>;
  /** % COOH */
  COOH?: Maybe<NumericProperty>;
  /** %OH */
  OH?: Maybe<NumericProperty>;
  /** Softening Point °C */
  softeningPoint?: Maybe<NumericProperty>;
  /** Previous Name */
  previousName?: Maybe<Scalars['String']>;
  /** Monomer Basis */
  monomerBasis?: Maybe<Scalars['String']>;
  /** Alternative Feedstock Type */
  feedstockType?: Maybe<Scalars['String']>;
  /** PCF in kg CO2/kg Product */
  carbonFootprint?: Maybe<NumericProperty>;
  /** Year */
  year?: Maybe<Scalars['String']>;
  /** Including Biogenic Carbon */
  includingBioCarbon?: Maybe<Scalars['Boolean']>;
  /** Excluding Biogenic Carbon */
  excludingBioCarbon?: Maybe<Scalars['Boolean']>;
  /** % Alternative Feedstock Content */
  biocontent?: Maybe<NumericProperty>;
  /** % Referring To Carbon Only */
  carbon?: Maybe<Scalars['Boolean']>;
  /** % Referring To Total Mass */
  mass?: Maybe<Scalars['Boolean']>;
  /** Chain of custody model */
  biocontentMethod?: Maybe<Scalars['String']>;
  /** Biodegradability */
  biodegradability?: Maybe<Scalars['String']>;
}

/** Energy Curable Solutions product family */
export interface EnergyCurableSolutions extends MinimalProduct {
  __typename?: 'EnergyCurableSolutions';
  /** Base Type */
  family: Scalars['String'];
  /** Supplier */
  supplier: Scalars['String'];
  /** Product Name */
  name: Scalars['String'];
  /** Type */
  type: Scalars['String'];
  /** Product Sub-Type */
  subType?: Maybe<Scalars['String']>;
  /** Archived */
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Primary Application */
  primaryApplication?: Maybe<Scalars['String']>;
  /** Functionality */
  functionality?: Maybe<NumericProperty>;
  /** Diluent */
  diluent?: Maybe<Scalars['String']>;
  /** Viscosity mPas (midpoint) */
  viscosityMPaS?: Maybe<NumericProperty>;
  /** Max Gardner Color */
  maxGardnerColor?: Maybe<NumericProperty>;
  /** Max Hazen APHA PtCo Color */
  maxHazenAPHAPtCoColor?: Maybe<NumericProperty>;
  /** Acid Number */
  acid?: Maybe<NumericProperty>;
  /** Mw by GPC */
  MwByGPC?: Maybe<NumericProperty>;
  /** Tg °C */
  tg?: Maybe<NumericProperty>;
  /** Density */
  density?: Maybe<NumericProperty>;
  /** Alternative Feedstock Type */
  feedstockType?: Maybe<Scalars['String']>;
  /** PCF in kg CO2/kg Product */
  carbonFootprint?: Maybe<NumericProperty>;
  /** Year */
  year?: Maybe<Scalars['String']>;
  /** Including Biogenic Carbon */
  includingBioCarbon?: Maybe<Scalars['Boolean']>;
  /** Excluding Biogenic Carbon */
  excludingBioCarbon?: Maybe<Scalars['Boolean']>;
  /** % Alternative Feedstock Content */
  biocontent?: Maybe<NumericProperty>;
  /** % Referring To Carbon Only */
  carbon?: Maybe<Scalars['Boolean']>;
  /** % Referring To Total Mass */
  mass?: Maybe<Scalars['Boolean']>;
}

/** Entered Value Logs Data */
export interface EnteredValueLogsData {
  __typename?: 'EnteredValueLogsData';
  propertyName?: Maybe<Scalars['String']>;
  numericValue?: Maybe<NumericProperty>;
  textValue?: Maybe<Scalars['String']>;
  booleanValue?: Maybe<Scalars['Boolean']>;
}

export interface FloatBounds {
  __typename?: 'FloatBounds';
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
}

export interface FloatFilterInput {
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
}

/** Isocyanate product family */
export interface Isocyanate extends MinimalProduct {
  __typename?: 'Isocyanate';
  /** Base Type */
  family: Scalars['String'];
  /** Supplier */
  supplier: Scalars['String'];
  /** Product Name */
  name: Scalars['String'];
  /** Type */
  type: Scalars['String'];
  /** Product Sub-Type */
  subType?: Maybe<Scalars['String']>;
  /** Archived */
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Primary Application */
  primaryApplication?: Maybe<Scalars['String']>;
  /** Equivalent Weight */
  equivalentWeight?: Maybe<NumericProperty>;
  /** Functionality */
  functionality?: Maybe<NumericProperty>;
  /** Monomer Basis */
  monomerBasis?: Maybe<Scalars['String']>;
  /** Max Gardner Color */
  maxGardnerColor?: Maybe<NumericProperty>;
  /** Max Hazen APHA PtCo Color */
  maxHazenAPHAPtCoColor?: Maybe<NumericProperty>;
  /** Max Iodine Color */
  maxIodineColor?: Maybe<NumericProperty>;
  /** Maximum % Monomer */
  maximumMonomer?: Maybe<NumericProperty>;
  /** Viscosity mPas (midpoint) */
  viscosityMPaS?: Maybe<NumericProperty>;
  /** Viscosity Range mPas (±) */
  viscosityRange?: Maybe<NumericProperty>;
  /** Viscosity Temp °C */
  viscosityTemperature?: Maybe<NumericProperty>;
  /** % NCO (midpoint) */
  NCO?: Maybe<NumericProperty>;
  /** NCO Range (±) */
  NCORange?: Maybe<NumericProperty>;
  /** % Solids */
  solids?: Maybe<NumericProperty>;
  /** Solvent */
  solvent?: Maybe<Scalars['String']>;
  /** Blocking Agent */
  blockingAgent?: Maybe<Scalars['String']>;
  /** Deblock Temperature  °C */
  deblockTemperature?: Maybe<NumericProperty>;
  /** Previous Name */
  previousName?: Maybe<Scalars['String']>;
  /** Alternative Feedstock Type */
  feedstockType?: Maybe<Scalars['String']>;
  /** PCF in kg CO2/kg Product */
  carbonFootprint?: Maybe<NumericProperty>;
  /** Year */
  year?: Maybe<Scalars['String']>;
  /** Including Biogenic Carbon */
  includingBioCarbon?: Maybe<Scalars['Boolean']>;
  /** Excluding Biogenic Carbon */
  excludingBioCarbon?: Maybe<Scalars['Boolean']>;
  /** % Alternative Feedstock Content */
  biocontent?: Maybe<NumericProperty>;
  /** % Referring To Carbon Only */
  carbon?: Maybe<Scalars['Boolean']>;
  /** % Referring To Total Mass */
  mass?: Maybe<Scalars['Boolean']>;
  /** Chain of custody model */
  biocontentMethod?: Maybe<Scalars['String']>;
  /** Biodegradability */
  biodegradability?: Maybe<Scalars['String']>;
}

/** Minimal Product Interface */
export interface MinimalProduct {
  /** Base Type */
  family: Scalars['String'];
  /** Supplier */
  supplier: Scalars['String'];
  /** Product Name */
  name: Scalars['String'];
  /** Product Type */
  type: Scalars['String'];
  /** Product Subtype */
  subType?: Maybe<Scalars['String']>;
  /** Archived */
  isDeleted?: Maybe<Scalars['Boolean']>;
}

/** Modified Change Request Input */
export interface ModifiedChangeRequestInput {
  changeId: Scalars['Int'];
  newNumericValue?: Maybe<NumericPropertyInput>;
  newTextualValue?: Maybe<Scalars['String']>;
  booleanValue?: Maybe<Scalars['Boolean']>;
}

export interface Mutation {
  __typename?: 'Mutation';
  createProductsChangeRequest?: Maybe<Scalars['Boolean']>;
  responseProductsChangeRequest?: Maybe<Array<Maybe<Scalars['String']>>>;
  deleteProduct?: Maybe<Scalars['Boolean']>;
}


export interface MutationcreateProductsChangeRequestArgs {
  products: Array<ProductInput>;
}


export interface MutationresponseProductsChangeRequestArgs {
  accepted?: Maybe<Array<Maybe<Scalars['Int']>>>;
  rejected?: Maybe<Array<Maybe<Scalars['Int']>>>;
  modified?: Maybe<Array<Maybe<ModifiedChangeRequestInput>>>;
}


export interface MutationdeleteProductArgs {
  product?: Maybe<Scalars['String']>;
}

/** Nonreactive Resin product family */
export interface NonreactiveResin extends MinimalProduct {
  __typename?: 'NonreactiveResin';
  /** Base Type */
  family: Scalars['String'];
  /** Supplier */
  supplier: Scalars['String'];
  /** Product Name */
  name: Scalars['String'];
  /** Type */
  type: Scalars['String'];
  /** Product Sub-Type */
  subType?: Maybe<Scalars['String']>;
  /** Archived */
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Primary Application */
  primaryApplication?: Maybe<Scalars['String']>;
  /** Functionality */
  functionality?: Maybe<NumericProperty>;
  /** Max Gardner Color */
  maxGardnerColor?: Maybe<NumericProperty>;
  /** Max Hazen APHA PtCo Color */
  maxHazenAPHAPtCoColor?: Maybe<NumericProperty>;
  /** Viscosity mPas (midpoint) */
  viscosityMPaS?: Maybe<NumericProperty>;
  /** Viscosity Range mPas (±) */
  viscosityRange?: Maybe<NumericProperty>;
  /** Viscosity Method */
  viscosityMethod?: Maybe<Scalars['String']>;
  /** Typical Viscosity mPas */
  typicalViscosityMPaS?: Maybe<NumericProperty>;
  /** % Solids */
  solids?: Maybe<NumericProperty>;
  /** Solvent */
  solvent?: Maybe<Scalars['String']>;
  /** Previous Name */
  previousName?: Maybe<Scalars['String']>;
  /** Percent other Reactive Group */
  otherReactiveGroupPercentage?: Maybe<NumericProperty>;
  /** Maximum % Monomer */
  maximumMonomer?: Maybe<NumericProperty>;
  /** Alternative Feedstock Type */
  feedstockType?: Maybe<Scalars['String']>;
  /** PCF in kg CO2/kg Product */
  carbonFootprint?: Maybe<NumericProperty>;
  /** Year */
  year?: Maybe<Scalars['String']>;
  /** Including Biogenic Carbon */
  includingBioCarbon?: Maybe<Scalars['Boolean']>;
  /** Excluding Biogenic Carbon */
  excludingBioCarbon?: Maybe<Scalars['Boolean']>;
  /** % Alternative Feedstock Content */
  biocontent?: Maybe<NumericProperty>;
  /** % Referring To Carbon Only */
  carbon?: Maybe<Scalars['Boolean']>;
  /** % Referring To Total Mass */
  mass?: Maybe<Scalars['Boolean']>;
  /** Chain of custody model */
  biocontentMethod?: Maybe<Scalars['String']>;
  /** Biodegradability */
  biodegradability?: Maybe<Scalars['String']>;
}

export interface NumericProperty {
  __typename?: 'NumericProperty';
  value?: Maybe<Scalars['Float']>;
  isNotApplicable?: Maybe<Scalars['Boolean']>;
}

export interface NumericPropertyInput {
  value?: Maybe<Scalars['Float']>;
  isNotApplicable?: Maybe<Scalars['Boolean']>;
}

/** Other Reactive Systems product family */
export interface OtherReactiveSystems extends MinimalProduct {
  __typename?: 'OtherReactiveSystems';
  /** Base Type */
  family: Scalars['String'];
  /** Supplier */
  supplier: Scalars['String'];
  /** Product Name */
  name: Scalars['String'];
  /** Type */
  type: Scalars['String'];
  /** Product Sub-Type */
  subType?: Maybe<Scalars['String']>;
  /** Archived */
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Primary Application */
  primaryApplication?: Maybe<Scalars['String']>;
  /** Functionality */
  functionality?: Maybe<NumericProperty>;
  /** Max Gardner Color */
  maxGardnerColor?: Maybe<NumericProperty>;
  /** Max Hazen APHA PtCo Color */
  maxHazenAPHAPtCoColor?: Maybe<NumericProperty>;
  /** Viscosity mPas (midpoint) */
  viscosityMPaS?: Maybe<NumericProperty>;
  /** Viscosity Range mPas(±) */
  viscosityRange?: Maybe<NumericProperty>;
  /** Viscosity Method */
  viscosityMethod?: Maybe<Scalars['String']>;
  /** Typical Viscosity mPas */
  typicalViscosityMPaS?: Maybe<NumericProperty>;
  /** % Solids */
  solids?: Maybe<NumericProperty>;
  /** Solvent */
  solvent?: Maybe<Scalars['String']>;
  /** Previous Name */
  previousName?: Maybe<Scalars['String']>;
  /** Percent other Reactive Group */
  otherReactiveGroupPercentage?: Maybe<NumericProperty>;
  /** Maximum % Monomer */
  maximumMonomer?: Maybe<NumericProperty>;
  /** Alternative Feedstock Type */
  feedstockType?: Maybe<Scalars['String']>;
  /** PCF in kg CO2/kg Product */
  carbonFootprint?: Maybe<NumericProperty>;
  /** Year */
  year?: Maybe<Scalars['String']>;
  /** Including Biogenic Carbon */
  includingBioCarbon?: Maybe<Scalars['Boolean']>;
  /** Excluding Biogenic Carbon */
  excludingBioCarbon?: Maybe<Scalars['Boolean']>;
  /** % Alternative Feedstock Content */
  biocontent?: Maybe<NumericProperty>;
  /** % Referring To Carbon Only */
  carbon?: Maybe<Scalars['Boolean']>;
  /** % Referring To Total Mass */
  mass?: Maybe<Scalars['Boolean']>;
  /** Chain of custody model */
  biocontentMethod?: Maybe<Scalars['String']>;
  /** Biodegradability */
  biodegradability?: Maybe<Scalars['String']>;
}

/** Possible Product Filters */
export interface PossibleProductFilters {
  __typename?: 'PossibleProductFilters';
  _total: Scalars['Int'];
  family?: Maybe<Array<Maybe<Scalars['String']>>>;
  supplier?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  subType?: Maybe<Array<Maybe<Scalars['String']>>>;
  isDeleted?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  primaryApplication?: Maybe<Array<Maybe<Scalars['String']>>>;
  equivalentWeight?: Maybe<FloatBounds>;
  functionality?: Maybe<FloatBounds>;
  monomerBasis?: Maybe<Array<Maybe<Scalars['String']>>>;
  maxGardnerColor?: Maybe<FloatBounds>;
  maxHazenAPHAPtCoColor?: Maybe<FloatBounds>;
  maxIodineColor?: Maybe<FloatBounds>;
  maximumMonomer?: Maybe<FloatBounds>;
  viscosityMPaS?: Maybe<FloatBounds>;
  viscosityMethod?: Maybe<Array<Maybe<Scalars['String']>>>;
  viscosityRange?: Maybe<FloatBounds>;
  viscosityTemperature?: Maybe<FloatBounds>;
  typicalViscosityMPaS?: Maybe<FloatBounds>;
  NCO?: Maybe<FloatBounds>;
  NCORange?: Maybe<FloatBounds>;
  solids?: Maybe<FloatBounds>;
  solvent?: Maybe<Array<Maybe<Scalars['String']>>>;
  blockingAgent?: Maybe<Array<Maybe<Scalars['String']>>>;
  deblockTemperature?: Maybe<FloatBounds>;
  MFFT?: Maybe<FloatBounds>;
  activationTemperature?: Maybe<FloatBounds>;
  crystallization?: Maybe<Array<Maybe<Scalars['String']>>>;
  pH?: Maybe<FloatBounds>;
  dispersionBasis?: Maybe<Array<Maybe<Scalars['String']>>>;
  OHNumber?: Maybe<FloatBounds>;
  acid?: Maybe<FloatBounds>;
  tg?: Maybe<FloatBounds>;
  tg2?: Maybe<FloatBounds>;
  MwByGPC?: Maybe<FloatBounds>;
  PDIByGPC?: Maybe<FloatBounds>;
  particleSize?: Maybe<FloatBounds>;
  modulusMpa?: Maybe<FloatBounds>;
  tensileStrengthMpa?: Maybe<FloatBounds>;
  elongationAtBreak?: Maybe<FloatBounds>;
  anionicCationicNonionicDispersion?: Maybe<Array<Maybe<Scalars['String']>>>;
  meltingTemp?: Maybe<FloatBounds>;
  COOH?: Maybe<FloatBounds>;
  OH?: Maybe<FloatBounds>;
  softeningPoint?: Maybe<FloatBounds>;
  previousName?: Maybe<Array<Maybe<Scalars['String']>>>;
  amineNumber?: Maybe<FloatBounds>;
  PAEComposition?: Maybe<Array<Maybe<Scalars['String']>>>;
  carbonFootprint?: Maybe<FloatBounds>;
  year?: Maybe<Array<Maybe<Scalars['String']>>>;
  includingBioCarbon?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  excludingBioCarbon?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  biocontent?: Maybe<FloatBounds>;
  carbon?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  mass?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  biocontentMethod?: Maybe<Array<Maybe<Scalars['String']>>>;
  biodegradability?: Maybe<Array<Maybe<Scalars['String']>>>;
  otherReactiveGroupPercentage?: Maybe<FloatBounds>;
  diluent?: Maybe<Array<Maybe<Scalars['String']>>>;
  density?: Maybe<FloatBounds>;
  feedstockType?: Maybe<Array<Maybe<Scalars['String']>>>;
}

/** Product Type */
export type Product = Dispersion | Coreactant | Isocyanate | NonreactiveResin | OtherReactiveSystems | EnergyCurableSolutions;

/** Product Filter Input */
export interface ProductFilterInput {
  family?: Maybe<StringFilter>;
  supplier?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  type?: Maybe<StringFilter>;
  subType?: Maybe<StringFilter>;
  isDeleted?: Maybe<BooleanFilterInput>;
  primaryApplication?: Maybe<StringFilter>;
  equivalentWeight?: Maybe<FloatFilterInput>;
  functionality?: Maybe<FloatFilterInput>;
  monomerBasis?: Maybe<StringFilter>;
  maxGardnerColor?: Maybe<FloatFilterInput>;
  maxHazenAPHAPtCoColor?: Maybe<FloatFilterInput>;
  maxIodineColor?: Maybe<FloatFilterInput>;
  maximumMonomer?: Maybe<FloatFilterInput>;
  viscosityMPaS?: Maybe<FloatFilterInput>;
  viscosityMethod?: Maybe<StringFilter>;
  viscosityRange?: Maybe<FloatFilterInput>;
  viscosityTemperature?: Maybe<FloatFilterInput>;
  typicalViscosityMPaS?: Maybe<FloatFilterInput>;
  NCO?: Maybe<FloatFilterInput>;
  NCORange?: Maybe<FloatFilterInput>;
  solids?: Maybe<FloatFilterInput>;
  solvent?: Maybe<StringFilter>;
  blockingAgent?: Maybe<StringFilter>;
  deblockTemperature?: Maybe<FloatFilterInput>;
  MFFT?: Maybe<FloatFilterInput>;
  activationTemperature?: Maybe<FloatFilterInput>;
  crystallization?: Maybe<StringFilter>;
  pH?: Maybe<FloatFilterInput>;
  dispersionBasis?: Maybe<StringFilter>;
  OHNumber?: Maybe<FloatFilterInput>;
  acid?: Maybe<FloatFilterInput>;
  tg?: Maybe<FloatFilterInput>;
  tg2?: Maybe<FloatFilterInput>;
  MwByGPC?: Maybe<FloatFilterInput>;
  PDIByGPC?: Maybe<FloatFilterInput>;
  particleSize?: Maybe<FloatFilterInput>;
  modulusMpa?: Maybe<FloatFilterInput>;
  tensileStrengthMpa?: Maybe<FloatFilterInput>;
  elongationAtBreak?: Maybe<FloatFilterInput>;
  anionicCationicNonionicDispersion?: Maybe<StringFilter>;
  meltingTemp?: Maybe<FloatFilterInput>;
  COOH?: Maybe<FloatFilterInput>;
  OH?: Maybe<FloatFilterInput>;
  softeningPoint?: Maybe<FloatFilterInput>;
  previousName?: Maybe<StringFilter>;
  amineNumber?: Maybe<FloatFilterInput>;
  PAEComposition?: Maybe<StringFilter>;
  carbonFootprint?: Maybe<FloatFilterInput>;
  year?: Maybe<StringFilter>;
  includingBioCarbon?: Maybe<BooleanFilterInput>;
  excludingBioCarbon?: Maybe<BooleanFilterInput>;
  biocontent?: Maybe<FloatFilterInput>;
  carbon?: Maybe<BooleanFilterInput>;
  mass?: Maybe<BooleanFilterInput>;
  biocontentMethod?: Maybe<StringFilter>;
  biodegradability?: Maybe<StringFilter>;
  otherReactiveGroupPercentage?: Maybe<FloatFilterInput>;
  diluent?: Maybe<StringFilter>;
  density?: Maybe<FloatFilterInput>;
  feedstockType?: Maybe<StringFilter>;
}

/** Product History Logs Data */
export interface ProductHistoryLogsData {
  __typename?: 'ProductHistoryLogsData';
  productName?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  enteredValue?: Maybe<Array<Maybe<EnteredValueLogsData>>>;
}

/** Product History Logs Data With Total */
export interface ProductHistoryLogsDataWithTotal {
  __typename?: 'ProductHistoryLogsDataWithTotal';
  totalRequest: Scalars['Int'];
  logsData?: Maybe<Array<Maybe<ProductHistoryLogsData>>>;
}

/** Product Input */
export interface ProductInput {
  family: Scalars['String'];
  supplier: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  subType?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  primaryApplication?: Maybe<Scalars['String']>;
  equivalentWeight?: Maybe<NumericPropertyInput>;
  functionality?: Maybe<NumericPropertyInput>;
  monomerBasis?: Maybe<Scalars['String']>;
  maxGardnerColor?: Maybe<NumericPropertyInput>;
  maxHazenAPHAPtCoColor?: Maybe<NumericPropertyInput>;
  maxIodineColor?: Maybe<NumericPropertyInput>;
  maximumMonomer?: Maybe<NumericPropertyInput>;
  viscosityMPaS?: Maybe<NumericPropertyInput>;
  viscosityMethod?: Maybe<Scalars['String']>;
  viscosityRange?: Maybe<NumericPropertyInput>;
  viscosityTemperature?: Maybe<NumericPropertyInput>;
  typicalViscosityMPaS?: Maybe<NumericPropertyInput>;
  NCO?: Maybe<NumericPropertyInput>;
  NCORange?: Maybe<NumericPropertyInput>;
  solids?: Maybe<NumericPropertyInput>;
  solvent?: Maybe<Scalars['String']>;
  blockingAgent?: Maybe<Scalars['String']>;
  deblockTemperature?: Maybe<NumericPropertyInput>;
  MFFT?: Maybe<NumericPropertyInput>;
  activationTemperature?: Maybe<NumericPropertyInput>;
  crystallization?: Maybe<Scalars['String']>;
  biocontent?: Maybe<NumericPropertyInput>;
  pH?: Maybe<NumericPropertyInput>;
  dispersionBasis?: Maybe<Scalars['String']>;
  OHNumber?: Maybe<NumericPropertyInput>;
  acid?: Maybe<NumericPropertyInput>;
  tg?: Maybe<NumericPropertyInput>;
  tg2?: Maybe<NumericPropertyInput>;
  MwByGPC?: Maybe<NumericPropertyInput>;
  PDIByGPC?: Maybe<NumericPropertyInput>;
  particleSize?: Maybe<NumericPropertyInput>;
  modulusMpa?: Maybe<NumericPropertyInput>;
  tensileStrengthMpa?: Maybe<NumericPropertyInput>;
  elongationAtBreak?: Maybe<NumericPropertyInput>;
  anionicCationicNonionicDispersion?: Maybe<Scalars['String']>;
  meltingTemp?: Maybe<NumericPropertyInput>;
  COOH?: Maybe<NumericPropertyInput>;
  OH?: Maybe<NumericPropertyInput>;
  softeningPoint?: Maybe<NumericPropertyInput>;
  previousName?: Maybe<Scalars['String']>;
  amineNumber?: Maybe<NumericPropertyInput>;
  PAEComposition?: Maybe<Scalars['String']>;
  carbonFootprint?: Maybe<NumericPropertyInput>;
  year?: Maybe<Scalars['String']>;
  includingBioCarbon?: Maybe<Scalars['Boolean']>;
  excludingBioCarbon?: Maybe<Scalars['Boolean']>;
  carbon?: Maybe<Scalars['Boolean']>;
  mass?: Maybe<Scalars['Boolean']>;
  biocontentMethod?: Maybe<Scalars['String']>;
  biodegradability?: Maybe<Scalars['String']>;
  otherReactiveGroupPercentage?: Maybe<NumericPropertyInput>;
  diluent?: Maybe<Scalars['String']>;
  density?: Maybe<NumericPropertyInput>;
  feedstockType?: Maybe<Scalars['String']>;
}

/** Property Change Request */
export interface PropertyChangeRequest {
  __typename?: 'PropertyChangeRequest';
  id: Scalars['Int'];
  state: Scalars['String'];
  createdBy: Scalars['String'];
  createdAt: Scalars['Int'];
  productName?: Maybe<Scalars['String']>;
  propertyName: Scalars['String'];
  numericValue?: Maybe<NumericProperty>;
  textValue?: Maybe<Scalars['String']>;
  booleanValue?: Maybe<Scalars['Boolean']>;
}

export interface Query {
  __typename?: 'Query';
  getProducts?: Maybe<Array<Maybe<Product>>>;
  getPossibleProductFilters?: Maybe<PossibleProductFilters>;
  getUser?: Maybe<User>;
  getChangeRequests?: Maybe<Array<Maybe<PropertyChangeRequest>>>;
  getProductHistoryLogs?: Maybe<ProductHistoryLogsDataWithTotal>;
}


export interface QuerygetProductsArgs {
  filter?: Maybe<ProductFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Array<Maybe<SortBy>>>;
}


export interface QuerygetPossibleProductFiltersArgs {
  filter?: Maybe<ProductFilterInput>;
}


export interface QuerygetChangeRequestsArgs {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  state?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Int']>;
  productName?: Maybe<Array<Maybe<Scalars['String']>>>;
  page?: Maybe<Scalars['Int']>;
}


export interface QuerygetProductHistoryLogsArgs {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortBy?: Maybe<Array<Maybe<SortBy>>>;
  search?: Maybe<Scalars['String']>;
}

export interface SortBy {
  property: Scalars['String'];
  direction?: Maybe<SortDirection>;
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface StringFilter {
  startsWith?: Maybe<Scalars['String']>;
  equals?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface User {
  __typename?: 'User';
  email: Scalars['String'];
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  department?: Maybe<Scalars['String']>;
}

export type ProductHistoryLogsDataQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortBy?: Maybe<Array<Maybe<SortBy>> | Maybe<SortBy>>;
  search: Scalars['String'];
}>;


export type ProductHistoryLogsDataQuery = (
  { __typename?: 'Query' }
  & { getProductHistoryLogs?: Maybe<(
    { __typename?: 'ProductHistoryLogsDataWithTotal' }
    & Pick<ProductHistoryLogsDataWithTotal, 'totalRequest'>
    & { logsData?: Maybe<Array<Maybe<(
      { __typename?: 'ProductHistoryLogsData' }
      & Pick<ProductHistoryLogsData, 'createdAt' | 'createdBy' | 'productName' | 'state'>
      & { enteredValue?: Maybe<Array<Maybe<(
        { __typename?: 'EnteredValueLogsData' }
        & Pick<EnteredValueLogsData, 'propertyName' | 'textValue' | 'booleanValue'>
        & { numericValue?: Maybe<(
          { __typename?: 'NumericProperty' }
          & Pick<NumericProperty, 'isNotApplicable' | 'value'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type getOpenChangeRequestsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
}>;


export type getOpenChangeRequestsQuery = (
  { __typename?: 'Query' }
  & { getChangeRequests?: Maybe<Array<Maybe<(
    { __typename?: 'PropertyChangeRequest' }
    & Pick<PropertyChangeRequest, 'id' | 'state' | 'createdBy' | 'createdAt' | 'productName' | 'propertyName' | 'textValue' | 'booleanValue'>
    & { numericValue?: Maybe<(
      { __typename?: 'NumericProperty' }
      & Pick<NumericProperty, 'value' | 'isNotApplicable'>
    )> }
  )>>> }
);

export type createProductsChangeRequestMutationVariables = Exact<{
  products: Array<ProductInput> | ProductInput;
}>;


export type createProductsChangeRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createProductsChangeRequest'>
);

export type responseProductsChangeRequestMutationVariables = Exact<{
  accepted?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  rejected?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  modified?: Maybe<Array<Maybe<ModifiedChangeRequestInput>> | Maybe<ModifiedChangeRequestInput>>;
}>;


export type responseProductsChangeRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'responseProductsChangeRequest'>
);

export type NumericPropertyFieldsFragment = (
  { __typename?: 'NumericProperty' }
  & Pick<NumericProperty, 'value' | 'isNotApplicable'>
);

type ProductFields_Dispersion_Fragment = (
  { __typename: 'Dispersion' }
  & Pick<Dispersion, 'isDeleted' | 'carbon' | 'mass' | 'year' | 'includingBioCarbon' | 'excludingBioCarbon' | 'subType' | 'primaryApplication' | 'anionicCationicNonionicDispersion' | 'biocontentMethod' | 'feedstockType' | 'biodegradability' | 'crystallization' | 'dispersionBasis' | 'family' | 'blockingAgent' | 'monomerBasis' | 'name' | 'previousName' | 'solvent' | 'supplier' | 'type' | 'viscosityMethod'>
  & { carbonFootprint?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, COOH?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, MFFT?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, MwByGPC?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, PDIByGPC?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, OH?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, activationTemperature?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, biocontent?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, elongationAtBreak?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, deblockTemperature?: Maybe<(
    { __typename?: 'NumericProperty' }
    & Pick<NumericProperty, 'isNotApplicable' | 'value'>
  )>, meltingTemp?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, modulusMpa?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, pH?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, particleSize?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, softeningPoint?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, solids?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, tensileStrengthMpa?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, typicalViscosityMPaS?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, viscosityMPaS?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, viscosityRange?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, tg?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, tg2?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, OHNumber?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, acid?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )> }
);

type ProductFields_Coreactant_Fragment = (
  { __typename: 'Coreactant' }
  & Pick<Coreactant, 'isDeleted' | 'carbon' | 'mass' | 'year' | 'includingBioCarbon' | 'excludingBioCarbon' | 'subType' | 'primaryApplication' | 'PAEComposition' | 'biocontentMethod' | 'feedstockType' | 'biodegradability' | 'family' | 'name' | 'previousName' | 'monomerBasis' | 'solvent' | 'supplier' | 'type' | 'viscosityMethod'>
  & { carbonFootprint?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, acid?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, OHNumber?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, COOH?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, MFFT?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, OH?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, amineNumber?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, biocontent?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, equivalentWeight?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, functionality?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, maxGardnerColor?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, maxHazenAPHAPtCoColor?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, maxIodineColor?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, softeningPoint?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, tg?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, tg2?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, solids?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, viscosityMPaS?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, viscosityRange?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, viscosityTemperature?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )> }
);

type ProductFields_Isocyanate_Fragment = (
  { __typename: 'Isocyanate' }
  & Pick<Isocyanate, 'isDeleted' | 'carbon' | 'mass' | 'year' | 'includingBioCarbon' | 'excludingBioCarbon' | 'subType' | 'primaryApplication' | 'biocontentMethod' | 'feedstockType' | 'biodegradability' | 'blockingAgent' | 'family' | 'monomerBasis' | 'name' | 'previousName' | 'solvent' | 'supplier' | 'type'>
  & { carbonFootprint?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, NCO?: Maybe<(
    { __typename?: 'NumericProperty' }
    & Pick<NumericProperty, 'isNotApplicable' | 'value'>
  )>, NCORange?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, biocontent?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, deblockTemperature?: Maybe<(
    { __typename?: 'NumericProperty' }
    & Pick<NumericProperty, 'isNotApplicable' | 'value'>
  )>, equivalentWeight?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, functionality?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, maxGardnerColor?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, maxHazenAPHAPtCoColor?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, maxIodineColor?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, maximumMonomer?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, solids?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, viscosityMPaS?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, viscosityRange?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, viscosityTemperature?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )> }
);

type ProductFields_NonreactiveResin_Fragment = (
  { __typename: 'NonreactiveResin' }
  & Pick<NonreactiveResin, 'family' | 'supplier' | 'name' | 'type' | 'subType' | 'isDeleted' | 'primaryApplication' | 'carbon' | 'mass' | 'year' | 'includingBioCarbon' | 'excludingBioCarbon' | 'viscosityMethod' | 'solvent' | 'biocontentMethod' | 'feedstockType' | 'biodegradability' | 'previousName'>
  & { carbonFootprint?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, viscosityMPaS?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, viscosityRange?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, typicalViscosityMPaS?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, solids?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, biocontent?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, otherReactiveGroupPercentage?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, functionality?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, maxGardnerColor?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, maxHazenAPHAPtCoColor?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, maximumMonomer?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )> }
);

type ProductFields_OtherReactiveSystems_Fragment = (
  { __typename: 'OtherReactiveSystems' }
  & Pick<OtherReactiveSystems, 'family' | 'supplier' | 'name' | 'type' | 'subType' | 'isDeleted' | 'carbon' | 'mass' | 'year' | 'includingBioCarbon' | 'excludingBioCarbon' | 'primaryApplication' | 'viscosityMethod' | 'solvent' | 'biocontentMethod' | 'feedstockType' | 'biodegradability' | 'previousName'>
  & { carbonFootprint?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, viscosityMPaS?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, viscosityRange?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, typicalViscosityMPaS?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, solids?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, biocontent?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, otherReactiveGroupPercentage?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, functionality?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, maxGardnerColor?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, maxHazenAPHAPtCoColor?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, maximumMonomer?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )> }
);

type ProductFields_EnergyCurableSolutions_Fragment = (
  { __typename: 'EnergyCurableSolutions' }
  & Pick<EnergyCurableSolutions, 'isDeleted' | 'carbon' | 'mass' | 'year' | 'includingBioCarbon' | 'excludingBioCarbon' | 'subType' | 'primaryApplication' | 'family' | 'name' | 'supplier' | 'type' | 'diluent'>
  & { carbonFootprint?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, MwByGPC?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, biocontent?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, viscosityMPaS?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, tg?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, acid?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, functionality?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, maxGardnerColor?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, maxHazenAPHAPtCoColor?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )>, density?: Maybe<(
    { __typename?: 'NumericProperty' }
    & NumericPropertyFieldsFragment
  )> }
);

export type ProductFieldsFragment = ProductFields_Dispersion_Fragment | ProductFields_Coreactant_Fragment | ProductFields_Isocyanate_Fragment | ProductFields_NonreactiveResin_Fragment | ProductFields_OtherReactiveSystems_Fragment | ProductFields_EnergyCurableSolutions_Fragment;

export type deleteProductMutationVariables = Exact<{
  product: Scalars['String'];
}>;


export type deleteProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteProduct'>
);

export type filterOptionsQueryVariables = Exact<{
  filter?: Maybe<ProductFilterInput>;
}>;


export type filterOptionsQuery = (
  { __typename?: 'Query' }
  & { getPossibleProductFilters?: Maybe<(
    { __typename?: 'PossibleProductFilters' }
    & Pick<PossibleProductFilters, '_total' | 'family' | 'primaryApplication' | 'biocontentMethod' | 'monomerBasis' | 'solvent' | 'blockingAgent' | 'subType' | 'supplier' | 'type' | 'feedstockType' | 'year'>
  )> }
);

export type productsQueryVariables = Exact<{
  filter?: Maybe<ProductFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Array<Maybe<SortBy>> | Maybe<SortBy>>;
}>;


export type productsQuery = (
  { __typename?: 'Query' }
  & { getProducts?: Maybe<Array<Maybe<(
    { __typename?: 'Dispersion' }
    & ProductFields_Dispersion_Fragment
  ) | (
    { __typename?: 'Coreactant' }
    & ProductFields_Coreactant_Fragment
  ) | (
    { __typename?: 'Isocyanate' }
    & ProductFields_Isocyanate_Fragment
  ) | (
    { __typename?: 'NonreactiveResin' }
    & ProductFields_NonreactiveResin_Fragment
  ) | (
    { __typename?: 'OtherReactiveSystems' }
    & ProductFields_OtherReactiveSystems_Fragment
  ) | (
    { __typename?: 'EnergyCurableSolutions' }
    & ProductFields_EnergyCurableSolutions_Fragment
  )>>> }
);

export type userQueryVariables = Exact<{ [key: string]: never; }>;


export type userQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'department' | 'roles'>
  )> }
);

export const NumericPropertyFieldsFragmentDoc = gql`
    fragment NumericPropertyFields on NumericProperty {
  value
  isNotApplicable
}
    `;
export const ProductFieldsFragmentDoc = gql`
    fragment ProductFields on Product {
  __typename
  ... on Dispersion {
    isDeleted
    carbon
    mass
    carbonFootprint {
      ...NumericPropertyFields
    }
    year
    includingBioCarbon
    excludingBioCarbon
    subType
    primaryApplication
    COOH {
      ...NumericPropertyFields
    }
    MFFT {
      ...NumericPropertyFields
    }
    MwByGPC {
      ...NumericPropertyFields
    }
    PDIByGPC {
      ...NumericPropertyFields
    }
    OH {
      ...NumericPropertyFields
    }
    activationTemperature {
      ...NumericPropertyFields
    }
    anionicCationicNonionicDispersion
    biocontent {
      ...NumericPropertyFields
    }
    biocontentMethod
    feedstockType
    biodegradability
    crystallization
    dispersionBasis
    elongationAtBreak {
      ...NumericPropertyFields
    }
    family
    deblockTemperature {
      isNotApplicable
      value
    }
    blockingAgent
    meltingTemp {
      ...NumericPropertyFields
    }
    modulusMpa {
      ...NumericPropertyFields
    }
    monomerBasis
    name
    pH {
      ...NumericPropertyFields
    }
    particleSize {
      ...NumericPropertyFields
    }
    previousName
    softeningPoint {
      ...NumericPropertyFields
    }
    solids {
      ...NumericPropertyFields
    }
    solvent
    supplier
    tensileStrengthMpa {
      ...NumericPropertyFields
    }
    type
    typicalViscosityMPaS {
      ...NumericPropertyFields
    }
    viscosityMPaS {
      ...NumericPropertyFields
    }
    viscosityMethod
    viscosityRange {
      ...NumericPropertyFields
    }
    tg {
      ...NumericPropertyFields
    }
    tg2 {
      ...NumericPropertyFields
    }
    OHNumber {
      ...NumericPropertyFields
    }
    acid {
      ...NumericPropertyFields
    }
  }
  ... on Coreactant {
    isDeleted
    carbon
    mass
    carbonFootprint {
      ...NumericPropertyFields
    }
    year
    includingBioCarbon
    excludingBioCarbon
    subType
    primaryApplication
    acid {
      ...NumericPropertyFields
    }
    OHNumber {
      ...NumericPropertyFields
    }
    COOH {
      ...NumericPropertyFields
    }
    MFFT {
      ...NumericPropertyFields
    }
    OH {
      ...NumericPropertyFields
    }
    PAEComposition
    amineNumber {
      ...NumericPropertyFields
    }
    biocontent {
      ...NumericPropertyFields
    }
    biocontentMethod
    feedstockType
    biodegradability
    equivalentWeight {
      ...NumericPropertyFields
    }
    family
    functionality {
      ...NumericPropertyFields
    }
    maxGardnerColor {
      ...NumericPropertyFields
    }
    maxHazenAPHAPtCoColor {
      ...NumericPropertyFields
    }
    maxIodineColor {
      ...NumericPropertyFields
    }
    name
    previousName
    softeningPoint {
      ...NumericPropertyFields
    }
    monomerBasis
    tg {
      ...NumericPropertyFields
    }
    tg2 {
      ...NumericPropertyFields
    }
    solids {
      ...NumericPropertyFields
    }
    solvent
    supplier
    type
    viscosityMPaS {
      ...NumericPropertyFields
    }
    viscosityMethod
    viscosityRange {
      ...NumericPropertyFields
    }
    viscosityTemperature {
      ...NumericPropertyFields
    }
  }
  ... on Isocyanate {
    isDeleted
    carbon
    mass
    carbonFootprint {
      ...NumericPropertyFields
    }
    year
    includingBioCarbon
    excludingBioCarbon
    subType
    primaryApplication
    NCO {
      isNotApplicable
      value
    }
    NCORange {
      ...NumericPropertyFields
    }
    biocontent {
      ...NumericPropertyFields
    }
    biocontentMethod
    feedstockType
    biodegradability
    blockingAgent
    deblockTemperature {
      isNotApplicable
      value
    }
    equivalentWeight {
      ...NumericPropertyFields
    }
    family
    functionality {
      ...NumericPropertyFields
    }
    maxGardnerColor {
      ...NumericPropertyFields
    }
    maxHazenAPHAPtCoColor {
      ...NumericPropertyFields
    }
    maxIodineColor {
      ...NumericPropertyFields
    }
    maximumMonomer {
      ...NumericPropertyFields
    }
    monomerBasis
    name
    previousName
    solids {
      ...NumericPropertyFields
    }
    solvent
    supplier
    type
    viscosityMPaS {
      ...NumericPropertyFields
    }
    viscosityRange {
      ...NumericPropertyFields
    }
    viscosityTemperature {
      ...NumericPropertyFields
    }
  }
  ... on NonreactiveResin {
    family
    supplier
    name
    type
    subType
    isDeleted
    primaryApplication
    carbon
    mass
    carbonFootprint {
      ...NumericPropertyFields
    }
    year
    includingBioCarbon
    excludingBioCarbon
    viscosityMPaS {
      ...NumericPropertyFields
    }
    viscosityRange {
      ...NumericPropertyFields
    }
    viscosityMethod
    typicalViscosityMPaS {
      ...NumericPropertyFields
    }
    solids {
      ...NumericPropertyFields
    }
    solvent
    biocontent {
      ...NumericPropertyFields
    }
    biocontentMethod
    feedstockType
    biodegradability
    otherReactiveGroupPercentage {
      ...NumericPropertyFields
    }
    functionality {
      ...NumericPropertyFields
    }
    maxGardnerColor {
      ...NumericPropertyFields
    }
    maxHazenAPHAPtCoColor {
      ...NumericPropertyFields
    }
    maximumMonomer {
      ...NumericPropertyFields
    }
    previousName
  }
  ... on OtherReactiveSystems {
    family
    supplier
    name
    type
    subType
    isDeleted
    carbon
    mass
    carbonFootprint {
      ...NumericPropertyFields
    }
    year
    includingBioCarbon
    excludingBioCarbon
    primaryApplication
    viscosityMPaS {
      ...NumericPropertyFields
    }
    viscosityRange {
      ...NumericPropertyFields
    }
    viscosityMethod
    typicalViscosityMPaS {
      ...NumericPropertyFields
    }
    solids {
      ...NumericPropertyFields
    }
    solvent
    biocontent {
      ...NumericPropertyFields
    }
    biocontentMethod
    feedstockType
    biodegradability
    otherReactiveGroupPercentage {
      ...NumericPropertyFields
    }
    functionality {
      ...NumericPropertyFields
    }
    maxGardnerColor {
      ...NumericPropertyFields
    }
    maxHazenAPHAPtCoColor {
      ...NumericPropertyFields
    }
    maximumMonomer {
      ...NumericPropertyFields
    }
    previousName
  }
  ... on EnergyCurableSolutions {
    isDeleted
    carbon
    mass
    carbonFootprint {
      ...NumericPropertyFields
    }
    year
    includingBioCarbon
    excludingBioCarbon
    subType
    primaryApplication
    MwByGPC {
      ...NumericPropertyFields
    }
    biocontent {
      ...NumericPropertyFields
    }
    family
    name
    supplier
    type
    viscosityMPaS {
      ...NumericPropertyFields
    }
    tg {
      ...NumericPropertyFields
    }
    acid {
      ...NumericPropertyFields
    }
    functionality {
      ...NumericPropertyFields
    }
    maxGardnerColor {
      ...NumericPropertyFields
    }
    maxHazenAPHAPtCoColor {
      ...NumericPropertyFields
    }
    diluent
    density {
      ...NumericPropertyFields
    }
  }
}
    ${NumericPropertyFieldsFragmentDoc}`;
export const ProductHistoryLogsDataDocument = gql`
    query ProductHistoryLogsData($limit: Int!, $offset: Int!, $sortBy: [SortBy], $search: String!) {
  getProductHistoryLogs(
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    search: $search
  ) {
    totalRequest
    logsData {
      createdAt
      createdBy
      productName
      state
      enteredValue {
        propertyName
        numericValue {
          isNotApplicable
          value
        }
        textValue
        booleanValue
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductHistoryLogsDataGQL extends Apollo.Query<ProductHistoryLogsDataQuery, ProductHistoryLogsDataQueryVariables> {
    document = ProductHistoryLogsDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const getOpenChangeRequestsDocument = gql`
    query getOpenChangeRequests($page: Int) {
  getChangeRequests(state: "open", page: $page) {
    id
    state
    createdBy
    createdAt
    productName
    propertyName
    numericValue {
      value
      isNotApplicable
    }
    textValue
    booleanValue
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class getOpenChangeRequestsGQL extends Apollo.Query<getOpenChangeRequestsQuery, getOpenChangeRequestsQueryVariables> {
    document = getOpenChangeRequestsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const createProductsChangeRequestDocument = gql`
    mutation createProductsChangeRequest($products: [ProductInput!]!) {
  createProductsChangeRequest(products: $products)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class createProductsChangeRequestGQL extends Apollo.Mutation<createProductsChangeRequestMutation, createProductsChangeRequestMutationVariables> {
    document = createProductsChangeRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const responseProductsChangeRequestDocument = gql`
    mutation responseProductsChangeRequest($accepted: [Int], $rejected: [Int], $modified: [ModifiedChangeRequestInput]) {
  responseProductsChangeRequest(
    accepted: $accepted
    rejected: $rejected
    modified: $modified
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class responseProductsChangeRequestGQL extends Apollo.Mutation<responseProductsChangeRequestMutation, responseProductsChangeRequestMutationVariables> {
    document = responseProductsChangeRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const deleteProductDocument = gql`
    mutation deleteProduct($product: String!) {
  deleteProduct(product: $product)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class deleteProductGQL extends Apollo.Mutation<deleteProductMutation, deleteProductMutationVariables> {
    document = deleteProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const filterOptionsDocument = gql`
    query filterOptions($filter: ProductFilterInput) {
  getPossibleProductFilters(filter: $filter) {
    _total
    family
    primaryApplication
    biocontentMethod
    monomerBasis
    solvent
    blockingAgent
    subType
    supplier
    type
    feedstockType
    year
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class filterOptionsGQL extends Apollo.Query<filterOptionsQuery, filterOptionsQueryVariables> {
    document = filterOptionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const productsDocument = gql`
    query products($filter: ProductFilterInput, $limit: Int, $offset: Int, $sortBy: [SortBy]) {
  getProducts(filter: $filter, limit: $limit, offset: $offset, sortBy: $sortBy) {
    ...ProductFields
  }
}
    ${ProductFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class productsGQL extends Apollo.Query<productsQuery, productsQueryVariables> {
    document = productsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const userDocument = gql`
    query user {
  getUser {
    email
    department
    roles
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class userGQL extends Apollo.Query<userQuery, userQueryVariables> {
    document = userDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }