import * as types from '../../generated/graphql.json';

export const ENUMS = readEnums();
export const REVERSED_ENUMS = Object.fromEntries(Object.keys(ENUMS)
  .map(type => [type, Object.fromEntries(Object.entries(ENUMS[type]).map(([key, value]) => [value, key]))]));

function readEnums(): Record<string, Record<string, string>> {
  return Object.fromEntries(
    types.__schema.types
      .filter(type => type.kind === "ENUM")
      .map(enumType => [
        enumType.name,
        Object.fromEntries(enumType.enumValues
          .map(value => [value.name, value.description?.replace('#', '') ?? value.name]))
      ]));
}
