<div class="product-basket">
  <div class="bookmark-btn"><button class="bkmrk-btn" data-ui="secondary" (click)="openbookmarkDialog()">
    <img src="../../assets/icon_bookmark.svg"> Bookmark this tab </button></div>
  
  <div class="product-basket-list">
    <div class="product-basket-list-entry" *ngFor="let key of objectKeys(sortedArray); let I = index">
      <ng-container>
        <div class="product-basket-category">
          {{key}}
        </div>
        <div class="product-basket-product" *ngFor="let product of sortedArray[key]; let i = index">
          <div class="product-basket-name">
            {{product.name}} ({{product.supplier}})
          </div>
          <div class="product-basket-delete" (mousedown)="deleteProduct(product)" clickable>
            <i class="far fa-trash-alt"></i>
          </div>
        </div>
      </ng-container>
      <div class="product-basket-interactions">
        <button (click)="compareProducts(sortedArray[key])">Compare selected products</button>
        <button data-ui="secondary" (click)="deleteProducts(sortedArray[key])"> <i class="far fa-trash-alt"></i>
          Delete products from comparison</button>
      </div>
    </div>
    <div class="product-basket-interactions" *ngIf="!hideBasket">
      <button disabled>Compare selected products</button>
    </div>
  </div>
</div>
