import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChange, SimpleChanges, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ScatterPlotComponent } from 'covestro-ui-components/components/charts/scotter-plott/scatter-plot.component';
import { OverlayCreatorService } from 'covestro-ui-components/components/molecules/overlay-creator.service';
import { SortableTable } from 'covestro-ui-components/components/organisms/sortable-table/SortableTable.stories';
import { flatMap, groupBy, orderBy, uniq, uniqBy } from 'lodash';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { SearchResultsPageComponent } from 'src/app/pages/search-results/search-results.component';
import { GtagService } from 'src/app/shared/gtag/gtag.service';
import { Product, ProductInput } from 'src/generated/graphql';
import { LAZY_LOAD_WRAPPER, LOADING_WRAPPER, SortableTableComponent } from '../../../../../components/components/organisms/sortable-table/sortable-table.component';
import { hasChanged } from '../../../../../components/helpers/ngHasChanged';
import { generateDistributedColorsFromHex } from '../../../../../components/services/color-functions';
import { ProductsBasketStoreService } from '../../services/product-basket.store.service';
import { PRODUCT_PROPERTIES_BY_FAMILY } from '../../services/product-properties.service';
import { MissingDataComponent } from '../missing-data/missing-data.component';

@Component({
  selector: 'app-product-scatter-plot',
  templateUrl: 'product-scatter-plot.html',
  styleUrls: ['product-scatter-plot.scss']
})
export class ProductScatterPlotComponent implements OnInit, OnChanges, OnDestroy {

  private static defaultVizualisationProperties: DiagramProperties = {
    diagramPer: 'type',
    x: null,
    y: null,
    size: null,
    color: 'supplier'
  };

  @Input()
  vizualisationProperties: DiagramProperties = null;

  @Input()
  keyFn: (item: Product) => string;
  missingData: {};

  @Input()
  set products(products: Array<Product>) {
    this._products = products?.filter(product => product !== LOADING_WRAPPER && product !== LAZY_LOAD_WRAPPER);
  }

  get products() {
    return this._products;
  }

  _products: Array<Product>;

  @Input()
  productsSelected: Set<Product> = new Set();

  @Output()
  productsSelectedChange = new EventEmitter<Set<Product>>();

  @Output()
  vizualisationPropertiesChange = new EventEmitter<DiagramProperties>();

  propertiesForm: FormGroup = this.fb.group(this.vizualisationProperties ?? ProductScatterPlotComponent.defaultVizualisationProperties);
  private propertiesFormSub: Subscription;

  referenceProductColor = '#009fe4';

  colors = [
    '#00bb7e',
    '#ffee00',
    '#ff7f41',
    '#e6007c',
    '#7d55c7',
    '#000000'
  ];

  productKeys: Array<{ label: string, property: string }>;
  notDisplayable:number=0;

  items: DataItems<Product>[];
  classificationColors: { [key: string]: string };

  selected: Set<Product>[] = [];
  isAnySelected: boolean = false;
  isProductSelected: Array<Product> = [];

  properties: Record<string, string>;
  graphProperties: Array<[string, string]>;
  sortedProperties: Array<[string, string]>;
  options: any;
  private productDataItemsAssignment: { [name: string]: number } = {};

  constructor(
    private productsBasketStoreService: ProductsBasketStoreService,
    private overlayCreatorService: OverlayCreatorService,
    private gtag: GtagService,
    private fb: FormBuilder,
    private router: Router,
    private location: Location
    
  ) {
  }
  
  @ViewChildren(ScatterPlotComponent, { emitDistinctChangesOnly: true }) scatterPlots!: QueryList<ScatterPlotComponent>;

  ngOnInit(): void {
     // ToDo: Somehow the initial view, does get updated by the prop config
    if (this.sortedProperties?.length > 1) this.sortedProperties = [];
    const properties = uniqBy(uniq(this.products.map(product => product.family))
    .flatMap(family => PRODUCT_PROPERTIES_BY_FAMILY[family]), prop => prop.name);

    this.sortedProperties = orderBy(properties.map(property => ({name: property.name, label: property.label})), 'label')
      .map(property => [property.name, property.label]);
    this.properties = Object.fromEntries(this.sortedProperties);

    const numberProperties = properties.filter(property => property.type === 'number');
    this.graphProperties =  orderBy(numberProperties.map(property => ({name: property.name, label: property.label})), 'label')
    .map(property => [property.name, property.label]);

    this.propertiesFormSub = this.propertiesForm.valueChanges
      .pipe(
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        tap(filter => this.vizualisationPropertiesChange.emit(filter)),
        tap(() => this.gtag.click('change-graph-select')),
        tap(_ => this.ngOnChanges({
          products: new SimpleChange(null, this.products, true),
          productsSelected: null,
        }))
      )
      .subscribe();
        
    if (this.vizualisationProperties) {
      this.propertiesForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }
  }

  submit (data:Product, xaxis:any, yaxis:any) {
    if(xaxis != yaxis){
    this.overlayCreatorService.open(MissingDataComponent, {data,xaxis,yaxis});
      } 
    else{
      this.overlayCreatorService.open(MissingDataComponent, {data,xaxis});
    }
  }    

  updateItems(): void {
     this.items = this.createDataItems(
       this.products.filter(p => p.supplier !== 'Covestro'),
       this.products.filter(p => p.supplier === 'Covestro')
     );

     this.ngOnChanges({
      products: null,
      productsSelected: new SimpleChange(null, true, true),
     });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (hasChanged(changes.products)) {
      this.items = this.createDataItems(
        this.products.filter(p => p.supplier !== 'Covestro'),
        this.products.filter(p => p.supplier === 'Covestro')
      );
    }
    if (hasChanged(changes.products) || hasChanged(changes.productsSelected)) {
      this.selected = this.items.map((_) => new Set());
      this.isProductSelected = [];
      this.productsSelected?.forEach(selected => {
        const index = this.productDataItemsAssignment[this.keyFn(selected)];
        if (index != null) {
          this.selected[index].add(selected);
          this.isProductSelected.push(selected);
        }
      });
      this.isAnySelected = this.selected.some(s => s && s.size > 0);
    }
    if (hasChanged(changes.vizualisationProperties)) {
      this.propertiesForm.setValue(this.vizualisationProperties ?? ProductScatterPlotComponent.defaultVizualisationProperties);
    }
    const urlTree = this.router.createUrlTree([], {
      queryParams: { diagramPer:  this.propertiesForm.value.diagramPer, x: this.propertiesForm.value.x, y: this.propertiesForm.value.y, size:this.propertiesForm.value.size, color:this.propertiesForm.value.color},
      queryParamsHandling: "merge",
      preserveFragment: false }).toString();
      this.location.go(urlTree);
  }

  selectProductInGraph(product: Product, i: number): void {
    this.gtag.click('add-product-in-graph');
    this.productsBasketStoreService.addToBasket(product);
    this.isProductSelected.push(product);
    this.selected[i].add(product);
    this.updateItems();
  }

  removeProductInGraph(product: Product, i: number): void {
    this.gtag.click('remove-product-in-graph');
    this.productsBasketStoreService.removeFromBasket(product);
    this.isProductSelected = this.isProductSelected.filter(values => values.name !== product.name);
    this.selected[i].delete(product);
    this.updateItems();
  }

  private createDataItems(products: Product[], referenceProducts: Product[]): DataItems<Product>[] {
    if (!this.propertiesForm.controls['x'].value) {
      return [];
    }
    let items = this.mapData(products, referenceProducts, this.propertiesForm.value);
    const classifications = uniq(flatMap(items, (dataItems) => dataItems.items.map(dataItems.color ?? (() => undefined))));
    const calculatedColors = generateDistributedColorsFromHex([this.referenceProductColor, ...this.colors], classifications.length);
    this.classificationColors = Object.fromEntries(classifications
      .map((classification: string, idx) => [classification, calculatedColors[idx]]));
    this.appendClassificationColors(items);
    this.assignProductDataItemsIndex(items);
    return items;
  }

  ngOnDestroy(): void {
    this.propertiesFormSub.unsubscribe();
  }

  private mapData(
    products: Array<Product>,
    referenceProducts: Array<Product>,
    diagramProperties: DiagramProperties,
  ): Array<DataItems<Product>> {
    if (!diagramProperties.x) {
      return [];
    }

    const allProducts = [...(referenceProducts ?? []), ...(products ?? [])];
    const grouped = groupBy(
      allProducts,
      (item) => diagramProperties.diagramPer ? item[diagramProperties.diagramPer] : null
    );
    const result = Object.entries(grouped)
      .map(([diagram, products]) => {
        const dataItems: Omit<DataItems<Product>, 'metrics'> = {
          diagram,
          x: (products) => products[diagramProperties.x],
          y: diagramProperties.y ? (products) => products[diagramProperties.y] : undefined,
          size: diagramProperties.size ? (products) => products[diagramProperties.size] : undefined,
          color: diagramProperties.color ? (products) => products[diagramProperties.color] : undefined,
          items: products,
          classificationColors: {}
        };
        dataItems['metrics'] = {
          numberOfProducts: dataItems.items.length,
          notDisplayable: this.calculateNumberOfNotDisplayable(dataItems),
          notSizable: dataItems.size ? dataItems.items.map(dataItems.size).filter((s) => !s).length : undefined,
        };
        return dataItems as DataItems<Product>;
    });
    return result;
  }

  private appendClassificationColors(chart: DataItems<Product>[]): void {
    chart.forEach(dataItems => {
      if (dataItems.classificationColors && dataItems.color) {
        uniq(dataItems.items.map(dataItems.color)).forEach(color =>
          dataItems.classificationColors[color] = this.classificationColors[color]);
      }
    });
  }

  private assignProductDataItemsIndex(items: DataItems<Product>[]): void {
    this.productDataItemsAssignment = {};
    items?.forEach((dataItems, index) => {
      dataItems.items.forEach(item => this.productDataItemsAssignment[this.keyFn(item)] = index);
    });
  }

  private calculateNumberOfNotDisplayable<T>(dataItems: Pick<DataItems<T>, 'x' | 'y' | 'items'>): any {
    const nv = dataItems.items
      .filter(item => dataItems.x(item) == null || (dataItems.y && dataItems.y(item) == null))
      .length;
     const np = dataItems.items
     .filter(item => dataItems.x(item) == null || (dataItems.y && dataItems.y(item) == null))
    return np;
  }
}

interface DataItems<T> {
  diagram: number | string;
  x: (item: T) => number | string;
  y?: (item: T) => number | string;
  size?: (item: T) => number;
  color?: (item: T) => number | string;
  classificationColors?: { [classification: string]: string };
  items: Array<T>;
  metrics: DataItemsMetrics;
}

interface DataItemsMetrics {
  numberOfProducts: number;
  notDisplayable: number;
  notSizable: number;
  numberOfSelected?: number;
}

export interface DiagramProperties {
  diagramPer: string;
  x: string;
  y: string;
  size: string;
  color: string;
}
