import { Component, EventEmitter, Input, Output, ContentChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'popover-menu',
  templateUrl: './popover-menu.component.html',
  styleUrls: ['./popover-menu.component.scss']
})

export class PopoverMenuComponent {

  // tslint:disable-next-line:no-input-rename
  @Input()
  dataUi: string;

  @Input()
  label: string;

  @Input()
  direction: 'left' | 'right' = 'left';

  @ContentChild(TemplateRef)
  content: TemplateRef<any>;

  showSelection = false;

  close(event: MouseEvent): void {
    event.stopPropagation();
    const element = event.target as HTMLInputElement;
    const className = element.className;
    if (className !== 'option') {
      this.showSelection = false;
    }
  }
}
