import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GtagService } from 'src/app/shared/gtag/gtag.service';
import { Product } from 'src/generated/graphql';
import { ProductsBasketStoreService } from '../../services/product-basket.store.service';

@Component({
  selector: 'app-products-basket',
  templateUrl: './products-basket.component.html'
})
export class ProductsBasketComponent implements OnInit {
  basket$: Observable<Array<Product>>;

  currentRoute: string;

  constructor(
    private productsBasketStoreService: ProductsBasketStoreService,
    private gtag: GtagService,
    private router: Router,
  ){}

  ngOnInit() {
    this.basket$ = this.productsBasketStoreService.basket$;
  }

  deleteProducts(products: Product[]): void {
    this.gtag.click('delete-products');
    this.productsBasketStoreService.removeBulkFromBasket(products);
  }

  deleteProduct(product: Product): void {
    this.gtag.click('delete-product');
    this.productsBasketStoreService.removeFromBasket(product);
  }

  compareProducts(products: Product[]): void {
    this.gtag.click('compare-product');
    const navigationExtras: NavigationExtras = {
      queryParams: { ids: JSON.stringify(products.map(product => product.name)) }
    };
    this.router.navigate(['/compare-details'], navigationExtras);
  }
}
