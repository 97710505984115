<div class="main">
  <section class="actions">
    <form [formGroup]="form" class="products-import-form">
      <input type="file" class="file-input" accept=".csv,.xlsx" #file (change)="fileChanged($event)" />
      <div class="file-upload">
        {{fileName || "No file uploaded yet."}}
        <button color="primary" class="upload-btn" (click)="file.click()">
          <i class="fa fa-paperclip" aria-hidden="true"></i>
        </button>
      </div>
      <div class="customSelect">
        <select formControlName="family" (change)="selectChangeHandler()">
          <option [ngValue]="null" disabled selected>Product Family</option>
          <!-- //// todo: To enable ALl option in Drop down please uncomment below code-->
          <!-- <option [ngValue]="">All</option> -->
          <option *ngFor="let item of productFamilies | keyvalue;" [ngValue]="item.value"  >
            {{item.value}}</option>
        </select>
      </div>
      <button [disabled]="!fileName || !form.controls['family'].value" #import (click)="importClicked()">Import</button>
      <button (click)="clean()">Clean</button>
    </form>
  </section>
  <progress-bar *ngIf="importProgress" [progress]="importProgress" [total]="total"></progress-bar>
  <div *ngIf="success" class="success">{{success}}</div>
  <div *ngIf="error?.errorMessage"  data-ui="warn">{{error.errorMessage}}</div>
  
  <div *ngIf="errorLog?.length > 0"  data-ui="warn">
    <h2>Errorlog for the file: {{fileName}}</h2>
    <ul>
      <li *ngFor="let errorMessages of errorLog">
        {{errorMessage}}
      </li>
    </ul>
  </div>
  <br>
  <app-excel-preview [data]="preview"></app-excel-preview>
  <div *ngIf="products?.length > 0">
    <div>
      <sortable-table [data]="products" [fields]="['family', 'supplier', 'name']" [showFields]="showColumns">
      </sortable-table>
    </div>
  </div>
</div>
