import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { groupBy } from 'lodash';
import { BookmarkComponent } from 'src/app/components/bookmark/bookmark.component';
import { DialogService } from 'src/app/components/dialog/dialog.service';
import { GtagService } from 'src/app/shared/gtag/gtag.service';



@Component({
  selector: 'product-basket',
  templateUrl: './product-basket.component.html',
  styleUrls: ['./product-basket.component.scss']
})

export class ProductBasketComponent<T> implements OnInit, OnChanges {

  objectKeys = Object.keys;
  private _data: Array<T>;
  sortedArray: Array<T>
  hideBasket: boolean = true;
  options: any= '';
  

  constructor(
    public dialogService: DialogService ,
    private gtag: GtagService
    ){}

  @Input('data')
  set data(data: Array<T>) {
    this._data = data;
  }

  get data(): Array<T> {
    return this._data;
  }

  @Input()
  sortBy: string;

  @Input()
  hideCompare: boolean = false;

  @Output()
  deletedProduct = new EventEmitter<string>();

  @Output()
  deletedProducts = new EventEmitter<Array<string>>();

  @Output()
  comparedProducts = new EventEmitter<Array<string>>();

  ngOnInit(): void {
    this.sortedArray = groupBy(this._data, this.sortBy);
    this.hideBasket = Object.keys(this.sortedArray).length > 0 ? true : false;
  }

  ngOnChanges(): void {
    this.sortedArray = groupBy(this._data, this.sortBy);
    this.hideBasket = Object.keys(this.sortedArray).length > 0 ? true : false;
  }

  deleteProduct(product: string): void {
    this.deletedProduct.emit(product);
  }

  deleteProducts(products: Array<string>): void {
    this.deletedProducts.emit(products);
  }

  compareProducts(products: Array<string>): void {
    this.comparedProducts.emit(products);
  }

  openbookmarkDialog() {
    this.gtag.click('book-mark');
    const ref = this.dialogService.open( BookmarkComponent, {data: {options: this.options}});
    
  }


}


