export function calcWithOccurenceNumber<T>(array: Array<T>): Array<{item: T, occurence: number}> {
  const saw = new Map<T, number>();
  return array.map((item) => {
    let occurence = 0;
    if (!saw.has(item)) {
      saw.set(item, occurence);
    } else {
      occurence = saw.get(item) + 1;
      saw.set(item, occurence);
    }
    return {
      item,
      occurence
    };
  });
}
