<div class="selection" (clickOutside)="close($event)">
  <button [attr.data-ui]="dataUi" (mousedown)="showSelection = !showSelection">
    {{ label }}
    &nbsp;<i class="fas fa-chevron-down" *ngIf="!showSelection"></i><i class="fas fa-chevron-up" *ngIf="showSelection"></i>
  </button>
  <section [attr.data-ui]="dataUi" class="options" [class.right]="direction == 'right'" *ngIf="showSelection" (click)="close($event)">
    <ng-container *ngTemplateOutlet="content">
    </ng-container>
  </section>
</div>
