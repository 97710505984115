import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { CovestroUiComponentsModule } from 'covestro-ui-components/covestro-ui-components.module';
import { environment } from '../environments/environment';
// generated union and interface types by Fragment Matcher plugin
import generatedIntrospection from '../generated/introspection-result';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { DialogModule } from './components/dialog/dialog.module'
import { FeatureToggleDirective } from './components/feature-toggle/feature-toggle.directive';
import { MissingDataComponent } from './components/missing-data/missing-data.component';
import { MultiInteractions } from './components/multiInteractions/multi-interactions.component';
import { OpenRequestLinkComponent } from './components/open-request-link/open-request-link.component';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { ProductEditComponent } from './components/product-edit/product-edits.component';
import { ProductPropertyComponent } from './components/product-property/product-property.component';
import { ProductScatterPlotComponent } from './components/product-scatter-plot/product-scatter-plot.component';
import { ProductsBasketComponent } from './components/products-basket/products-basket.component';
import { ProductsFiltersComponent } from './components/products-filters/products-filters.component';
import { ProductsSearchComponent } from './components/products-search/products-search.component';
import { RequestModifyComponent } from './components/request-modify/request-modify.component';
import { SearchComponent } from './components/search/search.component';
import { UserNavigationComponent } from './components/user-navigation/user-navigation.component';
import { NotAuthorizedComponent } from './pages/authentication/not-authorized.component';
import { SigningInComponent } from './pages/authentication/signing-in.component';
import { SingleSignOnComponent } from './pages/authentication/single-sign-on.component';
import { ChangeRequestsComponent } from './pages/change-requests/change-requests.component';
import { CompareDetailsPageComponent } from './pages/compare-details/compare-details.component';
import { ProductsImportComponent } from './pages/products-import/products-import.component';
import { SearchResultsPageComponent } from './pages/search-results/search-results.component';
import { VariantSetPageComponent } from './pages/variant-set-page.component';
import { AuthInterceptor } from './services/auth.interceptor';
import { GtagModule } from './shared/gtag/gtag.module';
import { MissinreplaceNullWithTextPipe } from './components/missing-data/missinreplace-null-with-text.pipe';
import { ExcelPreviewComponent } from './components/excel-preview/excel-preview.component';
import { BookmarkComponent } from './components/bookmark/bookmark.component';
import { HelpSectionComponent } from './components/help-section/help-section.component';
import { LogHistoryComponent } from './pages/log-history/log-history.component';
import { ProductLogDetailsComponent } from './components/product-log-details/product-log-details.component';



const cache = new InMemoryCache({ possibleTypes: generatedIntrospection.possibleTypes });
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

@NgModule({
  declarations: [
    AppComponent,
    NotAuthorizedComponent,
    SingleSignOnComponent,
    SigningInComponent,
    SearchComponent,
    VariantSetPageComponent,
    FeatureToggleDirective,
    MultiInteractions,
    UserNavigationComponent,
    ProductScatterPlotComponent,
    CompareDetailsPageComponent,
    OpenRequestLinkComponent,
    ProductsImportComponent,
    ProductDetailsComponent,
    RequestModifyComponent,
    ProductEditComponent,
    SearchResultsPageComponent,
    ProductsBasketComponent,
    ProductsSearchComponent,
    ProductsFiltersComponent,
    ProductPropertyComponent,
    ChangeRequestsComponent,
    MissingDataComponent,
    MissinreplaceNullWithTextPipe,
    ExcelPreviewComponent,
    BookmarkComponent,
    HelpSectionComponent,
    LogHistoryComponent,
    ProductLogDetailsComponent,
    
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, { useHash: true }),
    CovestroUiComponentsModule,
    GtagModule.init({
      targetId: 'G-9DXEC0D3WK'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        return {
          cache, // InMemoryCache with custom fragment matcher
          defaultOptions: defaultOptions,
          link: httpLink.create({
            uri: environment.api.products
          })
        }
      },
      deps: [HttpLink]
    }
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule]
})
export class AppModule { }
