export const environment = {
    production: false,
    api: {
        products: 'https://m65ftos5zfanleahiqn7v4cbpe.appsync-api.eu-central-1.amazonaws.com/graphql'
    },
    auth: {
        userPoolId: 'eu-central-1_etkSXLdPq',
        clientId: '2uqipcnojemiii8shgcdq3ikh0',
        appWebDomain: 'covestro-dsl-qa-vcop.auth.eu-central-1.amazoncognito.com',
        identityProvider: 'QAADvcop',
        apiKey: ''
    }
  };