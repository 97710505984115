<section class="wrapper">
  <section class="login" data-ui="dark">
    <input placeholder="email" [(ngModel)]="username">
    <ng-container *ngIf="isLoginState">
      <input placeholder="password" type="password" [(ngModel)]="password" (keyup.enter)="login()">
      <button (click)="login()">Login</button>
      <button data-ui="secondary" (click)="forgotPassword()">Forgot password</button>
    </ng-container>
    <ng-container *ngIf="isNewPasswordRequired">
      <input placeholder="old password" type="password" [value]="password" disabled>
      <input placeholder="new password" type="password" [(ngModel)]="newPassword">
      <input placeholder="repeat password" type="password" [(ngModel)]="passwordRepeat" (keyup.enter)="changePassword()">
      <button (click)="changePassword()">Change Password</button>
    </ng-container>
    <ng-container *ngIf="isForgotPasswordState">
      <input placeholder="verification code (via e-mail)" [(ngModel)]="verificationCode">
      <input placeholder="new password" type="password" [(ngModel)]="newPassword">
      <input placeholder="repeat password" type="password" [(ngModel)]="passwordRepeat" (keyup.enter)="resetPassword()">
      <button (click)="resetPassword()">Set Password</button>
      <button data-ui="secondary" (click)="forgotPassword()">Resend Verification Code</button>
    </ng-container>
  </section>

  <section *ngIf="errorMessage" data-ui="warn">{{ errorMessage }}</section>
</section>
