import { Component,Input, OnChanges, OnInit } from '@angular/core';
import { DialogService } from '../dialog/dialog.service';

@Component({
  selector: 'app-product-log-details',
  templateUrl: './product-log-details.component.html',
  styleUrls: ['./product-log-details.component.scss']
})
export class ProductLogDetailsComponent<T> implements OnInit, OnChanges {
  data:any;
  objectKeys = Object.keys;
  sortedArray: Array<T>
  hideBasket: boolean = true;
  options: any= '';
  

  constructor(public dialogService: DialogService){
  }

  @Input()
  showDetails:any;

  @Input()
  enteredValue:any;

  @Input()
  fields: { [key in string]: string } = {};

  ngOnInit(): void {
    
  }

  async ngOnChanges(): Promise<void> {
    
  }
}


