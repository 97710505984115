import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
} from '@angular/router';
import { UserService } from '../api/user.service';
import { userGroups } from '../config/userGroups';


@Injectable({providedIn: 'root'})
export class RoleGuardService implements CanActivate {
  isInReviewGroup: boolean = false;

  constructor(public router: Router,
    private userService: UserService,
    ) { }
  async canActivate(): Promise<boolean> {
    const userMeta = await this.userService.getUser();
    this.isInReviewGroup = userGroups.some(r => userMeta.roles?.includes(r) ?? [].includes(r));
    if ( this.isInReviewGroup ) {
      return true;
    }
    this.router.navigate(['/'])
    return false;
  }
}
