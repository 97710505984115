import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class AuthorizationService {
  abstract register(email, password): Observable<any>;

  abstract confirmAuthCode(code): Observable<any>;

  abstract signIn(email, password): Observable<any>;

  abstract handleNewPassword(user: any, userAttributes: any, email: string, newPassword: string): Observable<'SUCCESS'>;

  abstract isLoggedIn(): boolean;

  abstract isLoggedInSubscribe(): Observable<boolean>;

  abstract getAuthenticatedUser(): any;

  abstract logOut(): void;

  // TODO: check if token refresh needed
  abstract getAccessToken(): Observable<string>;

  abstract initiateForgotPassword(email: string): Observable<any>;

  abstract handleForgotPassword(user: any, verificationCode: string, newPassword: string): Observable<boolean>;
}
