import { Component } from '@angular/core';
import { DialogConfig } from '../dialog/dialog-config';
import { DialogRef } from '../dialog/dialog-ref';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent {
  constructor(public config: DialogConfig, public dialog: DialogRef) {}

  onSubmit() {
    this.dialog.close('submit');
  }

  onCancel() {
    this.dialog.close('cancel');
  }
}
