import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { uniq } from 'lodash';
import { Observable } from 'rxjs';
import { mapProductToPrintable, PrintableProduct } from 'src/app/services/convert-product-to-printable.function';
import { ProductsBasketStoreService } from 'src/app/services/product-basket.store.service';
import { PRODUCT_PROPERTY_LABELS } from 'src/app/services/product-properties.service';
import { Product } from '../../../generated/graphql';
import { ProductsService } from '../../api/products.service';
import { DiagramProperties } from '../../components/product-scatter-plot/product-scatter-plot.component';
import { UserSettingsService } from './../../services/user-settings.service';

@Component({
  selector: 'app-compare-details',
  templateUrl: './compare-details.html',
  styleUrls: ['./compare-details.scss'],
})

export class CompareDetailsPageComponent implements OnInit {

  describingFields = ['supplier', 'name', 'type', 'subType']; // TODO: might remove from here

  showColumns: any = [...this.describingFields]; // TODO: remove config from here

  products: Array<PrintableProduct>;
  localSortedRows: Array<string> = this.userSettingsService.getProductDetailTableSorting() ?? [];

  basket$: Observable<Array<Product>>;

  fields: {[property: string]: string};

  keyFn = ProductsService.keyFn;

  compareDetailsGraphProperties: DiagramProperties = {
    diagramPer: null,
    x: null,
    y: null,
    size: null,
    color: 'supplier'
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userSettingsService: UserSettingsService,
    private productsService: ProductsService,
    private productsBasketStoreService: ProductsBasketStoreService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.basket$ = this.productsBasketStoreService.basket$;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const productIds = this.route.snapshot.queryParamMap.get('ids');
    const diagramPer = this.route.snapshot.queryParamMap.get('diagramPer');
    const x = this.route.snapshot.queryParamMap.get('x');
    const y = this.route.snapshot.queryParamMap.get('y');
    const size = this.route.snapshot.queryParamMap.get('size');
    const color = this.route.snapshot.queryParamMap.get('color');
    this.products = (await this.productsService.fetchByNames(JSON.parse(productIds))).map(mapProductToPrintable);
    this.fields = PRODUCT_PROPERTY_LABELS;
    const fields = [...new Set(
      this.products.map(Object.entries).flat()
        .filter(([, value]) => this.isEmpty(value))
        .map(([key]) => key)
    )];

    this.showColumns = [...this.describingFields, ...fields];

    if (this.localSortedRows) {
      const temp = [...this.localSortedRows, ...this.showColumns];
      this.showColumns = uniq(temp);
    }
    this.compareDetailsGraphProperties.diagramPer= diagramPer;
    this.compareDetailsGraphProperties.x= x;
    this.compareDetailsGraphProperties.y= y;
    this.compareDetailsGraphProperties.size= size;
    this.compareDetailsGraphProperties.color= color;
  }

  backLink(): void{
    this.router.navigate(['/']);
  }

  toggleEmptyFields(state: any, showEmptyFields: boolean): void {
    if (showEmptyFields) {
      const fields = [...new Set(
        this.products.map(Object.entries).flat()
          .filter(([, value]) => this.isEmpty(value))
          .map(([key]) => key)
      )];

      this.showColumns = [...this.describingFields, ...fields];
    } else {
      if (this.products) {
        this.showColumns = [...this.describingFields];
      }
    }
  }

  private isEmpty(value): boolean {
    return value === null || value === undefined || value === ""
  }
}
