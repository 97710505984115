import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthGuard } from 'src/app/services/auth.guard';
import { GtagService } from 'src/app/shared/gtag/gtag.service';
import { DialogService } from '../dialog/dialog.service';
import { HelpSectionComponent } from '../help-section/help-section.component';


@Component({
  selector: 'user-navigation',
  templateUrl: 'user-navigation.html',
  styleUrls: ['user-navigation.scss']
})

export class UserNavigationComponent implements OnInit{
  isLoggedIn$: Observable<boolean>;
  
  options:any = '';
  

  constructor(
    private gtag: GtagService,
    private authGuard: AuthGuard,
    public dialogService: DialogService ,
    
  ){}

  ngOnInit() {
    this.isLoggedIn$ = this.authGuard.isLoggedIn;
  }

  onGiveFeedback() {
    this.gtag.click('send-feedback')
  }

  openhelppageDialog() {
    this.gtag.click('book-mark');
    const ref = this.dialogService.open(HelpSectionComponent, {data: {options: this.options}});
    
  }
}
