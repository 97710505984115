import { Directive, ElementRef, Input, Output, EventEmitter, HostListener, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CheckboxGroup } from './checkbox-group.model';

@Directive({ selector: 'input[type="checkbox"][group]' })
export class CheckboxGroupDirective implements OnInit, OnDestroy {
  @Input()
  group: CheckboxGroup;

  constructor(private elem: ElementRef) {}

  set value(value: boolean) {
    this.elem.nativeElement.checked = value;
  }

  get value(): boolean {
    return this.elem.nativeElement.checked;
  }

  ngOnInit(): void {
    this.group.addCheckbox(this);
  }

  @HostListener('change', ['$event'])
  onChange(e): void {
    this.group.updateCheckboxValue(this);
  }

  ngOnDestroy(): void {
    this.group.removeCheckbox(this);
  }
}
