import { KeyValue } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MinMax } from 'covestro-ui-components/components/organisms/filter/filter.component';
import { ProductsService } from 'src/app/api/products.service';
import { ProductFilterInput } from 'src/generated/graphql';
import * as XLSX from 'xlsx'; 

@Component({
  selector: 'app-excel-preview',
  templateUrl: './excel-preview.component.html',
  styleUrls: ['./excel-preview.component.scss']
})
export class ExcelPreviewComponent  {

  constructor(private productsService: ProductsService){}
  
  @Input() data:any;
  All:any=[{
    "Base Type":"Text",
    "Supplier":"Text",
    "Product Name":"Text",
    "Type":"Text",
    "Product Sub-Type":"Text",
    
    "Primary Application":"Text",
    "% Solids":"Number",
    "Solvent":"Text",
    "Blocking Agent":"Text",
    "MFFT °C":"Number",
    "Activation Temperature °C":"Number",
    "Crystallization":"Text",
    "pH":"Number",
    "PES/PC/PET":"Text",
    "OH Number":"Number",
    "Acid Number":"Number",
    "Amine Number":"Number",
    "Tg °C":"Number",
    "Tg2 °C":"Number",
    "PAE Composition":"Text",
    "Typical Dispersion Viscosity":"Number",
    "Typical Viscosity mPas":"Number",
    "Viscosity mPas (midpoint)":"Number",
    "Viscosity Method":"Text",
    "Viscosity Range mPas (±)":"Number",
    "Viscosity Temp °C":"Number",
    "Equivalent Weight":"Number",
    "Maximum % Monomer":"Number",
    "NCO Range (±)":"Number",
    "% NCO (midpoint)":"Number",
    "Functionality":"Number",
    "Max Gardner Color":"Number",
    "Max Hazen APHA PtCo Color":"Number",
    "Max Iodine Color":"Number",
    "Mw by GPC":"Number",
    "PDI by GPC":"Number",
    "Particle Size":"Number",
    "100% Modulus Mpa":"Number",
    "Tensile Strength Mpa":"Number",
    "Elongation at Break":"Number",
    "Anionic/Cationic/Nonionic":"Text",
    "deblockTemperature  °C":"Number",
    "Melting Temp °C":"Number",
    "% COOH":"Number",
    "%OH":"Number",
    "Softening Point °C":"Number",
    "Previous Name":"Text",
    "Percent other Reactive Group":"Number",
    "Monomer Basis":"Text",
    "% Biocontent":"Number",
    "Chain of custody model":"Text",
    "Biodegradability":"Text",
    "Biodegradibility Method":"Text"
  }];
 
  
  Dispersion:any=[{
  "Base Type":"Dispersion",
  "Supplier":"Text",
  "Product Name":"Text",
  "Type":"Text",
  "Product Sub-Type":"Text",  
  "Primary Application":"Text",
  "% Solids":"Number",
  "Solvent":"Text",
  "Blocking Agent":"Text",
  "MFFT °C":"Number",
  "Activation Temperature °C":"Number",
  "Crystallization":"Text",
  "pH":"Number",
  "PES/PC/PET":"Text",
  "OH Number":"Number",
  "Acid Number":"Number",
  "Tg °C":"Number",
  "Tg2 °C":"Number",
  "Typical Dispersion Viscosity":"Number",
  "Viscosity mPas (midpoint)":"Number",
  "Viscosity Method":"Text",
  "Viscosity Range mPas (±)":"Number",
  "Equivalent Weight":"Number",
  "Maximum % Monomer":"Number",
  "Functionality":"Number",
  "Max Gardner Color":"Number",
  "Max Hazen APHA PtCo Color":"Number",
  "Max Iodine Color":"Number",
  "Mw by GPC":"Number",
  "PDI by GPC":"Number",
  "Particle Size":"Number",
  "100% Modulus Mpa":"Number",
  "Tensile Strength Mpa":"Number",
  "Elongation at Break":"Number",
  "Anionic/Cationic/Nonionic":"Text",
  "deblockTemperature °C":"Number",
  "Melting Temp °C":"Number",
  "% COOH":"Number",
  "%OH":"Number",
  "Softening Point °C":"Number",
  "Previous Name":"Text",
  "Monomer Basis":"Text",
  "Chain of custody model":"Text",
  "% Alternative Feedstock Content":"Number",
  "% Referring To Total Mass":"Boolean",
  "% Referring To Carbon Only":"Boolean",
  "Alternative Feedstock Type":"Text",
  "Year":"Text",
  "Including Biogenic Carbon":"Boolean",
  "Excluding Biogenic Carbon":"Boolean",
  "PCF in kg CO2/kg Product":"Number",
  "Biodegradability":"Text",
  "Biodegradibility Method":"Text"
  }];

  Coreactant:any =[{
    "Base Type":"Coreactant",
    "Supplier":"Text",
    "Product Name":"Text",
    "Type":"Text",
    "Product Sub-Type":"Text",    
    "Primary Application":"Text",
    "Equivalent Weight":"Number",
    "Functionality":"Number",
    "Max Gardner Color":"Number",
    "Max Hazen APHA PtCo Color":"Number",
    "Max Iodine Color":"Number",
    "Viscosity Method":"Text",
    "Viscosity mPaS (midpoint)":"Number",
    "Viscosity Range mPas (±)":"Number",
    "Viscosity Temp °C":"Number",
    "% Solids":"Number",
    "Solvent":"Text",
    "%OH":"Number",
    "OH Number":"Number",
    "Acid Number":"Number",
    "% COOH":"Number",
    "Previous Name":"Text",
    "Amine Number":"Number",
    "MFFT °C":"Number",
    "Softening Point °C":"Number",
    "Monomer Basis":"Text",
    "Tg °C":"Number",
    "Tg2 °C":"Number",
    "PAE Composition":"Text",
    "Chain of custody model":"Text",
    "% Alternative Feedstock Content":"Number",
    "% Referring To Total Mass":"Boolean",
    "% Referring To Carbon Only":"Boolean",
    "Alternative Feedstock Type":"Text",
    "Year":"Text",
    "Including Biogenic Carbon":"Boolean",
    "Excluding Biogenic Carbon":"Boolean",
    "PCF in kg CO2/kg Product":"Number",
    "Biodegradability":"Text",
    "Biodegradibility Method":"Text",
  }];

  Isocyanate:any= [{
    "Base Type":"Isocyanate",
    "Supplier":"Text",
    "Product Name":"Text",
    "Type":"Text",
    "Product Sub-Type":"Text",    
    "Primary Application":"Text",
    "Equivalent Weight":"Number",
    "Functionality":"Number",
    "Monomer Basis":"Text",
    "Max Gardner Color":"Number",
    "Max Hazen APHA PtCo Color":"Number",
    "Max Iodine Color":"Number",
    "Maximum % Monomer":"Number",
    "Viscosity mPas (midpoint)":"Number",
    "Viscosity Range mPas (±)":"Number",
    "Viscosity Temp °C":"Number",
    "% NCO (midpoint)":"Number",
    "NCO Range (±)":"Number",
    "% Solids":"Number",
    "Solvent":"Text",
    "Blocking Agent":"Text",
    "deblockTemperature °C":"Number",
    "Previous Name":"Text",
    "Chain of custody model":"Text",
    "% Alternative Feedstock Content":"Number",
    "% Referring To Total Mass":"Boolean",
    "% Referring To Carbon Only":"Boolean",
    "Alternative Feedstock Type":"Text",
    "Year":"Text",
    "Including Biogenic Carbon":"Boolean",
    "Excluding Biogenic Carbon":"Boolean",
    "PCF in kg CO2/kg Product":"Number",
    "Biodegradability":"Text",
    "Biodegradibility Method":"Text"
  }];
  
  NonreactiveResin:any=[{
    "Base Type":"NonreactiveResin",
    "Supplier":"Text",
    "Product Name":"Text",
    "Type":"Text",
    "Product Sub-Type":"Text",    
    "Primary Application":"Text",
    "Functionality":"Number",
    "Max Gardner Color":"Number",
    "Max Hazen APHA PtCo Color":"Number",
    "Viscosity mPas (midpoint)":"Number",
    "Viscosity Range mPas (±)":"Number",
    "Viscosity Method":"Text",
    "Typical Viscosity mPas":"Number",
    "% Solids":"Number",
    "Solvent":"Text",
    "Previous Name":"Text",
    "Percent other Reactive Group":"Number",
    "Maximum % Monomer":"Number",
    "Chain of custody model":"Text",
    "% Alternative Feedstock Content":"Number",
    "% Referring To Total Mass":"Boolean",
    "% Referring To Carbon Only":"Boolean",
    "Alternative Feedstock Type":"Text",
    "Year":"Text",
    "Including Biogenic Carbon":"Boolean",
    "Excluding Biogenic Carbon":"Boolean",
    "PCF in kg CO2/kg Product":"Number",
    "Biodegradability":"Text",
    "Biodegradibility Method":"Text"
  }];
  
  OtherReactiveSystems:any=[{
    "Base Type":"OtherReactiveSystems",
    "Supplier":"Text",
    "Product Name":"Text",
    "Type":"Text",
    "Product Sub-Type":"Text",    
    "Primary Application":"Text",
    "Functionality":"Number",
    "Max Gardner Color":"Number",
    "Max Hazen APHA PtCo Color":"Number",
    "Viscosity mPas (midpoint)":"Number",
    "Viscosity Range mPas (±)":"Number",
    "Viscosity Method":"Text",
    "Typical Viscosity mPas":"Number",
    "% Solids":"Number",
    "Solvent":"Text",
    "Previous Name":"Text",
    "Percent other Reactive Group":"Number",
    "Maximum % Monomer":"Number",
    "Chain of custody model":"Text",
    "% Alternative Feedstock Content":"Number",
    "% Referring To Total Mass":"Boolean",
    "% Referring To Carbon Only":"Boolean",
    "Alternative Feedstock Type":"Text",
    "Year":"Text",
    "Including Biogenic Carbon":"Boolean",
    "Excluding Biogenic Carbon":"Boolean",
    "PCF in kg CO2/kg Product":"Number",
    "Biodegradability":"Text",
    "Biodegradibility Method":"Text"
  }]; 
  
  EnergyCurableSolutions:any=[{
    "Base Type":"EnergyCurableSolutions",
    "Supplier":"Text",
    "Product Name":"Text",
    "Type":"Text",
    "Product Sub-Type":"Text",    
    "Primary Application":"Text",
    "Functionality":"Number",
    "Diluent":"Text",
    "Max Gardner Color":"Number",
    "Max Hazen APHA PtCo Color":"Number",
    "Viscosity mPas (midpoint)":"Number",
    "Acid Number":"Number",
    "Mw by GPC":"Number",
    "Tg °C":"Number",
    "Density":"Number",
    "% Alternative Feedstock Content":"Number",
    "% Referring To Total Mass":"Boolean",
    "% Referring To Carbon Only":"Boolean",
    "Alternative Feedstock Type":"Text",
    "Year":"Text",
    "Including Biogenic Carbon":"Boolean",
    "Excluding Biogenic Carbon":"Boolean",
    "PCF in kg CO2/kg Product":"Number",
  }];  
 
  
  type=[];
  subType= [];
  supplier=[];
  feedstockType=[];

  filterInput: Partial<ProductFilterInput>;
  filterInput_sub: Partial<ProductFilterInput>;

  originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
    return 0;
  }

  exportexcel(): void 
    {
      const data = {family: this.data, supplier: null, type: null, subType: null}
      this.onSelectFilter(data);
    }
  
  close(){
    this.data=null;
  }

  async onSelectFilter(val: Record<string, number | boolean | string | MinMax>, onInit: boolean = false) {
    this.filterInput = this.convertToFilterInput(val);
    const sheet2_data: any = await this.productsService.fetchFilterOptions(this.filterInput);

    if (sheet2_data.supplier && sheet2_data.supplier.length) {
      this.supplier = sheet2_data.supplier.map(supplier => [supplier]);
    }

    if (sheet2_data.feedstockType && sheet2_data.feedstockType.length) {
      this.feedstockType = sheet2_data.feedstockType.map(feedstockType => [feedstockType]);
    }

    if (sheet2_data.type && sheet2_data.type.length) {
      for (let i = 0; i < sheet2_data.type.length; i++) {
        if (sheet2_data.type[i] === 'not entered') sheet2_data.type.splice(i, 1);
        if (sheet2_data.subType && sheet2_data.subType.length) {
          if (sheet2_data.subType[i] === 'not entered') sheet2_data.subType.splice(i, 1);
        }
        this.type.push({ type: sheet2_data.type[i] });
      }
      for (let i = 0; i < this.type.length; i++) {
        if (this.type[i].type != null) {
          const data = { family: this.data, supplier: null, type: this.type[i].type, subType: null };
          this.filterInput_sub = this.convertToFilterInput(data);
          const sub = await this.productsService.fetchFilterOptions(this.filterInput_sub);
          this.subType.push({ type: this.type[i].type, subtype: sub.subType });
        }
      }
    }

    const filename = this.data +'.xlsx'
    const sheet2 = 'Type and SubType';
    const sheet3 = 'Supplier';
    const sheet4 = 'Alternative Feedstock Type'
    let element = document.getElementById('excel-table'); 
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element,{raw:true});
    const fixedData = this.subType.map(row => Object.fromEntries(Object.entries(row).map(([k,v]) => ([k, Array.isArray(v) ? v.join(",") : v]))))
    const supplier = this.supplier.map(row => Object.fromEntries(Object.entries(row).map(([k,v]) => ([k, Array.isArray(v) ? v.join(",") : v]))))
    const feedStock = this.feedstockType.map(row => Object.fromEntries(Object.entries(row).map(([k,v]) => ([k, Array.isArray(v) ? v.join(",") : v]))))
    const ws2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(fixedData)
    const ws3: XLSX.WorkSheet = XLSX.utils.json_to_sheet(supplier)
    const ws4: XLSX.WorkSheet = XLSX.utils.json_to_sheet(feedStock)
    ws3['A1'] ={t: 's', v: 'Supplier'}
    ws4['A1'] ={t: 's', v: 'Alternative Feedstock Type'}
    ws['!rows'] = [];
    ws['!rows'] = [{'hpt' : 30}];

    ws2['!cols'] = [];
    ws2['!rows'] = [];
        
    ws2['!cols'] = [{'width' : 20},{'width' : 120}]; 
    ws2['!rows'] = [{'hpt' : 30}]; 

    ws3['!cols'] = [];
    ws3['!rows'] = [];
        
    ws3['!cols'] = [{'width' : 20},{'width' : 120}]; 
    ws3['!rows'] = [{'hpt' : 30}];

    ws4['!cols'] = [];
    ws4['!rows'] = [];
        
    ws4['!cols'] = [{'width' : 20},{'width' : 120}]; 
    ws4['!rows'] = [{'hpt' : 30}];
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, this.data);
    XLSX.utils.book_append_sheet(wb, ws2, sheet2);
    XLSX.utils.book_append_sheet(wb, ws3, sheet3);
    XLSX.utils.book_append_sheet(wb, ws4, sheet4);
    XLSX.writeFile(wb, filename);
    this.subType =[];
    this.type = [];
    this.supplier=[];
    this.feedstockType=[];
  }
  
  private convertToFilterInput($event: Record<string, number | boolean | string | MinMax | Array<any>>): ProductFilterInput {
    const filterInputEntries = Object.entries($event)
      .filter(([key, value]) => value != null && ((typeof value === 'string' && value !== 'ALL') || ((value as MinMax).min != null || (value as MinMax).max != null) || typeof value === 'number' || typeof value === 'boolean' || (Array.isArray(value) && (value as Array<any>)?.length)))
      .map(([key, value]) => {
        if (key === 'hasBiocontent') {
          if (value && ($event['biocontent'] as MinMax)?.min == null && ($event['biocontent'] as MinMax)?.max == null) {
            return ['biocontent', {min: 0.000001}];
          } else {
            return null;
          }
        }
        if (typeof value === 'string') {
          if(value.length == 0){ return null;}
          else{
          return [key, {contains: value}];
          }
        }
        if (Array.isArray(value) && (value as Array<any>)?.length) {
          return [key, {in: value}];
        }
        if (typeof value === 'boolean') {
          return [key, {is: value}];
        }
        return [key, value];
      });
      filterInputEntries.push(['isDeleted', {is: false}]); // If "Archived/Deleted" products want to be filtered, remove this line
    return filterInputEntries.length ? Object.fromEntries(filterInputEntries.filter(e => e != null)) : null;
  }
}
