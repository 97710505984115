import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { GtagService } from '../../shared/gtag/gtag.service';

@Component({
  selector: 'app-products-search',
  templateUrl: './products-search.component.html',
  styleUrls: ['./products-search.component.scss']
})
export class ProductsSearchComponent implements OnInit, OnDestroy  {
  @Output() search = new EventEmitter();
  @Output() selectSearchTerm = new EventEmitter();
  searchControl: FormControl;
  searchSub: Subscription;

  constructor(
    private fb: FormBuilder,
    private gtag: GtagService
  ) { }

  ngOnDestroy(): void {
    this.searchSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.searchControl = this.fb.control('');

    this.searchSub = this.searchControl.valueChanges.pipe(
      distinctUntilChanged(),
      tap((value) => this.selectSearchTerm.emit(value))).subscribe();
  }


  onSearch() {
    this.gtag.search(this.searchControl.value)
    this.search.emit(this.searchControl.value);
  }

  onEnter() {
    this.onSearch();
  }

  reset() {
    this.searchControl.reset(null, { onlySelf: true, emitEvent: false });
  }
}
