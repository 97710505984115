import { Directive, ElementRef, Input } from '@angular/core';

@Directive({ selector: 'input[type="checkbox"][indeterminate]' })
export class CheckboxIndeterminateDirective {
   @Input()
   set indeterminate(value: boolean) {
     this.elem.nativeElement.indeterminate = value;
   }
    constructor(private elem: ElementRef) {}
}
