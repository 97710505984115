<section class="overlay">
  <div class="overlay-header">
    <span (click)="onClose()">
      <i class="fas fa-times"></i>
    </span>
  </div>

  <div class="overlay-form">
    <ng-container *ngIf="!successMessage">
      <h3 class="overlay-headline">{{headline}}</h3>
      <table>
        <tr>
          <td>
            <div *ngFor="let property of properties; let i = index" class="input">
              <dynamic-input [formGroup]="values" [type]="property.type" [name]="property.name" [label]="property.label"
                [options]="property.options" [required]="property.required" [default]="product?product[property.name]:null">
              </dynamic-input>
            </div>
          </td>
          <td></td>
        </tr>
      </table>
    </ng-container>

    <section *ngIf="errorMessages?.length" data-ui="warn">
      An error occured:
      <li *ngFor="let errorMessage of errorMessages">
        {{errorMessage}}
      </li>
    </section>

    <section *ngIf="successMessage" data-ui="accent2">
      {{successMessage}}
    </section>

  </div>
  <div *ngIf="!successMessage" class="overlay-actions">
    <div>
      <div>
        <button style="width: 100%" data-ui="small warn secondary" *ngIf="productEdit" (click)="requestRemoval()">Request product removal</button>
      </div>
      <div>
        <button style="width: 100%" data-ui="small" (click)="submit()">{{productEdit ? 'Submit Changes' : 'Submit'}}</button>
      </div>
    </div>
  </div>
</section>
