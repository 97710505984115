<ng-container [ngSwitch]="type" [formGroup]="formGroup">
  <div *ngSwitchCase="'number'" [formGroupName]="name" class="numeric">
    <div class="required"><span *ngIf="required">*</span></div>
    <div class="input-label">
      <label>{{label}}</label>
      <input [id]="name" [formControlName]="'value'" type="number" [attr.data-property-type]="'Float'">
    </div>
    <div class="not-measurable" *ngIf="!notApplicableFieldNotRequired.includes(label)">
      <input [id]="name + 'checkbox'" [formControlName]="'isNotApplicable'" type="checkbox">
      <label [for]="name + 'checkbox'"> not applicable</label>
    </div> 
  </div>

  <div *ngSwitchCase="'string'" class="wrapper">
    <div class="required"><span *ngIf="required">*</span></div>

    <div class="input-label">
      <label>{{label}}</label>
      <input [id]="name" [formControlName]="name" class="text" [attr.data-property-type]="'String'">
    </div>
  </div>

  <div *ngSwitchCase="'boolean'">
    <input [id]="name + 'checkbox'" [formControlName]="name" type="checkbox" (change)="onCheckChange($event)">
    <label [for]="name + 'checkbox'">{{label}} </label>
  </div>

  <div *ngSwitchCase="'datalist'" class="wrapper">
    <div class="required"><span *ngIf="required">*</span></div>

    <div class="datalistWrapper">
      <label>{{label}}</label>
      <input type="text" [attr.list]="'data_' + name" [id]="name + '_id'" [name]="name" [formControlName]="name">
      <datalist [id]="'data_' + name">
        <option *ngFor="let opt of options | keyvalue" [value]="opt.value"></option>
      </datalist>
    </div>
  </div>

  <div *ngSwitchCase="'enum'" class="wrapper">
    <div class="required"><span *ngIf="required">*</span></div>
    <div class="customSelect">
      <label>{{label}}</label>
      <select [id]="name" [attr.data-property-type]="'ENUM'" [formControlName]="name">
        <option [ngValue]="null" [disabled]="required" [selected]="required" [hidden]="required"></option>
        <option *ngFor="let opt of options | keyvalue" [value]="opt.key">
          {{opt.value}}</option>
      </select>
    </div>
  </div>
  <div *ngSwitchCase="'array'" class="wrapper">
    <div class="required"><span *ngIf="required">*</span></div>
    <div class="customSelect">
      <label>{{label}}</label>
      <select [id]="name" [attr.data-property-type]="'ENUM'" [formControlName]="name">
         <option *ngFor="let opt of options | keyvalue" [value]="opt.value">
          {{opt.value}}</option>
      </select>
    </div>
  </div>
</ng-container>
