import { Component, Input, Output, EventEmitter, TemplateRef, OnChanges, SimpleChanges, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { isEqual } from 'lodash';
import { CheckboxGroup } from '../../atoms/checkbox/checkbox-group.model';

export const LAZY_LOAD_WRAPPER = {} as any;
export const LOADING_WRAPPER = {} as any;

@Component({
  selector: 'app-log-table',
  templateUrl: './log-table.component.html',
  styleUrls: ['./log-table.component.scss']
})
export class LogTableComponent<T> implements OnChanges {
  iconVisible: boolean;

  @Input('data')
  set data(data: Array<T>) {
    if (data.length > 0) {
      this._data = data;
      this.noData = false;
    } else {
      this.noData = true;
      this._data = [];
    }
  }

  get data(): Array<T> {
    return this._data;
  }

  @Input()
  clickEvents: { [columnName: string]: (item: T) => void } = {};

  private _data: Array<T>;
  noData: boolean = false;

  @Input()
  showActionsTemplate: TemplateRef<any>;

  @Input()
  selectActionsTemplate: TemplateRef<any>;

  @Input()
  totalNumberTemplate: TemplateRef<any>;

  @Input()
  columnTemplate: TemplateRef<any>;

  @Input()
  showFields:any;

  @Input()
  hasBiocontent:boolean;
  
  @Input()
  selected: Set<T> = new Set();

  @Output()
  selectedChange = new EventEmitter<Set<T>>();

  @Input()
  keyFn: (item: T) => string | number;

  @Input()
  fields: { [key in string]: string } = {};

  @Input()
  state: SortableTableState<T>;

  @Output()
  activeSelectValues = new EventEmitter<Array<string>>();

  @Output()
  selectedRow = new EventEmitter<Array<any>>();

  @Output()
  stateChange = new EventEmitter<SortableTableState<T>>();

  @Output()
  loadItems = new EventEmitter<Array<number>>();

  sortPositionAndDirectionByColumns: SortOrder = {};

  selectedRows = {};

  productCheckboxGroup = new CheckboxGroup();

  get columns(): Array<string> {
    return Object.keys(this.fields);
  };

  LAZY_LOAD_WRAPPER = LAZY_LOAD_WRAPPER;
  LOADING_WRAPPER = LOADING_WRAPPER;

  @ViewChild('scrollableWrapper')
  scrollableWrapper: ElementRef;

  @ViewChildren('lazyLoadElement')
  lazyLoadElements:QueryList<ElementRef>;

  onScroll($event): void {
    const loadItems = this.lazyLoadElements
      ?.map(el => this.isVisible(el.nativeElement)? Number(el.nativeElement.getAttribute("data-index")) : null)
      .filter(i => i != null && this._data[i] !== LOADING_WRAPPER);
    if (loadItems?.length > 0) {
      loadItems.forEach(i => this._data[i] = LOADING_WRAPPER);
      this.loadItems.emit(loadItems);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.state) {
      const state = changes.state.currentValue as SortableTableState<T>;
      if (state && !isEqual(state, changes.state.previousValue)) {
        if(this.hasBiocontent){
        } 
        if(!this.hasBiocontent){
        }
        this.sortPositionAndDirectionByColumns = state.sortOrder;
        this.performSort();
      }
    }
    if (changes.selected) {
      const selected = changes.selected.currentValue as Set<T>;
      if (selected) {
        this.selectedRows = {};
        selected.forEach(entry =>
          this.selectedRows[this.keyFn(entry)] = true);
      }
    }
  }

  showFieldsChanged(fields: Array<string>): void {
    this.stateChanged();
  }

  selectOrDeselectAll(selected: boolean): void {
    if (selected) {
      this.selected = new Set(this._data);
    } else {
      this.selected = new Set();
    }
    this.selectedRows = {};
    this.selected.forEach(entry => this.selectedRows[this.keyFn(entry)] = true);
  }

  setSelectedChanged(row: T, $event): void {
    this.selectedRows[this.keyFn(row)] = $event.target.checked;
    if (!this.data.some(d => d === row)) {
      this.selectedRows[this.keyFn(row)] = undefined;
    }
    this.updateSelected();
  }

  private updateSelected(): void {
    let returnSelectedRows = new Set<T>();

    Object.entries(this.selectedRows)
      .filter(([key, value]) => value)
      .map(([key]) => {
        this._data.map((entry) => {
          if (this.keyFn(entry) === key) {
            returnSelectedRows.add(entry);
          }
        })
      });

    this.selected = returnSelectedRows;
  }

  toggleSortForColumn(column: string): void {
    const columnSort = this.sortPositionAndDirectionByColumns[column];
    if (!columnSort) {
      this.sortPositionAndDirectionByColumns[column] = {
        index: Object.keys(this.sortPositionAndDirectionByColumns).length,
        direction: 'ASC',
      };
    } else if (columnSort.direction === 'ASC') {
      columnSort.direction = 'DESC';
    } else {
      delete this.sortPositionAndDirectionByColumns[column];
    }
    this.performSort();
    this.stateChanged();
  }

  // TODO: improve sort by returning consistent result when comparing different types
  performSort(): void {
    if (!this._data.some(d => d === LAZY_LOAD_WRAPPER || d === LOADING_WRAPPER)) {
      const sortByOrder =
        Object.entries(this.sortPositionAndDirectionByColumns).sort((a, b) => a[1].index - b[1].index);

      this._data.sort((a, b) => {
        for (let compareByColumn of sortByOrder) {
          if (a[compareByColumn[0]] != b[compareByColumn[0]]) {

            // null & undefined shall be at bottom on ASC as the backend will also do
            if (a[compareByColumn[0]] == null) {
              return compareByColumn[1].direction === 'ASC' ? 1 : -1;
            }
            if (b[compareByColumn[0]] == null) {
              return compareByColumn[1].direction === 'ASC' ? -1 : 1;
            }

            if (a[compareByColumn[0]] < b[compareByColumn[0]]) {
              if (compareByColumn[1].direction === 'ASC') {
                return -1;
              } else {
                return 1;
              }
            } else {
              if (compareByColumn[1].direction === 'ASC') {
                return 1;
              } else {
                return -1;
              }
            }
          }
        }
        return 0;
      });
    }
  }

  stateChanged(): void {
    this.stateChange.emit({
      sortOrder: this.sortPositionAndDirectionByColumns,
      showFields: this.showFields,
    });
  }

  tableRowIdentity(index, item): string | number {
    if (this.keyFn) {
      return this.keyFn(item);
    }
    return item;
  }

  isVisible(element: Element): boolean {
    const { bottom, height, top } = element.getBoundingClientRect();
    const containerRect = this.scrollableWrapper.nativeElement.getBoundingClientRect();

    return top <= containerRect.top
        ? (containerRect.top - top <= height)
        : (bottom - containerRect.bottom <= height);
  };

  mouseEnter(row:any) {
   this.iconVisible = true;
  }
  
  mouseLeave(row:any) {
    this.iconVisible = false;
  }

  showDetails(row:any){
    this.selectedRow.emit(row);
  }
}



type SortOrder = { [column: string]: { index: number, direction: 'ASC' | 'DESC' } };

export interface SortableTableState<T> {
  sortOrder: SortOrder;
  showFields: Array<string>;
}
