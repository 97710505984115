<div class="comparision-interactions">
  <multi-dropdown [options]="columns" [selected]="showFields" [fields]="fields"
    (selectedChange)="showFieldsChanged($event)" dataUi="primary secondary" label="Show columns">
  </multi-dropdown>
  <div class="multi-dropdown-interaction-block">
    <ng-container
      *ngTemplateOutlet="showActionsTemplate; context: { state: state, selected: selected, total: data.length }">
    </ng-container>
  </div>
</div>
<div *ngIf="noData" class="warning">
  Sorry, but we can't find any results for your search parameters.
</div>
<!-- TODO: https://github.com/akeresztesgh/angular-double-scrollbars -->
<div class="wrapper scrollable-table-wrapper">
  <div class="loaderWrapper" *ngIf="downloadAsExcelLoading">
    <p class="msg">Downloading is in progress. <br> Please wait..</p>
    <span class="loader"></span>
  </div>
  <div class="table-window" id="scrollableTableWindow" #scrollableWrapper (scroll)="onScroll($event)">
    <div class="action-bar">
      <div class="actions">
        <input class="select-all" type="checkbox" (click)="selectOrDeselectAll($event.target.checked)" [groupLead]="productCheckboxGroup">
      </div>
      <span class="select-actions">
        <ng-container *ngTemplateOutlet="selectActionsTemplate; context: { state: state, selected: selected }">
        </ng-container>
      </span>
      <ng-container *ngTemplateOutlet="totalNumberTemplate; context: { total: data.length }"></ng-container>
    </div>
    <table id="productsTableId">
      <thead>
        <tr>
          <th class="select"></th>
          <th *ngFor="let col of showFields" (click)="toggleSortForColumn(col)" style="cursor: pointer;">
            {{ fields ? fields[col] : col }}&nbsp;
            <i class="fas fa-sort" *ngIf="!sortPositionAndDirectionByColumns[col]; else sorted"></i>
            <ng-template #sorted>
              <i class="fas fa-sort-up" *ngIf="sortPositionAndDirectionByColumns[col].direction === 'ASC'"></i>
              <i class="fas fa-sort-down" *ngIf="sortPositionAndDirectionByColumns[col].direction === 'DESC'"></i>
              <sup class="sort-order">&nbsp;{{ sortPositionAndDirectionByColumns[col].index + 1 }}</sup>
            </ng-template>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="data">
        <tr *ngFor="let row of data; let i = index; trackBy: tableRowIdentity">
          <ng-container *ngIf="row !== LAZY_LOAD_WRAPPER && row !== LOADING_WRAPPER">
            <td><input type="checkbox" [checked]="selectedRows[keyFn(row)]" (click)="setSelectedChanged(row, $event)" [group]="productCheckboxGroup"></td>
            <td *ngFor="let col of showFields">
              <span (click)="(clickEvents && clickEvents[col]) ? clickEvents[col](row) : null"
                [class.clickable]="clickEvents && clickEvents[col]">
                <ng-container *ngIf="columnTemplate; else defaultPrint">
                  <ng-container *ngTemplateOutlet="columnTemplate; context: {$implicit: row[col], column: col, row: row}">
                  </ng-container>
                </ng-container>
              </span>

              <ng-template #defaultPrint>
                <ng-container *ngIf="row[col] === true; then truePrint;
                    else (row[col] === false && falsePrint)
                      || simplePrint">
                </ng-container>
              </ng-template>
              <ng-template #simplePrint>{{ row[col] }}</ng-template>
            </td>
          </ng-container>
          <ng-container *ngIf="row === LAZY_LOAD_WRAPPER">
            <td><span class="lazy" #lazyLoadElement [attr.data-index]="i"></span></td>
          </ng-container>
          <ng-container *ngIf="row === LOADING_WRAPPER">
            <td colspan="2"><i class="fas fa-spinner"></i></td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #truePrint><i class="fas fa-check"></i></ng-template>
<ng-template #falsePrint><i class="fas fa-times"></i></ng-template>
