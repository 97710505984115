import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { hasChanged } from '../../../helpers/ngHasChanged';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';


@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  properties: Array<FilterProperty>;

  values: FormGroup = new FormGroup({});

  @Output()
  filterChange = new EventEmitter<Record<string, number | boolean | string | MinMax>>();

  constructor(
  ) { }

  private subscription: Subscription;

  ngAfterViewInit() {
    this.subscription = this.values.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(800),
      )
      .subscribe(val => this.filterChange.emit(val))
      
  }

  ngOnChanges(changes: SimpleChanges) {
    if (hasChanged(changes.properties)) {
      const currentKeys = Object.keys(this.values.controls);
      const targetMap = Object.fromEntries(this.properties.map(prop => [prop.name, prop]));
      currentKeys.filter(key => !targetMap.hasOwnProperty(key))
        .forEach(key => this.values.removeControl(key));
      Object.entries(targetMap).filter(([key, property]) => !currentKeys.includes(key))
        .forEach(([key, property]) => {
          if (this.values.get(key) && this.values.get(key).disabled) {
            this.values.get(key).enable();
          }
        });
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  reset() {
    this.values.reset({}, { onlySelf: true, emitEvent: true });
  }
}

export interface FilterProperty {
  type: 'number' | 'boolean' | 'enum' | 'multi-enum' | 'string' | 'datalist';
  name: string;
  label: string;
  required?: boolean;
  default?: any;
  options?:any;
}

export interface DropdownFilterProperty extends FilterProperty {
  type: 'enum' | 'multi-enum' | 'datalist';
  options: Record<string, string>;
  // TODO: for mutli-enum display label function could be passed
}

export interface NumberRangeFilterProperty extends FilterProperty {
  type: 'number';
  allowedRange: MinMax;
}

export type FilterPropertyType = FilterProperty | DropdownFilterProperty | NumberRangeFilterProperty;

export type MinMax = { min?: number, max?: number };

