import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { expand, map, mergeMap, switchMap, toArray } from 'rxjs/operators';
import { createProductsChangeRequestGQL, ProductHistoryLogsDataGQL, deleteProductGQL, filterOptionsGQL, getOpenChangeRequestsGQL, ModifiedChangeRequestInput, PossibleProductFilters, Product, ProductFilterInput, ProductInput, productsGQL, responseProductsChangeRequestGQL, SortBy, ProductHistoryLogsData, ProductHistoryLogsDataWithTotal } from '../../generated/graphql';
import { chunkedRequest } from '../services/chunked-request.service';
import { PrintableProduct } from '../services/convert-product-to-printable.function';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  constructor (
    private productsGQL: productsGQL,
    private filterOptionsGQL: filterOptionsGQL,
    private createProductsChangeRequestGQL: createProductsChangeRequestGQL,
    private getOpenChangeRequestsGQL: getOpenChangeRequestsGQL,
    private responseProductsChangeRequestGQL: responseProductsChangeRequestGQL,
    private deleteProductGQL: deleteProductGQL,
    private ProductHistoryLogsDataGQL: ProductHistoryLogsDataGQL,
  ) { }

  static readonly keyFn = (product: Product | PrintableProduct) => `${product.name}`;

  fetchFilterOptions = async (filter: Partial<ProductFilterInput>) =>
    this.filterOptionsGQL.fetch({ filter })
      .pipe(
        map(response => response.data?.getPossibleProductFilters)
      )
      .toPromise() as Promise<PossibleProductFilters>;

  fetchByNames = async (productNames: Array<string>) => chunkedRequest(
    productNames,
    100,
    chunkedProductNames => this.productsGQL.fetch({ filter: { name: { in: chunkedProductNames} } }).pipe(
        map(result => result.data && result.data.getProducts),
        mergeMap((products) => <Product[]>products),
        toArray())
      .toPromise() as Promise<Array<Product>>
    );

  fetchByFilter = async (
    filter: Partial<ProductFilterInput>,
    sortBy: Array<SortBy> = null,
    offset: number = 0,
    limit: number = 20
  ) =>
    this.productsGQL.fetch({ filter, limit, offset, sortBy })
      .pipe(
        map(products => products.data.getProducts)
      )
      .toPromise() as Promise<Array<Product>>;

  fetchAllByFilter = (filter: Partial<ProductFilterInput>) => this.fetchPagedProducts(filter)
    .pipe(
      expand((page, index) => page.length === 20 ? this.fetchPagedProducts(filter, (index + 1) * 20) : []), // TODO: test
      mergeMap((page) => page),
      toArray())
    .toPromise() as Promise<Array<Product>>

  private fetchPagedProducts = (filter: Partial<ProductFilterInput>, offset: number = null, limit: number = 20): Observable<Array<Product>> =>
    this.productsGQL.fetch({ filter: filter, limit: limit, offset: offset })
      .pipe(
        switchMap((response) => response.errors ?
          throwError(response.errors) :
          of(response.data.getProducts)));
  
  fetchByFilterHistory = async (
   search:string,
   sortBy: Array<SortBy> = null,
   offset: number = 0,
   limit: number = 20
      ) =>
      this.ProductHistoryLogsDataGQL.fetch({ limit, sortBy, offset, search })
        .pipe(
          map(products => products.data.getProductHistoryLogs)
           )
          .toPromise();


  createProductsChangeRequest = (products: Array<ProductInput>) =>
    this.createProductsChangeRequestGQL.mutate({products}).toPromise();

  fetchProductsChangeRequests = (page: number = 1) =>
    this.getOpenChangeRequestsGQL.fetch({page}).pipe(map(r => r.data.getChangeRequests)).toPromise();

  respondProductChangeRequests = (accepted?: Array<number>, rejected?: Array<number>, modified?: Array<ModifiedChangeRequestInput>) =>
    this.responseProductsChangeRequestGQL.mutate({accepted, modified, rejected}).pipe(map(r => r.data.responseProductsChangeRequest)).toPromise();

  deleteProduct = (product: string) =>
    this.deleteProductGQL.mutate({product}).toPromise();
}
