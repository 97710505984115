<section data-ui="content">
  <div class="wrapper">
    <div class="table-window" #scrollableWrapper (scroll)="onScroll($event)">
      <div class="action-bar">
        <span class="select-actions">
          {{data.length}} products found
        </span>
      </div>
      <table>
        <thead>
          <tr>
             <th *ngFor="let col of column" (click)="toggleSortForColumn(col)" style="cursor: pointer;">
              {{col | missinreplaceNullWithText}}&nbsp;
              <i class="fas fa-sort" *ngIf="!sortPositionAndDirectionByColumns[col]; else sorted"></i>
              <ng-template #sorted>
                <i class="fas fa-sort-up" *ngIf="sortPositionAndDirectionByColumns[col].direction === 'ASC'"></i>
                <i class="fas fa-sort-down" *ngIf="sortPositionAndDirectionByColumns[col].direction === 'DESC'"></i>
                <sup class="sort-order">&nbsp;{{ sortPositionAndDirectionByColumns[col].index + 1 }}</sup>
              </ng-template>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="data">
          <tr *ngFor="let row of data; let i = index; trackBy: tableRowIdentity">
            <ng-container *ngIf="row !== LAZY_LOAD_WRAPPER && row !== LOADING_WRAPPER">
              <!-- <td><input type="checkbox" [checked]="selectedRows[keyFn(row)]" (click)="setSelectedChanged(row, $event)" [group]="productCheckboxGroup"></td> -->
              <td ><a (click)= "editproduct(row)">{{row.name}}</a></td>
              <td>{{row.family}}</td>
              <td>{{row.supplier}}</td>
              <td>{{row.type}}</td>
              <td>{{row[xaxis] | missinreplaceNullWithText : 'not entered'}}</td>
              <td>{{row[yaxis]  | missinreplaceNullWithText : 'not entered'}}</td>
            </ng-container>
            <ng-container *ngIf="row === LAZY_LOAD_WRAPPER">
              <td><span class="lazy" #lazyLoadElement [attr.data-index]="i"></span></td>
            </ng-container>
            <ng-container *ngIf="row === LOADING_WRAPPER">
              <td colspan="2"><i class="fas fa-spinner"></i></td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <ng-template #truePrint><i class="fas fa-check"></i></ng-template>
  <ng-template #falsePrint><i class="fas fa-times"></i></ng-template>
  </section>