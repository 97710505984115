import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'search-block',
    templateUrl: 'search.component.html',
    styleUrls: ['search.component.scss']
})

export class SearchComponent {

  @Input()
  searchPhrase: string;

  @Output()
  returnSearchPhrase = new EventEmitter();

  submitSearch(): void {
    this.returnSearchPhrase.emit(this.searchPhrase)
  }

}



