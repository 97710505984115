import { Component, OnDestroy, OnInit } from '@angular/core';
import { OverlayCreatorService } from 'covestro-ui-components/components/molecules/overlay-creator.service';
import { LOADING_WRAPPER, SortableTableState } from 'covestro-ui-components/components/organisms/sortable-table/sortable-table.component';
import { isEmpty, sortBy } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { GtagService } from 'src/app/shared/gtag/gtag.service';
import { Product} from 'src/generated/graphql';
import { LAZY_LOAD_WRAPPER } from '../../../../../components/components/organisms/sortable-table/sortable-table.component';
import { SortBy, SortDirection } from '../../../generated/graphql';
import { ProductsService } from '../../api/products.service';
import { ProductDetailsComponent } from '../../components/product-details/product-details.component';
import { DiagramProperties } from '../../components/product-scatter-plot/product-scatter-plot.component';
import { ProductsBasketStoreService } from '../../services/product-basket.store.service';
import { PRODUCT_PROPERTY_LABELS } from '../../services/product-properties.service';
import {DialogService } from '../../components/dialog/dialog.service';
import { ProductEditComponent } from 'src/app/components/product-edit/product-edits.component';

@Component({
  selector: 'app-log-history',
  templateUrl: './log-history.component.html',
  styleUrls: ['./log-history.component.scss']
})
export class LogHistoryComponent implements OnInit, OnDestroy {
  products: any;
  showDetails:any =null;
  enteredValue:any;
  basket$: Observable<Array<Product>>;
  loading: boolean;
  hasBiocontent:boolean;
  allProductsLoading: boolean;
  numberLoadedProducts: number;
  searchResulstSub: Subscription;
  showSearchResult: boolean = false;
  showDetailsAll:boolean= false;
  selectedTab: number;
  showFields:any=['productName','createdBy','createdAt','state'];

  fields: any;

  private selectedFamily: string = 'ALL';

  private sortOrder: Array<SortBy> = this.convertSortOrder(this.settings.resultTableState);

  private totalProducts = 0;
  private filterInput: any = "";
  options: any;

  constructor(
    private overlayCreatorService: OverlayCreatorService,
    private productsService: ProductsService,
    private productsBasketStoreService: ProductsBasketStoreService,
    public settings: UserSettingsService,
    private gtag: GtagService,
    public dialogService: DialogService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.onFilterSelected(this.filterInput);
    this.basket$ = this.productsBasketStoreService.basket$;
    this.fields = PRODUCT_PROPERTY_LABELS;
    this.selectedTab = this.settings.getTab();
    this.loadAllProducts();
  }

  ngOnChanges(): void {
    this.selectedTab = this.settings.getTab();
  }

  onSetTab($event) {
    this.settings.setTab($event);
    this.selectedTab = $event;
  }

  preloadTab = (selected: number) => {
   this.loadAllProducts(); 
  };

  keyFn = ProductsService.keyFn;

  tableCellClickEvents = { name: (product: Product) => {
    const filterInput:any = Object.assign({}, null);
    const options = this.options;
    this.overlayCreatorService.open(ProductDetailsComponent, { product, options });
   } 
 };

  async loadProducts($event:any) {
    this.filterInput = $event;
    this.loading = true;
    this.showSearchResult = true;
    this.products = (await this.productsService.fetchByFilterHistory(this.filterInput));
    if(this.products){
      this.totalProducts = this.products.totalRequest;
      this.numberLoadedProducts = this.products.logsData.length;
    }
    while (this.products.logsData.length < this.totalProducts) {
      this.products.logsData.push(LAZY_LOAD_WRAPPER);
    }
    this.loading = false;
  }


  async loadAllProducts(): Promise<Array<any>> {
    if (this.products?.logsData.some(p => p === LAZY_LOAD_WRAPPER)) {
      this.allProductsLoading = true;
      await this.loadTableItems(
        [this.products.logsData.indexOf(LAZY_LOAD_WRAPPER), this.products.logsData.lastIndexOf(LAZY_LOAD_WRAPPER)]
      );
      this.allProductsLoading = false;
    }
    return this.products;
  }

  async onFilterSelected($event: any) {
    this.filterInput = $event;
    if (!this.products?.length) {
      this.loadProducts(this.filterInput);
    }
  }

  async onSearch($event: string) {
    const filterInput = $event
    await this.loadProducts(filterInput);
  }

  onClearAllFilters($event) {
    this.filterInput = null;
  }

  addToBasket(products: Set<Product>): void {
    let productsArray = Array.from(products)
    productsArray = productsArray.filter(product => !isEmpty(product))
    this.productsBasketStoreService.addBulkToBasket(new Set(productsArray));
  }

  removeSelections(products: Set<Product>): void {
    const toRemove = new Set([...products].map(product => this.keyFn(product)));
    this.products = this.products.filter(product => !toRemove.has(this.keyFn(product)));
  }

  onColoumnsChange($event) {
   const temp = $event.map(value => `${value},` )
  }

  toggleEmptyFields(state: SortableTableState<Product>, showEmptyFields: boolean): void {
    if (showEmptyFields) {
      const fields = [...new Set(
        this.products.map(Object.entries).flat()
          .filter(([, value]) => this.isEmpty(value))
          .map(([key]) => key)
      )];

      this.showFields = fields;
    } else {
      if (this.products) {
        this.showFields = ['productName','createdBy','createdAt','state'];
      }
    }
  }

  async onTableStateChanged(state: SortableTableState<any>): Promise<void> {
    this.settings.resultTableStateLog = state;
    const sortOrder = this.convertSortOrder(state);
    if (JSON.stringify(sortOrder) != JSON.stringify(this.sortOrder)) {
      this.sortOrder = sortOrder;
      this.totalProducts = this.products.totalRequest;
      if (this.totalProducts > this.products.logsData.filter(p => p !== LOADING_WRAPPER && p !== LAZY_LOAD_WRAPPER).length) {
        await this.loadProducts(this.filterInput);
      }
    }
  }

  private convertSortOrder(state: SortableTableState<Product>) {
    
    if (state == null || state.sortOrder == null) {
      return null;
    }
    return sortBy(Object.entries(state.sortOrder), ([property, { index, direction }]) => index)
      .map(([property, { index, direction }]) => ({ property, direction: direction as SortDirection }));
  }

  onTotalAvailableChange(total: number) {
    this.totalProducts = total;
  }

  async loadTableItems(itemPositions: Array<number>) {
    const min = Math.min(...itemPositions);
    const max = Math.max(...itemPositions);

    for (let i = min; i<= max; i++) {
      this.products.logsData[i] = LOADING_WRAPPER;
    }

    for (let i = min; i <= max; i = i + 20) { 
      const products = (await this.productsService.fetchByFilterHistory(this.filterInput, this.sortOrder, i, 20))
      if(products){
      this.numberLoadedProducts += products.logsData.length;
      this.products.logsData.splice(i, Math.min(20, this.products.logsData.length - i), ...(products.logsData));
      }
    }
  }

  get graphProperties(): DiagramProperties {
    return this.settings.graphProperties ? this.settings.graphProperties[this.selectedFamily] : null;
  }

  set graphProperties(graphProperties: DiagramProperties) {
    this.settings.graphProperties = Object.assign({}, this.settings.graphProperties ?? {}, {[this.selectedFamily]: graphProperties});
  }

  addNewProduct() {
   const ref = this.dialogService.open(ProductEditComponent, {data: {options: this.options}} );
  };

  private isEmpty(value): boolean {
    return value === null || value === undefined || value === ""
  }

  ngOnDestroy() {
    this.searchResulstSub?.unsubscribe();
  }

  async selectedRow($event){
    this.enteredValue = $event;
    const productDetails :any = { name: {equals: $event.productName}};
    this.showDetails = await this.productsService.fetchByFilter(productDetails);
    
  }
}