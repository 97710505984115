import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'logTable'
})
export class LogTablePipe implements PipeTransform {

  transform(value: any, repleceText: string = 'N/A'): any {
    if (typeof value === 'undefined' || value === null) {
      return repleceText;
    }
    if (value === 'productName') {
      return 'Product Name';
    }
    if (value === 'createdBy') {
      return 'Created By';
    }
    if (value === 'createdAt') {
      return 'Date & Time';
    }
    if (value === 'state') {
      return 'Status';
    }

    return value;
  }
}
