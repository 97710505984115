import { DropdownFilterProperty, FilterProperty } from '../../../../components/components/organisms/filter/filter.component';
import { Scalars } from '../../generated/graphql';
import * as types from '../../generated/graphql.json';
import { ENUMS } from './enums-extractor.service';
import { FILTER_ORDERS_PER_FAMILY } from '../config/filter-order';
import { PROPERTY_ORDERS_PER_FAMILY } from '../config/property-order';

export const PRODUCT_PROPERTY_SORTED_LABEL_PAIRS = readProductPropertyLabels();
export const PRODUCT_PROPERTY_LABELS = Object.fromEntries(PRODUCT_PROPERTY_SORTED_LABEL_PAIRS);
export const PRODUCT_PROPERTIES_FOR_LABELS = Object.fromEntries(PRODUCT_PROPERTY_SORTED_LABEL_PAIRS.map(([prop, label]) => [label, prop]));

export const PRODUCT_PROPERTIES_BY_FAMILY = readProductPropertiesForFamilies(PROPERTY_ORDERS_PER_FAMILY);
export const PRODUCT_FILTER_PROPERTIES_BY_FAMILY = readProductPropertiesForFamilies(FILTER_ORDERS_PER_FAMILY);
export const PRODUCT_MINIMAL_PROPERTIES = readProductMinimalProperties();

export const PRODUCT_PROPERTY_LABELS_BY_FAMILY = Object.fromEntries(Object.entries(PRODUCT_PROPERTIES_BY_FAMILY)
  .map(([family, properties]) => [family, Object.fromEntries(properties.filter(prop => prop).map(prop => [prop.name, prop.label]))]));

function readProductPropertyLabels(): Array<[string, string]> {
  return types.__schema.types
    .filter(type => type.name === 'Product')[0]
    .possibleTypes
    .map(type => type.name)
    .map(productType => types.__schema.types.filter(t => t.name === productType)[0])
    .flatMap(type => type.fields.map(field => [field.name, field.description]))
    .sort(([, a], [, b]) => a.toLowerCase().localeCompare(b.toLowerCase()));
}

function readProductMinimalProperties(): Array<FilterProperty> {
  return types.__schema.types
    .filter(type => type.name === 'MinimalProduct')[0]
    .fields
    .filter((field: GraphQLField) => FILTER_ORDERS_PER_FAMILY['MinimalProduct'].includes(field.name))
    .map(field => mapToFilterProperty(field))
    .sort((a, b) => FILTER_ORDERS_PER_FAMILY['MinimalProduct'].indexOf(a.name) - FILTER_ORDERS_PER_FAMILY['MinimalProduct'].indexOf(b.name));
}

function readProductPropertiesForFamilies(ordersByFamily: Record<string, Array<string>>): Record<string, Array<FilterProperty>> {
  return Object.fromEntries(types.__schema.types
    .filter(type => type.name === 'Product')[0]
    .possibleTypes
    .map(family => family.name)
    .map(family => types.__schema.types
    .filter(type => type.name === family)[0])
    .map(family => [family.name, [
      ...family.fields
        .filter((field: GraphQLField) => ordersByFamily[family.name].includes(field.name))
        .map(field => mapToFilterProperty(field)),
      {
        name: 'hasBiocontent',
        label: 'Has Alternative Feedstock Content',
        type: 'boolean',
        required: false
      }
    ].sort((a, b) => ordersByFamily[family.name].indexOf(a.name) - ordersByFamily[family.name].indexOf(b.name))
  ]));
}

type GraphQLNotNullType = { kind: 'NON_NULL', ofType: GraphQLType };
type GraphQLScalarType = { kind: 'SCALAR', name: keyof Scalars };
type GraphQLEnumType = { kind: 'ENUM', name: string };
type GraphQLType = GraphQLNotNullType | GraphQLScalarType | GraphQLEnumType;
type GraphQLField = { name: string, description: string, args: Array<any>, type: GraphQLType, isDeprecated: boolean };

function mapToFilterProperty(field: GraphQLField): FilterProperty {
  const required = field.type.kind === 'NON_NULL';
  const fieldType = (required ? (field.type as GraphQLNotNullType).ofType : field.type) as GraphQLScalarType | GraphQLEnumType;

  if (fieldType.kind === 'SCALAR') {
    let type;
    switch ((fieldType as GraphQLScalarType).name) {
      case 'Boolean': type = 'boolean'; break;
      case 'Float': type = 'number'; break;
      case 'Int': type = 'number'; break;
      case 'String': type = 'string'; break;
      case 'ID': type = 'string'; break;
    }

    return {
      name: field.name,
      label: field.description,
      type,
      required
    };
  } else if (fieldType.kind === 'ENUM') {
    const enumType = (fieldType as GraphQLEnumType).name;
    const options = ENUMS[enumType];

    return <DropdownFilterProperty>{
      name: field.name,
      label: field.description,
      type: 'enum',
      required,
      options,
      enumType,
    }
  }

  if ((fieldType as any).kind === 'OBJECT' && (fieldType as any).name === 'NumericProperty') {
    return {
      name: field.name,
      label: field.description,
      type: 'number',
      required
    };
  }
}

