<div  class="authed-links">
  <a *ngIf="isInReviewGroup" (click)="openLogHistoryPage()" class="log-history">
    <img src="../../assets/Log_history.svg" draggable="false" height="20px" width="20px">
    <span class="open-request-text">Log History</span>
  </a>
  <a *ngIf="isInReviewGroup" (click)="openOpenRequestsPage()" class="open-request">
    <img src="../../assets/check_all.svg" draggable="false" height="20px" width="20px">
    <span class="open-request-text">Data Submits</span>
    <span *ngIf="openChangeRequsts" class="open-request-dot"></span>
  </a>
  <a *ngIf="isInReviewGroup" (click)="openBulkUploadPage()" class="upload">
    <i class="fas fa-file-upload"></i>
    <span class="open-request-text">File Upload</span>
  </a>
</div>
