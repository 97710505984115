
/**
 * Simple linear distribution with default and max size, where max size never exceeding,
 * but based on linear distribution values smaller than max-min-value-quote could be
 * smaller than than default size.
 */
export function linearDistributionWithMaxBound(
  defaultSize: number,
  maxSize: number,
  minValue: number,
  maxValue: number,
  value: number
): number {
  if (maxValue && minValue && value) {
    return (maxSize - defaultSize) / (Math.max(maxValue, minValue * maxSize / defaultSize) - minValue) * value;
  }
  return defaultSize;
}

/**
 * Simple linear distribution with min and max size, where max and min size never exceeding,
 * but max size is also dependent of factor to min size.
 */
export function linearDistribution(
  minSize: number,
  maxSize: number,
  minValue: number,
  maxValue: number,
  value: number
): number {
  if (maxValue && !!value) {
    const calculatedMinValue = Math.max(minValue, 0);
    const calculatedMaxValue = maxValue - minValue;
    const calculatedMaxSize = calculatedMinValue && minSize ? Math.min(maxSize, calculatedMaxValue / calculatedMinValue * minSize) : maxSize;
    return ((calculatedMaxSize - minSize) / calculatedMaxValue * (value - calculatedMinValue)) + minSize;
  }
  return minSize;
}
