import { Component } from '@angular/core';
import { version } from '../../package.json';
import { DialogConfig } from './components/dialog/dialog-config';
import { DialogService } from './components/dialog/dialog.service';
import {RoleGuardService} from './services/role.guard'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DialogConfig, DialogService, RoleGuardService]
})
export class AppComponent {
  public version: string = version;
  title = 'virtual-comparison-of-products';
}
