<section data-ui="content" class="scatter-plot-wrapper">
  <svg #svg class="scatter-plot" xmlns="http://www.w3.org/2000/svg">
    <g [attr.transform]="'translate('+marginLeft+',0)'">
      <g class="x-axis" [attr.transform]="'translate(0, ' + (svgHeight - marginBottom - marginTop) + ')'"></g>
      <text style="text-anchor: middle;" font-size="0.71em"
        [attr.transform]="'translate(' + ((svgWidth - marginLeft - marginRight) / 2 + marginLeft) + ', ' + (svgHeight - marginBottom) + ')'">
        {{ fields ? fields[xPropertyName] : xPropertyName }}
      </text>
      <g class="y-axis" [attr.transform]="'translate(' + (marginLeft) +', 0)'"></g>
      <text *ngIf="yPropertyName" transform="rotate(-90)" style="text-anchor: middle;" font-size="0.71em" [attr.y]="" [attr.x]="-(svgHeight - marginTop)/2+marginTop">
       {{ fields ? fields[yPropertyName] : yPropertyName }}
      </text>

      <rect class="draw-area" [attr.x]="marginLeft" [attr.y]="marginBottom" [attr.width]="svgWidth - marginLeft - marginRight" [attr.height]="svgHeight - marginBottom - marginTop" fill-opacity="0"></rect>

      <ng-container *ngFor="let item of renderableItems; index as i">
        <g class="dot" *ngIf="visible[i]" [attr.transform]="'translate('+x[i]+','+y[i]+')'">
          <circle *ngIf="isSelected[i]" class="point" [attr.r]="r[i]" [attr.fill]="color[i]" (mouseenter)="displayTooltip(item)" (click)="onBubbleSelected(item)"></circle>
          <circle *ngIf="!isSelected[i]" class="point" [attr.r]="r[i]" fill="white" [attr.stroke]="color[i]" stroke-width="3" (mouseenter)="displayTooltip(item)" (click)="onBubbleSelected(item)"></circle>
        </g>
      </ng-container>
      <ng-container *ngFor="let item of renderableItems; index as i">
        <g *ngIf="tooltipBubble == item" (mouseleave)="hideTooltip(item)">
          <circle class="point"  [attr.transform]="'translate('+x[i]+','+y[i]+')'" [attr.r]="r[i] + 3" fill="#FFFFFF99" stroke="transparent" stroke-width="10" (click)="onBubbleSelected(item)"></circle>
          <g [attr.transform]="'translate('+toolTipX[i]+','+toolTipY[i]+')'">
            <foreignObject width="300px" height="300px" y="-150" x="-55">
              <xhtml:div class="tooltip" xmlns="http://www.w3.org/1999/xhtml">
                <ng-container *ngTemplateOutlet="template; context: { item: item }"></ng-container>
              </xhtml:div>
            </foreignObject>
        </g>
      </g>
      </ng-container>
    </g>
  </svg>
  <section class="legend" data-ui="flatted">
    {{ classificationPropertyName }}
    <ul>
      <li *ngFor="let classification of classificationColors | keyvalue"
      [attr.style]="'color: ' + classification.value + ';'">
        &#11044; {{ classification.key }}
      </li>
    </ul>
  </section>
</section>
