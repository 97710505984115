<section data-ui="content">
  <multi-dropdown [options]="columns" [(selected)]="showFields" [fields]="fields" dataUi="primary secondary"
    label="Show rows">
  </multi-dropdown>
  <ng-container *ngTemplateOutlet="actions; context: { value: {showFields: showFields, data: data} }"></ng-container>

  <div class="comparison-table">
    <table>
      <tr *ngFor="let field of showFields">
        <th>{{ fields ? fields[field] : field }}</th>
        <td *ngFor="let entry of data; let i = index">
          <ng-container
            *ngIf="entry[field] === true; then truePrint; else (entry[field] === false && falsePrint) || simplePrint">
          </ng-container>
          <ng-container
            *ngTemplateOutlet="columnTemplate; context: {$implicit: entry[field], column: field, row: entry}">
          </ng-container>
          <ng-template #defaultPrint>
            <ng-container
              *ngIf="entry[field] === true; then truePrint; else (entry[field] === false && falsePrint) || simplePrint">
            </ng-container>
          </ng-template>
        </td>
      </tr>
    </table>
  </div>

</section>


<ng-template #truePrint><i class="fas fa-check"></i></ng-template>
<ng-template #falsePrint><i class="fas fa-times"></i></ng-template>
