import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/api/products.service';
import { UserService } from 'src/app/api/user.service';
import { userGroups } from '../../config/userGroups';

@Component({
  selector: 'app-open-request-link',
  templateUrl: './open-request-link.html',
  styleUrls: ['./open-request-link.scss'],
})
export class OpenRequestLinkComponent implements OnInit{

  openChangeRequsts: boolean = false;
  isInReviewGroup: boolean = false;

  constructor (
    private productsService: ProductsService,
    private userService: UserService,
    private router: Router,
  ) {}


  async ngOnInit() {
    const userMeta = await this.userService.getUser();
    this.isInReviewGroup = userGroups.some(r => userMeta.roles?.includes(r) ?? [].includes(r));
    const changeRequests = await this.productsService.fetchProductsChangeRequests();
    this.openChangeRequsts = changeRequests.length > 0 ? true : false;
  }

  openOpenRequestsPage() {
    this.router.navigate(['/open-requests']);
  }

  openBulkUploadPage() {
    this.router.navigate(['/products-import']);
  }

  openLogHistoryPage() {
    this.router.navigate(['/log-history']);
  }

}
