import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';


@Component({
  selector: 'dynamic-input',
  templateUrl: './dynamic-input.component.html',
  styleUrls: ['./dynamic-input.component.scss']
})

export class DynamicInputComponent implements OnInit, OnDestroy {
  @Input()
  type: 'number' | 'boolean' | 'enum' | 'string' | 'array';

  @Input()
  name: string;

  @Input()
  label: string;

  @Input()
  options?: Record<string, string>;

  @Input()
  formGroup: FormGroup;

  @Input()
  required: boolean = false;

  @Input()
  default: any;


   private sub: Subscription;
   private sub1: Subscription;

  //  Fields that's not need "not applicable" checkbox
   notApplicableFieldNotRequired = ['% Alternative Feedstock Content', 'PCF in kg CO2/kg Product']

  ngOnInit(): void {
    if (!this.formGroup.controls[this.name]) {
      this.formGroup.addControl(
        this.name,
        this.type === 'number' ?
          new FormGroup({
            value: new FormControl(),
            isNotApplicable: new FormControl()
          })
          : new FormControl()
      );

      if (this.type === 'boolean') { 
        setTimeout(() => this.formGroup.controls['mass'].disable());
        setTimeout(() => this.formGroup.controls['carbon'].disable());
        setTimeout(() => this.formGroup.controls['includingBioCarbon'].disable());
        setTimeout(() => this.formGroup.controls['excludingBioCarbon'].disable());
      }

      if (this.type === 'number') { 
        this.sub1 = this.formGroup.controls[this.name].valueChanges.pipe(distinctUntilChanged(),).subscribe(({value, isNotApplicable}) => {
          if(this.name == 'biocontent'){
            if(value != null){
            setTimeout(() => this.formGroup.controls['mass'].enable());
            setTimeout(() => this.formGroup.controls['carbon'].enable());
            } else{
              setTimeout(() => this.formGroup.controls['mass'].disable());
              setTimeout(() => this.formGroup.controls['carbon'].disable());
              setTimeout(() => this.formGroup.controls['mass'].setValue(false));
              setTimeout(() => this.formGroup.controls['carbon'].setValue(false));
            }
        }  if(this.name == 'carbonFootprint') {
          if(value != null){
          setTimeout(() => this.formGroup.controls['includingBioCarbon'].enable());
          setTimeout(() => this.formGroup.controls['excludingBioCarbon'].enable());
          } else{
            setTimeout(() => this.formGroup.controls['includingBioCarbon'].disable());
            setTimeout(() => this.formGroup.controls['excludingBioCarbon'].disable());
            setTimeout(() => this.formGroup.controls['includingBioCarbon'].setValue(false));
            setTimeout(() => this.formGroup.controls['excludingBioCarbon'].setValue(false));
          }
      }
        });

         this.sub = this.formGroup.controls[this.name].valueChanges
          .pipe(distinctUntilChanged(),).subscribe(({value, isNotApplicable}) => {
            const prev = this.formGroup.value[this.name];
            if (value && !prev.value) {
              setTimeout(() => this.formGroup.controls[this.name].setValue(({ value, isNotApplicable:false})));
            }  else if (value && !prev.value && prev.isNotApplicable) {              
              setTimeout(() => this.formGroup.controls[this.name].setValue(({ value, isNotApplicable:false})));
            } else if (isNotApplicable && !prev.isNotApplicable && prev.value) {              
              setTimeout(() => this.formGroup.controls[this.name].setValue(({value: null, isNotApplicable})));
            }
          });
      }
    }
    if (this.default) {
      this.formGroup.controls[this.name].setValue(this.default);
    }
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  onCheckChange(data:any, label:string){
    if(data.target.checked == true && data.target.id == "carboncheckbox" ){
      setTimeout(() => this.formGroup.controls['mass'].disable());
      setTimeout(() => this.formGroup.controls['mass'].setValue(false));
    } else if (data.target.checked == true && data.target.id == "masscheckbox"){
      setTimeout(() => this.formGroup.controls['carbon'].disable());
      setTimeout(() => this.formGroup.controls['carbon'].setValue(false));
    } else {
    setTimeout(() => this.formGroup.controls['mass'].enable());
    setTimeout(() => this.formGroup.controls['carbon'].enable());
    }   

    if(data.target.checked == true && data.target.id == "includingBioCarboncheckbox" ){
      setTimeout(() => this.formGroup.controls['excludingBioCarbon'].disable());
      setTimeout(() => this.formGroup.controls['excludingBioCarbon'].setValue(false));
    } else if (data.target.checked == true && data.target.id == "excludingBioCarboncheckbox"){
      setTimeout(() => this.formGroup.controls['includingBioCarbon'].disable());
      setTimeout(() => this.formGroup.controls['includingBioCarbon'].setValue(false));
    } else {
    setTimeout(() => this.formGroup.controls['excludingBioCarbon'].enable());
    setTimeout(() => this.formGroup.controls['includingBioCarbon'].enable());
    } 
  }
}

