import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { DropdownFilterProperty, FilterComponent, FilterProperty, MinMax, NumberRangeFilterProperty } from '../../../../../components/components/organisms/filter/filter.component';
import { PossibleProductFilters, ProductFilterInput } from '../../../generated/graphql';
import { ProductsService } from '../../api/products.service';
import { PRODUCT_MINIMAL_PROPERTIES, PRODUCT_FILTER_PROPERTIES_BY_FAMILY } from '../../services/product-properties.service';
import { GtagService } from '../../shared/gtag/gtag.service';
import { ProductsSearchComponent } from '../products-search/products-search.component';

@Component({
  selector: 'app-products-filters',
  templateUrl: './products-filters.component.html',
  styleUrls: ['./products-filters.component.scss']
})
export class ProductsFiltersComponent implements OnInit, AfterViewInit {
  @Output() clearAllFilters = new EventEmitter();
  @Output() search = new EventEmitter();


  @ViewChild(FilterComponent, { static: true }) productsFilter: FilterComponent;
  @ViewChild(ProductsSearchComponent, { static: true }) productsSearch: ProductsSearchComponent;

  @Output()
  load = new EventEmitter<Partial<ProductFilterInput>>();

  @Output()
  selectFilter = new EventEmitter<Partial<ProductFilterInput>>();

  @Output()
  totalAvailable = new EventEmitter<number>();

  properties: Array<FilterProperty>;
  total: number;

  anyFiltered: boolean = false;
  loadingFilterPossibilities: boolean = false;

  filterInput: Partial<ProductFilterInput>;

  private previousFamily: string;

  constructor(
    private userSettings: UserSettingsService,
    private gtag: GtagService,
    private productsService: ProductsService,
  ) { }

  async ngOnInit() {
    this.properties = this.initFilterProperties({family: this.userSettings.getFilterFamily()}, null);
    const filter = this.userSettings.getPersonalFilter() ?? {};
    await this.onSelectFilter(filter, true);
  }

  ngAfterViewInit() {
    this.productsFilter.values.patchValue(this.userSettings.getPersonalFilter() ?? {"family":"ALL","supplier":null,"type":null,"subType":null}, {emitEvent: false});
  }

  async onSelectFilter($event: Record<string, number | boolean | string | MinMax>, onInit: boolean = false) {
    if (!onInit) {
      this.gtag.click('set-filter');
      // this.selectFilter.emit($event); // rethink about component slicing #1
      this.userSettings.setPersonalFilter($event);
    }
    this.filterInput = this.convertToFilterInput($event);
    this.selectFilter.emit(this.filterInput); // rethink about component slicing #1
    this.loadingFilterPossibilities = true;
    this.total = null;
    await this.productsService.fetchFilterOptions(this.filterInput).then(options => this.applyFilterProperties($event, options));
    this.onShow();
  }

  onShow() {
    this.load.emit(this.filterInput);
    this.totalAvailable.emit(this.total);
  }

  onClearAll($event) {
    this.gtag.click('cleared-filters');
    this.userSettings.setPersonalFilter({});
    this.productsFilter.reset();
    this.productsSearch.reset();
    this.clearAllFilters.emit($event);
  }

  onSearch($event) {
    this.search.emit($event);
  }

  private convertToFilterInput($event: Record<string, number | boolean | string | MinMax | Array<any>>): ProductFilterInput {
    const filterInputEntries = Object.entries($event)
      .filter(([key, value]) => value != null && ((typeof value === 'string' && value !== 'ALL') || ((value as MinMax).min != null || (value as MinMax).max != null) || typeof value === 'number' || typeof value === 'boolean' || (Array.isArray(value) && (value as Array<any>)?.length)))
      .map(([key, value]) => {
        if (key === 'hasBiocontent') {
          if (value && ($event['biocontent'] as MinMax)?.min == null && ($event['biocontent'] as MinMax)?.max == null) {
            return ['biocontent', {min: 0.000001}];
          } else {
            return null;
          }
        }
        if (typeof value === 'string') {
          if(value.length == 0){ return null;}
          else{
          return [key, {contains: value}];
          }
        }
        if (Array.isArray(value) && (value as Array<any>)?.length) {
          return [key, {in: value}];
        }
        if (typeof value === 'boolean') {
          if(value == false){
            return ['isDeleted', {is: false}];
          } else {
            return [key, {is: value}];
          }
        }
        return [key, value];
      });
      filterInputEntries.push(['isDeleted', {is: false}]); // If "Archived/Deleted" products want to be filtered, remove this line
    return filterInputEntries.length ? Object.fromEntries(filterInputEntries.filter(e => e != null)) : null;
  }

  private applyFilterProperties(
    selectedFilter: Record<string, number | boolean | string | MinMax>,
    filterOptions: PossibleProductFilters
  ) {
    this.anyFiltered = Object.values(selectedFilter)
      .filter(val => val && ((val as MinMax).min === undefined || (val as MinMax).min != null || (val as MinMax).max != null))
      .length > 0;

    let properties: Array<FilterProperty> = this.initFilterProperties(selectedFilter, filterOptions);
    properties = this.restrictFilterProperties(filterOptions, properties);

    this.properties = properties;
    this.loadingFilterPossibilities = false;
  }

  private initFilterProperties(
    selectedFilter: Record<string, string | number | boolean | MinMax>,
    filterOptions: PossibleProductFilters
  ): Array<FilterProperty> {
    let properties: Array<FilterProperty>;
    const selectedFamily = (selectedFilter.family ?? (filterOptions?.family?.length === 1 ? filterOptions.family[0] : null)) as string;
    this.userSettings.setFilterFamily(selectedFamily);
    if (this.previousFamily !== selectedFamily || this.previousFamily == null || properties == null) {
      this.previousFamily = selectedFamily;
      if (selectedFamily != null && selectedFamily != 'ALL') {
        properties = PRODUCT_FILTER_PROPERTIES_BY_FAMILY[selectedFamily as string];
      } else {
        properties = PRODUCT_MINIMAL_PROPERTIES;
        const hasBiocontent:any = {name: 'hasBiocontent', label: 'Has Alternative Feedstock Content', type: 'boolean',required: false}
       // const carbon:any = {name: 'carbon', label: '% referring to carbon only', type: 'boolean',required: false}
       // const mass:any = {name: 'mass', label: '% referring to mass only', type: 'boolean',required: false}
        properties=[...properties,hasBiocontent]
      }
      if (properties == null) {
        properties = PRODUCT_MINIMAL_PROPERTIES;
        const hasBiocontent:any = {name: 'hasBiocontent', label: 'Has Alternative Feedstock Content', type: 'boolean', required: false}
        //const carbon:any = {name: 'carbon', label: '% referring to carbon only', type: 'boolean',required: false}
        //const mass:any = {name: 'mass', label: '% referring to mass only', type: 'boolean',required: false}
        properties=[...properties,hasBiocontent]
      }
      return properties.filter(prop => prop.name !== 'name'); // remove product name from filter options
    }
    return properties;
  }

  private restrictFilterProperties(filterOptions: PossibleProductFilters, properties: Array<FilterProperty>) {
    if (filterOptions) {
      this.total = filterOptions._total;
      Object.entries(filterOptions)
        .map(([propertyName, options]) => {
          const propertyIdx = properties.findIndex(prop => prop.name === propertyName);
          if (propertyIdx > -1) {
            if ((properties[propertyIdx]?.type === 'enum' || properties[propertyIdx]?.type === 'multi-enum' || properties[propertyIdx]?.type === 'string')) {
              properties[propertyIdx] = Object.assign(
                {},
                properties[propertyIdx],
                <Partial<DropdownFilterProperty>>{ type: properties[propertyIdx].name === 'family' ? 'enum' : 'multi-enum',
                  options: Object.fromEntries(options?.map(o => [o, o]) ?? []) }
              );
            } else if (properties[propertyIdx]?.type === 'number') {
              properties[propertyIdx] = Object.assign(
                {},
                properties[propertyIdx],
                <Partial<NumberRangeFilterProperty>>{ allowedRange: options as MinMax }
              );
            }
          }
        });
    }
    return properties;
  }
}
