<div class="user-navigation" *ngIf="isLoggedIn$ | async">
  <app-open-request-link></app-open-request-link>
  <a href="https://covestro.sharepoint.com/sites/CompetitorObservation" outbound class="clue-link" target="_blank">
    <img src="../../assets/1675.svg" draggable="false" height="20px" width="20px">
    <span class="feedback-text">More in CLUE Sharepoint</span>
  </a>
  <a href="mailto:CLUE-approvals_core_team@covestro.com?subject=Vcop%20feedback&body=If%20you%20have%20any%20feedback%20to%20the%20current%20state%20of%20Vcop%2C%20please%20let%20us%20know."
    (click)="onGiveFeedback()"
    class="feedback-mail">
    <i class="fas fa-paper-plane"></i><span class="feedback-text">Send us feedback</span>
  </a>
  <a (click)="openhelppageDialog()" class="help-section">
    <img src="../../assets/help.svg" draggable="false" height="20px" width="20px">
    <span class="help-section-text">Help</span>
    
  </a>
</div>

