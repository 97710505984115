<div class="product-basket-list">
  <div>
    <div class="product-basket" *ngIf="showDetails">
      <div  *ngFor="let showDetails of showDetails">
        <div><b>Product Name </b>   :   {{showDetails.name}}</div><br>
        <div><b>Base Type </b>   :   {{showDetails.family}}</div><br>
        <div><b>Supplier </b>   :   {{showDetails.supplier}}</div><br>
        <div><b>Type </b>   :   {{showDetails.type}}</div><br>
        <div><b>SubType </b>   :   {{showDetails.subType}}</div><br>
      </div> <br>
          
    <Table >
      <hr>
      <tr>
        <th>Property Name</th>
          <th>Entered Value</th>
          </tr>
      <hr><br>
      <tr  *ngFor="let value of enteredValue.enteredValue">
        <td><b>{{ fields ? fields[value.propertyName] : value }}</b></td> 
        <td>{{value.textValue}}{{value.numericValue.value}}
          <div *ngIf="value.numericValue.isNotApplicable">Not Applicable</div>
          <div *ngIf="!value.numericValue.isNotApplicable && value.textValue == null && value.numericValue.value ==null">Not Entered</div></td> <br>
       </tr>
    </Table>
   </div>
  </div>
</div>
   
