import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  OnChanges,
  SimpleChanges,
  ContentChild,
  TemplateRef,
  OnInit
} from '@angular/core';
import * as d3 from 'd3';
import { ZoomBehavior } from 'd3';
import { calcWithOccurenceNumber } from '../../../services/collection-functions';
import { linearDistribution } from '../../../services/scale-functions';
import { Output, EventEmitter } from '@angular/core';
import { hasChanged } from '../../../helpers/ngHasChanged';
import { PRODUCT_PROPERTY_LABELS } from 'src/app/services/product-properties.service';

@Component({
  selector: 'scatter-plot',
  templateUrl: './scatter-plot.component.html',
  styleUrls: ['./scatter-plot.component.scss'],
})
export class ScatterPlotComponent
  implements OnChanges, OnInit {
  @Input()
  items: Array<any>; // TODO: for later refactoring <T>

  @Input()
  keyFn: (item) => string;

  @Input()
  xFn: (item) => number = (item) => item;

  @Input()
  yFn?: (item) => number;

  @Input()
  sizeFn?: (item) => number;

  @Input()
  classificationFn?: (item) => number;

  @Input()
  xPropertyName: string;

  @Input()
  yPropertyName?: string;

  @Input()
  sizePropertyName?: string;

  @Input()
  classificationPropertyName?: string;

  @Input()
  defaultColor = 'red';

  @Input()
  classificationColors?: { [key: string]: string };

  @ContentChild(TemplateRef)
  template: TemplateRef<any>;

  @Output()
  selectedChange = new EventEmitter<Set<any>>(); // TODO: for later refactoring <T>

  @Input()
  selected: Set<any> = new Set(); // TODO: for later refactoring <T>
  // TODO: search by key more efficient with key value pair

  fields: { [key in string]: string } = {};

  minDotSize = 40;
  maxDotSize = 625;

  marginLeft = 60;

  marginRight = 20 + this.minDotSize / 2;
  marginTop = 20 + this.minDotSize / 2;

  marginBottom = 40;

  constructor(private rd: Renderer2, private el: ElementRef) {}

  private calculateX;
  private calculateY;

  private stackedYValues: Array<number>;
  renderableItems: Array<{}>;

  tooltipBubble: any; // TODO: for later refactoring <T>

  svgWidth: number;
  svgHeight: number;

  x: Array<number>;
  y: Array<number>;
  r: Array<number>;
  color?: Array<string>;
  toolTipX: Array<number>;
  toolTipY: Array<number>;
  visible: Array<boolean>;
  isSelected: Array<boolean>;

  private minSize: number;
  private maxSize: number;

  private zoom: ZoomBehavior<Element, unknown>;

  ngOnInit(): void {
    this.fields = PRODUCT_PROPERTY_LABELS;
    this.zoom = d3
      .zoom()
      .scaleExtent([0.5, 32])
      .on('zoom', this.positionElements.bind(this));

    d3.select(this.el.nativeElement.querySelector('svg'))
      .call(this.zoom)
      .call(this.zoom.transform, d3.zoomIdentity);

  }

  ngOnChanges(changes: SimpleChanges): void {
    const svgDimesions = this.el.nativeElement
      .querySelector('svg')
      .getBoundingClientRect();

    this.svgWidth = svgDimesions.width;
    this.svgHeight = svgDimesions.height;

    if (hasChanged(changes.items) || hasChanged(changes.xFn) || hasChanged(changes.yFn)) {
      this.renderableItems = this.items.filter(item => {
        return this.xFn(item) != null && (!this.yFn || this.yFn(item) != null)});
    }

    if (hasChanged(changes.items) || hasChanged(changes.xFn) || (hasChanged(changes.yFn) && !this.yFn)) {
      const range: [number, number] = [
        this.marginLeft,
        this.svgWidth - this.marginRight - this.marginLeft,
      ];
      const xData = this.renderableItems.map(this.xFn);
      this.setCalculateX(xData, range);

      if (hasChanged(changes.yFn) && !this.yFn || !this.yFn && (!this.stackedYValues)) {
        this.stackedYValues = calcWithOccurenceNumber(xData).map(
          (data) => data.occurence + 1
        );
        this.setCalculateY(this.stackedYValues, 1);
      }
    }

    if ((hasChanged(changes.items) && this.yFn) || (hasChanged(changes.yFn) && this.yFn)) {
      this.setCalculateY(this.renderableItems.map(this.yFn), 0);
    }

    if (hasChanged(changes.items) || hasChanged(changes.sizeFn)) {
      const sizeData = this.sizeFn ? this.renderableItems.map(this.sizeFn) : undefined;
      this.setSizes(sizeData);
    }

    if (hasChanged(changes.items) || hasChanged(changes.classificationFn) || hasChanged(changes.classificationColors)) {
      this.color = this.renderableItems.map((item) =>
        this.calculateColor(item)
      );
    }
    this.setSelected();
  }

  private setCalculateX(xData: Array<number | string>, range: [number, number]) {
    if (!xData.some(isNaN)) {
      this.calculateX = d3
        .scaleLinear()
        .domain([Math.min(...xData as Array<number>), Math.max(...xData as Array<number>)])
        .range(range);
    } else {
      const [min, max] = range;
      const columnSize = (max - min) / xData.length;
      const ordinalRange = xData.map(
        (_, index) => columnSize * index + min
      );
      this.calculateX = d3
        .scaleOrdinal()
        .domain(xData as Array<any>)
        .range(ordinalRange);
    }
  }

  private setCalculateY(yData: Array<number>, yRangeBound: number) {
    this.calculateY = d3
      .scaleLinear()
      .domain([
        Math.max(...yData) + yRangeBound,
        Math.min(...yData) - yRangeBound,
      ])
      .range([
        this.marginTop,
        this.svgHeight - this.marginBottom - this.marginTop,
      ]);
  }

  private setSizes(sizeData?: Array<number>) {
    if (sizeData) {
      this.minSize = Math.min(...sizeData);
      this.maxSize = Math.max(...sizeData);
      if (this.maxSize > 10000) {
        this.minSize = this.minSize === 0 ? 100 : this.minSize;
        this.maxSize = this.maxSize / (this.minSize * 1000);
      }
      this.marginRight = 20 + this.maxSize / 2;
      this.marginTop = 20 + this.maxSize / 2;
    }

    this.r = this.renderableItems.map((item) => this.calculateRadius(item));
  }

  private calculateRadius(item: {}): number {
    let sizeValue = null;
    if (this.sizeFn) {
      sizeValue = this.sizeFn(item);
    }
    return Math.sqrt(
      linearDistribution(
        this.minDotSize,
        this.maxDotSize,
        this.minSize,
        this.maxSize,
        sizeValue
      )
    );
  }

  private calculateColor(item: {}): string {
    const classification = this.classificationFn && this.classificationColors ? this.classificationFn(item) : undefined;
    return classification ? this.classificationColors[classification] : this.defaultColor;
  }

  onBubbleSelected(item: any): void {
    const found = [...this.selected].filter(s => this.keyFn(s) === this.keyFn(item))[0];
    if (found) {
      this.selected.delete(found);
    } else {
      this.selected.add(item);
    }
    this.setSelected();

    this.selectedChange.emit(this.selected);
  }

  displayTooltip(item: any): void {
    this.tooltipBubble = item;
  }

  hideTooltip(item: any): void {
    if (this.tooltipBubble === item) {
      this.tooltipBubble = null;
    }
  }

  private positionElements({ transform }): void {
    const calculateX = transform
      .rescaleX(this.calculateX)
      .interpolate(d3.interpolateRound);
    const calculateY = transform
      .rescaleY(this.calculateY)
      .interpolate(d3.interpolateRound);

    this.x = this.renderableItems.map(this.xFn).map(calculateX);
    this.y = (this.yFn ? this.renderableItems.map(this.yFn) : this.stackedYValues).map(calculateY);

    this.visible = this.x.map((_, idx) => this.isVisible(idx));

    this.toolTipX = this.x.map((x) => this.calculateToolTipX(x, 200, 10));
    this.toolTipY = this.y.map((y) => this.calculateToolTipY(y, 100, 10));

    d3.select(this.el.nativeElement.querySelector('.x-axis')).call(
      d3.axisBottom(calculateX)
    );
    if (this.yPropertyName) {
      d3.select(this.el.nativeElement.querySelector('.y-axis')).call(
        d3.axisLeft(calculateY)
      );
    }
  }

  private calculateToolTipX(xPoint: number, width: number, margin = 0): number {
    return this.svgWidth - this.marginRight - this.marginLeft < xPoint + width
      ? xPoint - width - margin
      : xPoint;
  }

  private calculateToolTipY(
    yPoint: number,
    height: number,
    margin = 0
  ): number {
    return 0 + this.marginTop > yPoint - height
      ? yPoint + height + margin
      : yPoint;
  }

  private isVisible(i: number): boolean {
    const size = this.r[i];
    return this.marginLeft < this.x[i] + size
      && this.svgHeight - this.marginBottom > this.y[i] + size
      && this.svgWidth - this.marginLeft - this.marginRight > this.x[i] - size
      && this.marginTop < this.y[i] + size;
  }

  private setSelected(): void {
    const keys = [...this.selected].map(this.keyFn.bind(this));
    this.isSelected = this.renderableItems.map(item => keys.includes(this.keyFn(item)));
  }
}
