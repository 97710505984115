import { Component, Input, EventEmitter, ContentChild, TemplateRef, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CheckboxGroup } from '../../atoms/checkbox/checkbox-group.model';

@Component({
  selector: 'comparision-table',
  templateUrl: './comparision-table.component.html',
  styleUrls: ['./comparision-table.component.scss']
})
export class ComparisionTableComponent<T> {
  @Input('data')
  set data(data: Set<T>) {
    this._data = data;
    if (data) {
      this.columns = Object.keys(data?.values()?.next()?.value ?? {})
        // .sort((a, b) =>
        //   (this.fields?.[a] ?? a).toLowerCase()
        //     .localeCompare((this.fields?.[b] ?? b).toLowerCase()));
        
    } else {
      this.columns = [];
      this._data?.clear();
    }
  }

  get data(): Set<T> {
    return this._data;
  }


  @ContentChild(TemplateRef)
  actions: TemplateRef<any>;

  @Input()
  columnTemplate: TemplateRef<any>;

  @Output()
  sortedRows = new EventEmitter<Array<string>>();

  private _data: Set<T>;

  @Input()
  showFields: Array<string>;

  @Input()
  describingFields: Array<string>;

  @Input()
  fields: { [key in string]: string } = {};

  @Input()
  selectActionsTemplate: TemplateRef<any>;

  columns: Array<string>;

  productsCheckboxGroup = new CheckboxGroup();

  @Input()
  selected: Set<T> = new Set();
  /*
   dropProducts(event: CdkDragDrop<string[]>) {
     return
     // moveItemInArray(this._data, event.previousIndex, event.currentIndex);
   }

   dropField(event: CdkDragDrop<string[]>) {
     moveItemInArray(this.showFields, event.previousIndex, event.currentIndex);
     this.sortedRows.emit(this.showFields);
   }

   selectOrDeselectAll(selected: boolean): void {
     if (selected) {
       this.selected = new Set(this._data);
     } else {
       this.selected = new Set();
     }
   }

   selectedChange(item: T, selected: boolean): void {
     if (selected) {
       this.selected.add(item);
     } else {
       this.selected.delete(item);
     }
   } */
}

export type ComparisionTableState<T> = { data: Set<T>, showFields: Array<string> };
