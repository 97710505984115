import { Component } from '@angular/core';
@Component({
  selector: 'not-authorized',
  template: '<section data-ui="warn">You are not authorized to open this application</section>',
  styles: [`
    section {
      text-align: center;
      font-weight: bold;
    }
  `]
})
export class NotAuthorizedComponent {

}
