<div class="content" gtag="search-results">
  <div class="filter">
    <div class="step">1. Filter</div>
    <app-products-filters class="item" (search)="onSearch($event)"
      (selectFilter)="onFilterSelected($event)"
      (totalAvailable)="onTotalAvailableChange($event)"
      (load)="loadProducts($event)"
      (clearAllFilters)="onClearAllFilters($event)">
    </app-products-filters>
  </div>
  <section class="main">
    <div class="step">2. Select</div>
    <ng-container *ngIf="showSearchResult">
      <ng-container *ngIf="loading">
        <div class="loading-wrapper">
          <div class="loader"></div>
        </div>
      </ng-container>
      <ng-container *ngIf="!loading ">
        <tabs *ngIf="products && fields && showFields" [preloadFn]="preloadTab" [selected]="this.selectedTab" (selectedChange)="onSetTab($event)">
          <ng-template tab-label="Table">
            <sortable-table [data]="products"
            (loadItems)="loadTableItems($event)"
            (activeSelectValues)="onColoumnsChange($event)"
            [fields]="fields" [keyFn]="keyFn"
              [clickEvents]="tableCellClickEvents"
              [showFields]="showFields"
              [hasBiocontent]="hasBiocontent"
              [state]="settings.resultTableState"
              (stateChange)="onTableStateChanged($event)"
              [showActionsTemplate]="showActions" [selectActionsTemplate]="selectActions" [totalNumberTemplate]="totalNumber"
              [columnTemplate]="column"
              [downloadAsExcelLoading]="isDownloadAsExcelLoading">
              <div *actions="let v=value" class="actions">
                <multi-interactions (showEmptyFields)="toggleEmptyFields(v, $event)"></multi-interactions>
              </div>
            </sortable-table>
          </ng-template>
          <ng-template tab-label="Chart">
            <ng-container *ngIf="allProductsLoading">
              <div class="loading-wrapper">
                <div class="loader"></div>
                <div class="percentage" *ngIf="totalProducts && numberLoadedProducts">{{ (numberLoadedProducts / totalProducts * 100) | number : '1.0-0'}} %</div>
              </div>
            </ng-container>
            <ng-container *ngIf="!allProductsLoading ">
              <app-product-scatter-plot [keyFn]="keyFn" [products]="products" [productsSelected]="basket$ | async" [(vizualisationProperties)]="graphProperties"></app-product-scatter-plot>
            </ng-container>
          </ng-template>
        </tabs>
      </ng-container>
    </ng-container>
  </section>

  <div class="basket-place">
    <div class="step">3. Compare</div>
    <app-products-basket class="item"></app-products-basket>
  </div>

  <ng-template #showActions let-v="state">
    <multi-interactions (showEmptyFields)="toggleEmptyFields(v, $event)"></multi-interactions>
  </ng-template>

  <ng-template #selectActions let-selected="selected">
    <button class="action-to-basket" [disabled]="!selected?.size" data-ui="small" (click)="addToBasket(selected)">Add to comparision</button>
    <button data-ui="small" (click)="addNewProduct()">Add new product</button>
    <button data-ui="small" (click)="onDownloadAsExcel()">Download</button>
  </ng-template>

  <ng-template #totalNumber let-total="total">
    <div class="total" *ngIf="total">{{ totalProducts }} Products found</div>
  </ng-template>

  <ng-template #column let-value let-column="column" let-row="row">
    <app-product-property [value]="value"></app-product-property>
  </ng-template>
</div>
