import { CheckboxGroupDirective } from "./checkbox-group.directive";
import { CheckboxGroupLeadDirective } from './checkbox-group-lead.directive';

export class CheckboxGroup {
  private checkboxes: Map<CheckboxGroupDirective, boolean> = new Map();

  checkboxGroupLead: CheckboxGroupLeadDirective;

  private numberOfChecked: number = 0;
  private numberOfNotChecked: number = 0;

  addCheckbox(checkbox: CheckboxGroupDirective): void {
    this.checkboxes.set(checkbox, checkbox.value);
    if (checkbox.value) {
      this.numberOfChecked++;
    } else {
      this.numberOfNotChecked++;
    }
    this.setUpdated();
  }

  removeCheckbox(checkbox: CheckboxGroupDirective): void {
    if (this.checkboxes.get(checkbox)) {
      this.numberOfChecked--;
    } else {
      this.numberOfNotChecked--;
    }
    this.checkboxes.delete(checkbox);
    this.setUpdated();
  }

  updateCheckboxValue(checkbox: CheckboxGroupDirective): void {
    if (!this.checkboxes.get(checkbox) && checkbox.value) {
      this.numberOfChecked++;
      this.numberOfNotChecked--;
    } else if (this.checkboxes.get(checkbox) && !checkbox.value) {
      this.numberOfNotChecked++;
      this.numberOfChecked--;
    }
    this.checkboxes.set(checkbox, checkbox.value);
    this.setUpdated();
  }

  setAllValues(value: boolean) {
    this.checkboxes.forEach((val, key) => {
      this.checkboxes.set(key, value);
      key.value = value;
    });
    if (value) {
      this.numberOfChecked = this.checkboxes.size;
      this.numberOfNotChecked = 0;
    } else {
      this.numberOfChecked = 0;
      this.numberOfNotChecked = this.checkboxes.size;
    }
  }

  setUpdated(): void {
    this.checkboxGroupLead?.onValuesChange(this.numberOfChecked > 0, this.numberOfNotChecked > 0);
  }
}
