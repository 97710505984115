import { Injectable } from '@angular/core';
import { NumericPropertyInput, ProductInput } from '../../../generated/graphql';
import { Adapter } from './adapter';
@Injectable({
  providedIn: 'root',
})
export class ProductAdapter implements Adapter<ProductInput> {  
  private getNumericPropertyFor(value: string | number | null): NumericPropertyInput {
    if (typeof value === 'number') {
      return { value: Number(value), isNotApplicable: false };
    }
    if (typeof value === 'string' && value?.trim()?.length && !isNaN(Number(value.replace(",", ".")))) {
      return { value: Number(value.replace(",", ".")) };
    }
    if (value === 'not applicable') {
      return { isNotApplicable: true, value: null };
    }
      return null;
  }

  private getStringPropertyFor(value: string) {
    
   const actual:any = value;
   if(value == null ){
     return null
   }
   else{
     return actual;
   }
    }

  adapt = (product: any): ProductInput => <ProductInput>{
    ...product,
    family: product.family!,
    supplier: product.supplier.trim(),
    name: `${product.name}`.trim(),
    
    //string fields
    subType:this.getStringPropertyFor(product.subType),
    biocontentMethod:this.getStringPropertyFor(product.biocontentMethod),
    biodegradability:this.getStringPropertyFor(product.biodegradability),
    // biodegradabilityMethod:this.getStringPropertyFor(product.biodegradabilityMethod),
    monomerBasis:this.getStringPropertyFor(product.monomerBasis),
    previousName:this.getStringPropertyFor(product.previousName),
    primaryApplication:this.getStringPropertyFor(product.primaryApplication),
    solvent:this.getStringPropertyFor(product.solvent),
    diluent: this.getStringPropertyFor(product.diluent),
    feedstockType:this.getStringPropertyFor(product.feedstockType),

    // all numeric fields
    
    equivalentWeight: this.getNumericPropertyFor(product.equivalentWeight),
    functionality: this.getNumericPropertyFor(product.functionality),
    maxGardnerColor: this.getNumericPropertyFor(product.maxGardnerColor),
    maxHazenAPHAPtCoColor: this.getNumericPropertyFor(product.maxHazenAPHAPtCoColor),
    maxIodineColor: this.getNumericPropertyFor(product.maxIodineColor),
    maximumMonomer: this.getNumericPropertyFor(product.maximumMonomer),
    viscosityMPaS: this.getNumericPropertyFor(product.viscosityMPaS),
    viscosityRange: this.getNumericPropertyFor(product.viscosityRange),
    viscosityTemperature: this.getNumericPropertyFor(product.viscosityTemperature),
    typicalViscosityMPaS: this.getNumericPropertyFor(product.typicalViscosityMPaS),
    NCO: this.getNumericPropertyFor(product.NCO),
    NCORange: this.getNumericPropertyFor(product.NCORange),
    solids: this.getNumericPropertyFor(product.solids),
    deblockTemperature: this.getNumericPropertyFor(product.deblockTemperature),
    MFFT: this.getNumericPropertyFor(product.MFFT),
    activationTemperature: this.getNumericPropertyFor(product.activationTemperature),
    pH: this.getNumericPropertyFor(product.pH),
    tg: this.getNumericPropertyFor(product.tg),
    tg2: this.getNumericPropertyFor(product.tg2),
    MwByGPC: this.getNumericPropertyFor(product.MwByGPC),
    PDIByGPC: this.getNumericPropertyFor(product.PDIByGPC),
    particleSize: this.getNumericPropertyFor(product.particleSize),
    modulusMpa: this.getNumericPropertyFor(product.modulusMpa),
    tensileStrengthMpa: this.getNumericPropertyFor(product.tensileStrengthMpa),
    elongationAtBreak: this.getNumericPropertyFor(product.elongationAtBreak),
    meltingTemp: this.getNumericPropertyFor(product.meltingTemp),
    COOH: this.getNumericPropertyFor(product.COOH),
    OH: this.getNumericPropertyFor(product.OH),
    OHNumber: this.getNumericPropertyFor(product.OHNumber),
    acid: this.getNumericPropertyFor(product.acid),
    softeningPoint: this.getNumericPropertyFor(product.softeningPoint),
    amineNumber: this.getNumericPropertyFor(product.amineNumber),
    biocontent: this.getNumericPropertyFor(product.biocontent),
    density: this.getNumericPropertyFor(product.density),
  };
}
