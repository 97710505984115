export const FILTER_ORDERS_PER_FAMILY: Record<string, Array<string>> = {
  MinimalProduct: [
    'family',
    'supplier',
    'name',
    'type',
    'subType',
    // "isDeleted",
  ],
  Coreactant: [
    'family',
    'supplier',
    'name',
    'type',
    'subType',
    'hasBiocontent',
    //'carbon',
    //'mass',
    'primaryApplication',
    'carbonFootprint',
    'equivalentWeight',
    'functionality',
    'viscosityMPaS',
    'viscosityTemperature',
    'viscosityMethod',
    'solids',
    'solvent',
    'OH',
    'acid',
    'OHNumber',
    'COOH',
    'previousName',
    'amineNumber',
    'PAEComposition',
    'MFFT',
    'biocontent',
    'biocontentMethod',
    'feedstockType',
    'biodegradability',
    //'biodegradabilityMethod',
    'softeningPoint',
    'monomerBasis',
    'tg',
    'tg2',
    // "isDeleted",
    // "viscosityRange",
    //"maxGardnerColor",
    //"maxHazenAPHAPtCoColor",
    //"maxIodineColor",
  ],
  Dispersion: [
    'family',
    'supplier',
    'name',
    'type',
    'subType',
    'hasBiocontent',
    //'carbon',
    //'mass',
    'primaryApplication',
    'carbonFootprint',
    'solids',
    'solvent',
    'MFFT',
    'activationTemperature',
    'crystallization',
    'pH',
    'dispersionBasis',
    'OHNumber',
    'acid',
    'tg',
    'tg2',
    'typicalViscosityMPaS',
    'viscosityMPaS',
    'viscosityMethod',
    //'hasBiocontent',
    'biocontent',
    'biocontentMethod',
    'feedstockType',
    'biodegradability',
    //'biodegradabilityMethod',
    'anionicCationicNonionicDispersion',
    'monomerBasis',
    'maximumMonomer',
    'modulusMpa',
    'tensileStrengthMpa',
    'elongationAtBreak',
    'deblockTemperature',
    'blockingAgent',
    'meltingTemp',
    'COOH',
    'OH',
    'previousName',
    // "isDeleted",
    //"viscosityRange",
    //"equivalentWeight",
    //"functionality",
    //"maxGardnerColor",
    //"maxHazenAPHAPtCoColor",
    //"maxIodineColor",
    //"MwByGPC",
    //"PDIByGPC",
    //"particleSize",
    //"softeningPoint",
  ],
  Isocyanate: [
    'family',
    'supplier',
    'name',
    'type',
    'subType',
    'hasBiocontent',
    //'carbon',
    //'mass',
    'monomerBasis',
    'primaryApplication',
    'carbonFootprint',
    'NCO',
    'functionality',
    'viscosityMPaS',
    'viscosityTemperature',
    'solids',
    'solvent',
    //'hasBiocontent',
    'biocontent',
    'biocontentMethod',
    'feedstockType',
    'biodegradability',
   // 'biodegradabilityMethod',
    'maxHazenAPHAPtCoColor',
    'maxIodineColor',
    'maxGardnerColor',
    'blockingAgent',
    'deblockTemperature',
    'previousName',
    //"isDeleted",
    //"equivalentWeight",
    //"maximumMonomer",
    //"viscosityRange",
    //"NCORange",
  ],
  NonreactiveResin: [
    'family',
    'supplier',
    'name',
    'type',
    'subType',
    //"isDeleted",
    'hasBiocontent',
    //'carbon',
    //'mass',
    'primaryApplication',
    'carbonFootprint',
    'viscosityMPaS',
    'viscosityRange',
    'viscosityMethod',
    'typicalViscosityMPaS',
    'solids',
    'solvent',
    //'hasBiocontent',
    'biocontent',
    'biocontentMethod',
    'feedstockType',
    'biodegradability',
    //'biodegradabilityMethod',
    'otherReactiveGroupPercentage',
    'functionality',
    'maxGardnerColor',
    'maxHazenAPHAPtCoColor',
    'maximumMonomer',
    'previousName',
  ],
  OtherReactiveSystems: [
    'family',
    'supplier',
    'name',
    'type',
    'subType',
    'hasBiocontent',
    //'carbon',
    //'mass',
    //"isDeleted",
    'primaryApplication',
    'carbonFootprint',
    'viscosityMPaS',
    'viscosityRange',
    'viscosityMethod',
    'typicalViscosityMPaS',
    'solids',
    'solvent',
    //'hasBiocontent',
    'biocontent',
    'biocontentMethod',
    'feedstockType',
    'biodegradability',
    //'biodegradabilityMethod',
    'otherReactiveGroupPercentage',
    'functionality',
    'maxGardnerColor',
    'maxHazenAPHAPtCoColor',
    'maximumMonomer',
    'previousName',
  ],
  EnergyCurableSolutions: [
    'family',
    'supplier',
    'name',
    'type',
    'subType',
    //'carbon',
    //'mass',
    'primaryApplication',
    'carbonFootprint',
    'viscosityMPaS',
    'functionality',
    'maxGardnerColor',
    'maxHazenAPHAPtCoColor',
    'tg',
    'acid',
    'diluent',
    'density',
    'biocontent',
    'feedstockType',
  ],
};
