<div class="changelog">
  <a href="/">
    <button data-ui="secondary small">&lt; Back</button>
  </a>
  <h1>Open requests</h1>
  <section *ngIf="!changeRequestGroups?.length" data-ui="content">
    No open change requests available
  </section>
  <section *ngFor="let group of changeRequestGroups" class="changelog-product">
    <div>
      {{ group.createdAt }}, by <b>{{ group.createdBy }}</b>
    </div>

    <div *ngFor="let productChanges of group.changeRequestsPerProducts" class="changelog-log">
      <table>
        <tr>
          <td colspan="4">
            <div>
              <h3>{{productChanges.product ? 'Open changes for the product:' :' Request creation of new product:'}}</h3>
              <a *ngIf="productChanges.product" (click)="openProductDetails(productChanges.product)">{{productChanges.product.name}}</a>
            </div>
          </td>
        </tr>
        <tr *ngIf="productChanges.product">
          <td>
            <div><b>Base Type:</b> {{productChanges.product?.family}}</div>
            <div><b>Type:</b> {{productChanges.product?.type}}</div>
            <div><b>Sub-Type:</b> {{productChanges.product?.subType}}</div>
            <div><b>Supplier:</b> {{productChanges.product?.supplier}}</div>
          </td>
        </tr>
      </table>
      <div class="changelog-actions">
       <button (click)="declineProduct(productChanges)" data-ui="secondary small warn">Decline product</button>
       <button (click)="acceptProduct(productChanges)" data-ui="small accent2">Accept product</button>
      </div>
      <table class="changelog-changes">
        <thead>
          <tr>
            <td>Property name</td>
            <td>Old value</td>
            <td>New value</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let change of productChanges.changeRequests" [ngClass]="modifyState.open  && modifyState.id === change.id ? 'modify-highlight' : ''">
            <td><b>{{LABELS[change.propertyName]}}</b>
              <span class="is-required" *ngIf="!productChanges.product && IS_REQUIRED[change.propertyName]">
                &nbsp;<em>(required)</em>
              </span></td>
            <td>
              <span class="value-old">
                <app-product-property *ngIf="productChanges.product"
                  [value]="productChanges.product[change.propertyName]">
                </app-product-property>
              </span>
            </td>
            <td *ngIf="modifyState.id !== change.id">
              <span class="value-new">
                <app-product-property *ngIf="change.booleanValue != null" [value]="change.booleanValue"></app-product-property>
                <app-product-property *ngIf="change.textValue && change.booleanValue == null" [value]="change.textValue"></app-product-property>
                <app-product-property *ngIf="!change.textValue && change.booleanValue == null" [value]="change.numericValue"></app-product-property>
              </span>
            </td>
            <td *ngIf="modifyState.open  && modifyState.id === change.id">
              <app-request-modify [value]="change" [label]="LABELS[change.propertyName]" (newValue)="catchFormValues($event)"></app-request-modify>
            </td>
            <td *ngIf="change.state == 'open'">
              <ng-container *ngIf="!collectedStatesById[change.id]; else changed">
                <div class="align-buttons" *ngIf="change.state == 'open' && modifyState.id !== change.id">
                  <button *ngIf="!!productChanges.product || !IS_REQUIRED[change.propertyName]" (click)="decline(change, !!productChanges.product)" data-ui="secondary small warn">Decline Line Item</button>
                  <button data-ui="secondary small" (click)="modify(change)">Edit Line Item</button>
                  <button (click)="accept(change, !!productChanges.product)" data-ui="small accent2">Accept Line Item</button>
                 </div>
                <div class="align-buttons" *ngIf="modifyState.open  && modifyState.id === change.id">
                  <button (click)="abortModification()" data-ui="secondary small warn">Abort</button>
                  <button (click)="submitModification(change, !!productChanges.product)" data-ui="small accent2">Apply changes</button>
                </div>
              </ng-container>
              <ng-template #changed>
                {{collectedStatesById[change.id]}} <a (click)="revertChange(change.id, productChanges)">(revert)</a>
              </ng-template>
            </td>
            <td *ngIf="change.state == 'accepted'">Accepted</td>
            <td *ngIf="change.state == 'rejected'">Declined</td>
            <td *ngIf="change.state == 'modified'">Edited</td>
          </tr>
        </tbody>
      </table>
      <button *ngIf="!productChanges.product && collected?.length && isAllRequiredsSet(productChanges.changeRequests)" data-ui="small primary" (click)="submitCollected(productChanges)">Submit Approval Decisions</button>
    </div>
    <div class="changelog-actions">
      <button (click)="declineRequest(group)" data-ui="secondary small warn">Decline complete request</button>
      <button (click)="acceptRequest(group)" data-ui="small accent2">Accept complete request</button>
    </div>
  </section>

  <div *ngIf="changeRequestGroups?.length" class="load-more">
    <a *ngIf="!isLoadingMore && !isNoMoreAvailable && !isDirty" (click)="loadMoreRequests()">Load more requests</a>
    <i class="fas fa-spinner spinner" *ngIf="isLoadingMore"></i>
    <span *ngIf="!isLoadingMore && isNoMoreAvailable">No more open requests found.</span>
    <span *ngIf="isDirty">Please reload page, to be able to check for more open requests.</span>
  </div>
</div>
