import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';
import { fetchDistributed } from './distribution-functions';

type RGB = {red: number, green: number, blue: number};

function calculateColorHue(first: RGB, second: RGB, percentage: number): RGB {
  return {
    red: Math.floor(first.red * (1 - percentage) + second.red * percentage),
    green: Math.floor(first.green * (1 - percentage) + second.green * percentage),
    blue: Math.floor(first.blue * (1 - percentage) + second.blue * percentage)
  };
}

function calculateColorBrightness(color: RGB, percentage: number): RGB {
  return {
    red: Math.floor(color.red * percentage + 255 * (1 - percentage)),
    green: Math.floor(color.green * percentage + 255 * (1 - percentage)),
    blue: Math.floor(color.blue * percentage + 255 * (1 - percentage))
  };
}

function generateDistributedColors(start: RGB, stop: RGB, numberOfGrades: number): Array<RGB> {
  const grades = [];
  const total = numberOfGrades + 2;
  for (let i = 0; i < numberOfGrades; i++) {
    grades.push(calculateColorBrightness(start, (i + 1) / total))
  }
  for (let i = 0; i < numberOfGrades; i++) {
    grades.push(calculateColorHue(start, stop, (i + 1) / total))
  }
  return grades;
}

export function generateDistributedColorsFromRGB(baseColors: Array<RGB>, length: number): Array<RGB> {
  if (baseColors.length >= length) {
    return [...baseColors].slice(0, length);
  }
  const numberOfGrades = Math.ceil(length / baseColors.length / 2); // 2 means for number of generators
  const calculatedColors = baseColors
    .flatMap((start, idx) => generateDistributedColors(start, baseColors[(idx + 1) % baseColors.length], numberOfGrades));
  return [...baseColors, ...fetchDistributed<RGB>(calculatedColors, length - baseColors.length)];
}

export function generateDistributedColorsFromHex(baseColors: Array<string>, length: number): Array<string> {
  return generateDistributedColorsFromRGB(baseColors.map(hex => hexRgb(hex)), length)
    .map(rgb => {
      return "#" + rgbHex(rgb.red, rgb.green, rgb.blue)});
}
