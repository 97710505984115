import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Product } from '../../generated/graphql';
import { ProductsService } from '../api/products.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsBasketStoreService {
  private readonly _basket = new BehaviorSubject<Product[]>(ProductsBasketStoreService.readBasket());

  readonly basket$ = this._basket.asObservable();

  constructor() { }

  get basket(): Product[] {
    return this._basket.getValue();
  }

  set basket(products: Product[]) {
    ProductsBasketStoreService.writeBasket(products);
    this._basket.next(products);
  }

  addToBasket(product: Product) {
    if (!this.basket.some(p => ProductsService.keyFn(p) === ProductsService.keyFn(product))) {
      this.basket = [...this.basket, product];
    }
  }

  addBulkToBasket(products: Array<Product> | Set<Product>) {
    const toAdd = [...products].filter(product => !this.basket.some(p => ProductsService.keyFn(p) === ProductsService.keyFn(product)));
    this.basket = [...this.basket, ...toAdd];
  }

  removeFromBasket(product: Product) {
    this.basket = this.basket.filter(p => ProductsService.keyFn(p) !== ProductsService.keyFn(product));
  }


  removeBulkFromBasket(products: Array<Product>) {
    this.basket = this.basket.filter(p => {
      const key = ProductsService.keyFn(p)
      return !products.some(product => key === ProductsService.keyFn(product));
    });
  }

  private static readBasket(): Array<Product> {
    return (JSON.parse(window.localStorage.getItem("userSettings.basket")) ?? []) as Array<Product>;
  }

  private static writeBasket(selectedProducts: Array<Product>) {
    window.localStorage.setItem("userSettings.basket", JSON.stringify(selectedProducts));
  }
}
