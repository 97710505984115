import { Injectable } from "@angular/core";
import { Product } from "src/generated/graphql";
import { SortableTableState } from '../../../../components/components/organisms/sortable-table/sortable-table.component';
import { DiagramProperties } from '../components/product-scatter-plot/product-scatter-plot.component';

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
  setVariant = (variant: "default" | "buttoned") => window.localStorage.setItem("userSettings.variant", variant);
  getVariant = () => (window.localStorage.getItem("userSettings.variant") ?? "default") as "default" | "buttoned";

  setProductDetailTableSorting = (order?: Array<any>) => window.localStorage.setItem("userSettings.productDetailTableSorting", JSON.stringify(order));
  getProductDetailTableSorting = () => (JSON.parse(window.localStorage.getItem("userSettings.productDetailTableSorting")) ?? []);

  setTab = (tab: number) => window.localStorage.setItem("userSettings.tab", tab as unknown as string);
  getTab = () => (Number(window.localStorage.getItem("userSettings.tab") ?? 0))

  setPersonalFilter = (filter) => window.localStorage.setItem("userSettings.filterSettings", JSON.stringify(filter));
  getPersonalFilter = () => (JSON.parse(window.localStorage.getItem("userSettings.filterSettings")));

  setFilterFamily = (family?: string) => family ? window.localStorage.setItem("userSettings.filterFamily", family) : window.localStorage.removeItem("userSettings.filterFamily");
  getFilterFamily = () => window.localStorage.getItem("userSettings.filterFamily") as string;

  get resultTableState(): SortableTableState<Product> {
    return JSON.parse(window.localStorage.getItem("userSettings.resultTableState")) as SortableTableState<Product>;
  }

  set resultTableState(resultTableState: SortableTableState<Product>) {
    window.localStorage.setItem("userSettings.resultTableState", JSON.stringify(resultTableState));
  }

  get resultTableStateLog(): SortableTableState<Product> {
    return JSON.parse(window.localStorage.getItem("userSettings.resultTableStateLog")) as SortableTableState<Product>;
  }

  set resultTableStateLog(resultTableStateLog: SortableTableState<Product>) {
    window.localStorage.setItem("userSettings.resultTableStateLog", JSON.stringify(resultTableStateLog));
  }

  get graphProperties(): Record<string, DiagramProperties> {
    return JSON.parse(window.localStorage.getItem("userSettings.diagramProperties")) as Record<string, DiagramProperties>;
  }

  set graphProperties(graphProperties: Record<string, DiagramProperties>) {
    window.localStorage.setItem("userSettings.diagramProperties", JSON.stringify(graphProperties));
  }
}
