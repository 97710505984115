<div class="bookmark-dialog">
    
    <h3>Add bookmark <i _ngcontent-wgl-c93="" class="fas fa-times" (click) = 'onClose()'></i></h3>
    
    <section>
        <p>Virtual Comparison of Product says</p>
        <p>Press ctrl + D to bookmark this page</p>
    </section>
      

      <button (click) = 'onClose()'>OK</button>
    </div>

